import React from "react";

import PropTypes from "prop-types";
import { EditorState } from "draft-js";

import EditorTextoEmailsBase from "../EditorTextoEmailsBase/EditorTextoEmailsBase";
import { decorators } from "../../../_common/utils/editorTexto/editorTexto";

class EditorTextoNovoEmailContainer extends React.PureComponent {
  state = {
    editorState: EditorState.createEmpty(decorators),
    copiasExpandidas: false,
  };

  onChange = editorState => {
    this.setState({ editorState });
  };

  toggleCopiasExpandidas = () => {
    this.setState(state => ({ copiasExpandidas: !state.copiasExpandidas }));
  };

  render() {
    return (
      <EditorTextoEmailsBase
        onChange={this.onChange}
        editorState={this.state.editorState}
        assuntoInicial={this.props.assuntoInicial || ""}
        setFocusCampoMensagem={false}
        emailsTo={this.props.emailsTo}
        emailsCc={this.props.emailsCc}
        emailsCco={this.props.emailsCco}
        isRespondendo={false}
        handleExibirTemplate={this.props.handleExibirTemplate}
        templateId={this.props.templateId}
        handleExcluirRascunho={this.props.handleFecharModal}
        fecharModalLerEmail={this.props.handleFecharModal}
        linksAnexos={this.props.linksAnexos}
        isEncaminhando={this.props.isEncaminhando}
        assinatura={this.props.assinatura}
        mensagensIdConteudo={this.props.mensagensIdConteudo}
        threadNormalizada={this.props.threadNormalizada}
        esconderDivider
        exibirBotaoExpandirCopias
        copiasExpandidas={this.state.copiasExpandidas}
        toggleCopiasExpandidas={this.toggleCopiasExpandidas}
        conteudoHtml={this.props.conteudoHtml}
        conteudoTemplate={this.props.conteudoTemplate}
        ocultarDescartarRascunho={this.props.ocultarDescartarRascunho}
        ocultarBarraDestinatarios={this.props.ocultarBarraDestinatarios}
        enviarEmail={this.props.enviarEmail}
      />
    );
  }
}

EditorTextoNovoEmailContainer.propTypes = {
  emailsTo: PropTypes.array.isRequired,
  emailsCc: PropTypes.array.isRequired,
  emailsCco: PropTypes.array.isRequired,
  assuntoInicial: PropTypes.string,
  handleExibirTemplate: PropTypes.func.isRequired,
  handleFecharModal: PropTypes.func.isRequired,
  templateId: PropTypes.number,
  linksAnexos: PropTypes.array,
  isEncaminhando: PropTypes.bool,
  assinatura: PropTypes.string,
  mensagensIdConteudo: PropTypes.array,
  threadNormalizada: PropTypes.object,
  conteudoHtml: PropTypes.bool,
  conteudoTemplate: PropTypes.string,
  ocultarDescartarRascunho: PropTypes.bool,
  ocultarBarraDestinatarios: PropTypes.bool,
  enviarEmail: PropTypes.func,
};

export default EditorTextoNovoEmailContainer;
