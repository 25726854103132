import React from "react";
import PropTypes from "prop-types";
import injectSheet from "react-jss";

import { Dialog, DialogContent } from "@material-ui/core";
import ModalLerEmailConteudo from "../ModalLerEmailConteudo/ModalLerEmailConteudo";
import DialogTitleClose from "../../../_common/components/DialogTitleClose/DialogTitleClose";

const styles = {
  modalContainer: {
    padding: "0 !important",
    height: "calc(100vh - 80px)",
    maxHeight: "none !important",
    overflow: "auto",
    borderTopRightRadius: "4px !important",
    borderTopLeftRadius: "4px !important",
    display: "flex",
    flexDirection: "column",
  },
  modalPaperContainer: {
    borderTopRightRadius: "4px !important",
    borderTopLeftRadius: "4px !important",
  },
  dialogContent: {
    height: "calc(100vh - 80px)",
    top: -40,
  },
  header: {
    flex: "none",
  },
  paperScrollPaper: {
    height: 760,
  },
};

const ModalLerEmail = ({
  classes,
  tituloCabecalho,
  threadEmails,
  open,
  exibeTemplates,
  handleSelecionarTemplate,
  handleExibirTemplate,
  handleFecharModal,
  templateId,
  anexosVerificados,
  editorExpandido,
  toggleEditorExpandido,
  exibirBotaoExpandirEditor,
  exibirBotaoExpandirCopias,
  copiasExpandidas,
  toggleCopiasExpandidas,
  podeEnviarEmail,
  isPVOrVendedor,
  handleResponderOuEncaminhar,
  ...rest
}) => (
  <Dialog
    fullWidth
    maxWidth="md"
    onClose={handleFecharModal}
    open={open}
    classes={{ paperScrollPaper: classes.paperScrollPaper }}
    {...rest}
  >
    <DialogTitleClose onClose={handleFecharModal}>{tituloCabecalho}</DialogTitleClose>
    <DialogContent className={classes.modalContainer}>
      <ModalLerEmailConteudo
        exibeTemplates={exibeTemplates}
        handleSelecionarTemplate={handleSelecionarTemplate}
        threadEmails={threadEmails}
        handleExibirTemplate={handleExibirTemplate}
        templateId={templateId}
        anexosVerificados={anexosVerificados}
        editorExpandido={editorExpandido}
        toggleEditorExpandido={toggleEditorExpandido}
        exibirBotaoExpandirEditor={exibirBotaoExpandirEditor}
        exibirBotaoExpandirCopias={exibirBotaoExpandirCopias}
        copiasExpandidas={copiasExpandidas}
        toggleCopiasExpandidas={toggleCopiasExpandidas}
        podeEnviarEmail={podeEnviarEmail}
        isPVOrVendedor={isPVOrVendedor}
        handleResponderOuEncaminhar={handleResponderOuEncaminhar}
        {...rest}
      />
    </DialogContent>
  </Dialog>
);

ModalLerEmail.propTypes = {
  classes: PropTypes.object,
  threadEmails: PropTypes.object.isRequired,
  tituloCabecalho: PropTypes.string,
  handleFecharModal: PropTypes.func.isRequired,
  handleSelecionarTemplate: PropTypes.func.isRequired,
  handleClickTrash: PropTypes.func.isRequired,
  handleExibirTemplate: PropTypes.func,
  exibeTemplates: PropTypes.bool,
  templateId: PropTypes.number,
  provider: PropTypes.object,
  open: PropTypes.bool.isRequired,
  anexosVerificados: PropTypes.bool.isRequired,
  editorExpandido: PropTypes.bool,
  toggleEditorExpandido: PropTypes.func,
  exibirBotaoExpandirEditor: PropTypes.bool,
  exibirBotaoExpandirCopias: PropTypes.bool,
  copiasExpandidas: PropTypes.bool,
  toggleCopiasExpandidas: PropTypes.func,
  podeEnviarEmail: PropTypes.bool,
  isPVOrVendedor: PropTypes.bool,
  handleResponderOuEncaminhar: PropTypes.func.isRequired,
  resetState: PropTypes.func,
};

export default injectSheet(styles)(ModalLerEmail);
