import React from "react";
import { Chip, Avatar } from "@material-ui/core";
import ChipInput from "material-ui-chip-input";
import { Tooltip } from "react-tippy";
import PropTypes from "prop-types";
import cn from "classnames";

import { getIniciaisDeUmNome } from "../../../_common/utils/string";
import { AppWrapper, Tipografia } from "../../../_common";
import injectSheet from "../../../_common/hocs/injectSheet/injectSheet";

import TooltipDadosContato from "../TooltipDadosContato/TooltipDadosContato";

const styles = {
  chip: {
    height: 24,
    alignItems: "center",
    marginTop: "4px !important",
  },
  chipFocused: {
    backgroundColor: "#BDBDBD !important",
  },
  avatar: {
    fontSize: "14px !important",
    height: "24px !important",
    width: "24px !important",
    backgroundColor: "#616161 !important",
    marginRight: 8,
    color: "#FFFFFF",
  },
  inputStyle: {
    marginBottom: 0,
    flex: "auto",
  },
  hintStyle: {
    bottom: 3,
  },
  listStyle: {
    width: "auto",
  },
  chipContainerStyle: {
    display: "flex",
    flexWrap: "wrap",
  },
  textFieldStyle: {
    height: 32,
    flex: "auto",
  },
  chipInput: {
    display: "flex",
    flexWrap: "wrap",
    flex: "auto",
    margin: "8px 0",
  },
  deleteIconStyle: {
    marginTop: 0,
    marginRight: 0,
  },
  chipWrapper: {
    display: "flex",
    width: "100%",
    alignItems: "center",
  },
  label: {
    marginRight: 8,
  },
};
const ChipDestinatarios = ({
  classes,
  setFocusCampoMensagem,
  handleOnBlurChip,
  handleRequestAddChip,
  handleRequestDeleteChip,
  handleChangeInputChip,
  emails,
  dataSourceChip,
  label,
  tabIndex,
}) => (
  <div className={classes.chipWrapper}>
    <Tipografia cor="darkSecondaryText" className={classes.label}>
      {label}
    </Tipografia>
    <ChipInput
      textFieldStyle={styles.textFieldStyle}
      inputStyle={styles.inputStyle}
      autoFocus={!setFocusCampoMensagem}
      hintStyle={styles.hintStyle}
      listStyle={styles.listStyle}
      chipContainerStyle={styles.chipContainerStyle}
      style={styles.chipInput}
      onBlur={handleOnBlurChip}
      onRequestAdd={handleRequestAddChip}
      onRequestDelete={handleRequestDeleteChip}
      onUpdateInput={handleChangeInputChip}
      maxSearchResults={5}
      value={emails}
      dataSource={dataSourceChip}
      dataSourceConfig={{ text: "text", value: "email" }}
      filter={() => true}
      newChipKeyCodes={[9, 188, 191, 13]}
      underlineShow={false}
      tabIndex={tabIndex}
      chipRenderer={({ chip, defaultStyle, isFocused, handleClick, handleRequestDelete }, key) => {
        const { name, cargo, email, listaLeads } = emails.find(x => x.id === chip.id);

        return (
          <Tooltip
            theme="paper light"
            position="bottom-start"
            duration={0}
            className={classes.tooltipContainer}
            html={
              <div>
                <AppWrapper>
                  <TooltipDadosContato nome={name} cargo={cargo} email={email} listaLeads={listaLeads} />
                </AppWrapper>
              </div>
            }
          >
            <Chip
              id={`chip[${email}]`}
              key={key}
              style={{ ...defaultStyle }}
              className={cn(classes.chip, { [classes.chipFocused]: isFocused })}
              onClick={handleClick}
              onDelete={handleRequestDelete}
              labelStyle={{ paddingLeft: 0 }}
              avatar={<Avatar className={classes.avatar}>{getIniciaisDeUmNome(name)}</Avatar>}
              label={name}
            />
          </Tooltip>
        );
      }}
    />
  </div>
);

ChipDestinatarios.propTypes = {
  classes: PropTypes.object,
  emails: PropTypes.array.isRequired,
  dataSourceChip: PropTypes.array.isRequired,
  handleRequestAddChip: PropTypes.func.isRequired,
  handleRequestDeleteChip: PropTypes.func.isRequired,
  handleChangeInputChip: PropTypes.func.isRequired,
  handleOnBlurChip: PropTypes.func.isRequired,
  setFocusCampoMensagem: PropTypes.bool,
  label: PropTypes.string.isRequired,
  tabIndex: PropTypes.number,
};

export default injectSheet(styles)(ChipDestinatarios);
