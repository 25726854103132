import React from "react";
import { Tooltip } from "react-tippy";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import { Flex } from "../../../_common";
import injectSheet from "../../../_common/hocs/injectSheet";

import EmailItemConteudo from "../EmailItemConteudo/EmailItemConteudo";

const styles = () => ({
  emailItemConteudoContainer: {
    height: 20,
  },
  containerExpandir: {
    flex: "none",
    paddingBottom: 10,
    height: 20,
  },
  botaoExpandir: {
    width: 30,
    height: 16,
    cursor: "pointer",
    textAlign: "center",
    backgroundColor: "#e7e7e7",
    borderWidth: 1,
    borderColor: "#ddd",
    borderStyle: "solid",
  },
  spanBotaoExpandir: {
    width: 30,
    marginTop: "-6px",
    fontWeight: "bold",
  },
});

const EmailConteudoExpandir = ({ classes, handleExpandirConteudo, expandirConteudo, conteudoParaExpandir }) => {
  const intl = useIntl();

  return (
    <React.Fragment>
      {conteudoParaExpandir !== "" && (
        <Flex key="botaoExpandir" className={classes.containerExpandir}>
          <Tooltip
            title={
              expandirConteudo
                ? intl.formatMessage({ defaultMessage: "Ocultar conteúdo expandido" })
                : intl.formatMessage({ defaultMessage: "Mostrar conteúdo completo" })
            }
            position="top-start"
            duration={0}
          >
            <Flex onClick={handleExpandirConteudo} className={classes.botaoExpandir}>
              <span className={classes.spanBotaoExpandir}>...</span>
            </Flex>
          </Tooltip>
        </Flex>
      )}
      {expandirConteudo && (
        <Flex key="containerExpandir" className={classes.containerExpandir}>
          <Tooltip title={intl.formatMessage({ defaultMessage: "Somente leitura" })} position="top center" duration={0}>
            <EmailItemConteudo emailBody={conteudoParaExpandir} />
          </Tooltip>
        </Flex>
      )}
    </React.Fragment>
  );
};

EmailConteudoExpandir.propTypes = {
  classes: PropTypes.object,
  conteudoParaExpandir: PropTypes.string,
  handleExpandirConteudo: PropTypes.func.isRequired,
  expandirConteudo: PropTypes.bool.isRequired,
};

export default injectSheet(styles)(EmailConteudoExpandir);
