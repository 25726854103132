import React from "react";
import { useIntl } from "react-intl";

import PropTypes from "prop-types";
import { IconButton } from "@material-ui/core";

import ReplyAll from "@material-ui/icons/ReplyAll";
import Forward from "@material-ui/icons/Forward";
import Delete from "@material-ui/icons/Delete";
import { Tooltip } from "react-tippy";

import injectSheet from "../../../_common/hocs/injectSheet";
import { Tipografia, Flex } from "../../../_common";

const styles = {
  content: {
    height: 48,
    paddingLeft: 16,
  },
  responderContainer: {
    order: 4,
  },
  avatarText: {}, // Sim, esta linha é necessária para aplicar o hover definido dentro da classe "barraReponder"
  barraReponder: {
    flexGrow: 1,
    height: 48,
    "&:hover $avatarText": {
      color: "blue",
    },
    cursor: "pointer",
  },
  botoesBarraResponder: {
    marginLeft: "auto",
    height: 48,
  },
  editor: {
    height: "auto",
    padding: "8px 16px",
  },
  escondido: {
    display: "none",
  },
  destinatarios: {
    height: 32,
    marginBottom: 4,
    backgroundColor: "#ccc",
  },
  botoes: {
    width: 20,
    height: 20,
  },
};

const BarraResponder = ({
  classes,
  className,
  handleResponder,
  handleResponderTodos,
  handleEncaminhar,
  esconderBotaoResponder,
  handleExcluir,
  mostrarExcluir,
  mostrarEncaminhar,
}) => {
  const intl = useIntl();

  return (
    <React.Fragment>
      {!esconderBotaoResponder && (
        <Flex className={`${classes.content} ${className}`} alignItems="center" flexDirection="row">
          <Flex id="btnResponder" onClick={handleResponder} alignItems="center" className={classes.barraReponder}>
            <Tipografia tipo="textoSidebar" className={classes.avatarText}>
              {intl.formatMessage({ defaultMessage: "Responder" })}
            </Tipografia>
          </Flex>
          <div className={classes.botoesBarraResponder}>
            <Tooltip title={intl.formatMessage({ defaultMessage: "Responder a todos" })} position="top" duration={0}>
              <IconButton onClick={handleResponderTodos} id="btnResponderTodos" iconStyle={{ width: 20, height: 20 }}>
                <ReplyAll />
              </IconButton>
            </Tooltip>
            {mostrarEncaminhar && (
              <Tooltip title={intl.formatMessage({ defaultMessage: "Encaminhar todos" })} position="top" duration={0}>
                <IconButton onClick={handleEncaminhar} id="btnEncaminharTodos" iconStyle={{ width: 20, height: 20 }}>
                  <Forward />
                </IconButton>
              </Tooltip>
            )}
            {mostrarExcluir && (
              <Tooltip title={intl.formatMessage({ defaultMessage: "Excluir" })} position="top" duration={0}>
                <IconButton onClick={handleExcluir} id="btnExcluirThread" iconStyle={{ width: 20, height: 20 }}>
                  <Delete />
                </IconButton>
              </Tooltip>
            )}
          </div>
        </Flex>
      )}
    </React.Fragment>
  );
};

BarraResponder.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  handleResponder: PropTypes.func.isRequired,
  handleResponderTodos: PropTypes.func,
  handleEncaminhar: PropTypes.func,
  handleExcluir: PropTypes.func,
  esconderBotaoResponder: PropTypes.bool,
  mostrarExcluir: PropTypes.bool,
  mostrarEncaminhar: PropTypes.bool,
};

export default injectSheet(styles)(BarraResponder);
