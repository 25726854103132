import { SCREEN_CONFIGS } from "../constants";

export const initialLista = () => ({
  id: null,
  nome: "",
  query: null,
  podeEditar: true,
  campanhas: [],
  exibeMensagemConfirmacao: false,
  config: {
    permiteBaseLeads: true,
    apenasContatosPrincipais: false,
  },
});

export const initialState = {
  isLoading: true,
  screenMode: SCREEN_CONFIGS.DEFAULT.mode,
  lista: initialLista(),
  touchedNomeLista: false,
  error: null,
  isOpenEditAlert: false,
};
