import React from "react";
import PropTypes from "prop-types";

import EmailItemAgrupador from "./EmailItemAgrupador.jsx";

class EmailItemAgrupadorContainer extends React.Component {
  state = {
    agruparThreads: true,
  };

  handleClickAgrupador = () => {
    this.setState({ agruparThreads: false });
  };

  render = () => (
    <EmailItemAgrupador agruparThreads={this.state.agruparThreads} handleClickAgrupador={this.handleClickAgrupador}>
      {this.props.children}
    </EmailItemAgrupador>
  );
}

EmailItemAgrupadorContainer.propTypes = {
  children: PropTypes.node,
};

export default EmailItemAgrupadorContainer;
