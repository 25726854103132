import React from "react";
import { IconButton } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import VerticalAlignTopIcon from "@material-ui/icons/VerticalAlignTop";
import VerticalAlignBottomIcon from "@material-ui/icons/VerticalAlignBottom";
import { useIntl } from "react-intl";
import { Tooltip } from "react-tippy";
import PropTypes from "prop-types";
import cn from "classnames";

import { Loading } from "../../../_common";
import injectSheet from "../../../_common/hocs/injectSheet/injectSheet";

import ChipDestinatarios from "../ChipDestinatarios/ChipDestinatarios";

const styles = theme => ({
  botaoEnviar: {
    marginRight: 8,
  },
  editor: {
    flex: "none",
    margin: "8px 0",
    fontWeight: "400",
    fontSize: 14,
    minHeight: "calc(100% - 42px)",
  },
  containerEditor: {
    overflowY: "auto",
    flex: "auto",
    height: "100%",
  },
  containerAnexos: {
    flex: "none",
  },
  chip: {
    height: 24,
    alignItems: "center",
    marginTop: "4px !important",
  },
  chipFocused: {
    backgroundColor: "#BDBDBD !important",
  },
  avatar: {
    fontSize: "14px !important",
    height: "24px !important",
    width: "24px !important",
    backgroundColor: "#616161 !important",
    marginRight: 8,
  },
  botaoExcluir: {
    width: "25px !important",
    height: "25px !important",
    margin: "4px 8px 4px 8px",
    cursor: "pointer",
  },
  campoAssunto: {
    fontWeight: "500",
    fontSize: 16,
  },
  flex: {
    alignItems: "center",
    flex: "none",
  },
  noFlex: {
    flex: "none",
  },
  tooltipContainer: {
    flex: "none",
    width: 48,
    marginTop: 6,
    marginLeft: -12,
  },
  loading: {
    height: "48px !important",
  },
  wrapperRoot: {
    display: "flex",
    flex: "none",
    alignItems: "flex-start",
  },
  icone: {
    fill: `${theme.palette.icons.darkInactiveIcon} !important`,
    "&:hover": {
      fill: `${theme.palette.icons.darkActiveIcon} !important`,
    },
    "&.active": {
      fill: `${theme.palette.icons.darkActiveIcon} !important`,
      backgroundColor: "rgba(0,0,0,.12)",
    },
  },
  iconeExpandirRecolher: {
    height: "20px !important",
    width: "20px !important",
  },
  botaoExpandirRecolher: {
    width: 40,
    height: 40,
    padding: 0,
  },
  divider: {
    height: 20,
    marginLeft: "auto",
  },
  wrapperDestinatarios: {
    width: "100%",
  },
});

const BarraDestinatarios = ({
  classes,
  emailsTo,
  emailsCc,
  emailsCco,
  isCarregandoEmailsTo,
  dataSourceChip,
  handleRequestAddChipTo,
  handleRequestAddChipCc,
  handleRequestAddChipCco,
  handleRequestDeleteChipTo,
  handleRequestDeleteChipCc,
  handleRequestDeleteChipCco,
  handleChangeInputChip,
  handleOnBlurChipTo,
  handleOnBlurChipCc,
  handleOnBlurChipCco,
  setFocusCampoMensagem,
  editorExpandido,
  toggleEditorExpandido,
  exibirBotaoExpandirEditor,
  exibirBotaoExpandirCopias,
  copiasExpandidas,
  toggleCopiasExpandidas,
}) => {
  const IconeExpandir = editorExpandido ? VerticalAlignBottomIcon : VerticalAlignTopIcon;
  const IconeExibirCopias = copiasExpandidas ? KeyboardArrowUpIcon : KeyboardArrowDownIcon;
  const intl = useIntl();

  return (
    <div className={classes.wrapperRoot}>
      <Loading className={classes.loading} size={20} isLoading={isCarregandoEmailsTo}>
        <div className={classes.wrapperDestinatarios}>
          <ChipDestinatarios
            setFocusCampoMensagem={setFocusCampoMensagem}
            handleOnBlurChip={handleOnBlurChipTo}
            handleRequestAddChip={handleRequestAddChipTo}
            handleRequestDeleteChip={handleRequestDeleteChipTo}
            handleChangeInputChip={handleChangeInputChip}
            emails={emailsTo}
            dataSourceChip={dataSourceChip}
            label={intl.formatMessage({ defaultMessage: "Para" })}
            tabIndex={0}
          />
          {copiasExpandidas && (
            <React.Fragment>
              <ChipDestinatarios
                setFocusCampoMensagem
                handleOnBlurChip={handleOnBlurChipCc}
                handleRequestAddChip={handleRequestAddChipCc}
                handleRequestDeleteChip={handleRequestDeleteChipCc}
                handleChangeInputChip={handleChangeInputChip}
                emails={emailsCc}
                dataSourceChip={dataSourceChip}
                label="Cc"
              />
              <ChipDestinatarios
                setFocusCampoMensagem
                handleOnBlurChip={handleOnBlurChipCco}
                handleRequestAddChip={handleRequestAddChipCco}
                handleRequestDeleteChip={handleRequestDeleteChipCco}
                handleChangeInputChip={handleChangeInputChip}
                emails={emailsCco}
                dataSourceChip={dataSourceChip}
                label="Cco"
              />
            </React.Fragment>
          )}
        </div>
      </Loading>
      {exibirBotaoExpandirCopias && (
        <Tooltip
          title={`${
            copiasExpandidas
              ? intl.formatMessage({ defaultMessage: "Recolher" })
              : intl.formatMessage({ defaultMessage: "Expandir" })
          } Cc/Cco`}
          position="top"
          duration={0}
        >
          <IconButton
            tabIndex={-1}
            onClick={toggleCopiasExpandidas}
            id="btnExpandirCopias"
            className={classes.botaoExpandirRecolher}
          >
            <IconeExibirCopias tabIndex={-1} className={cn(classes.iconeExpandirRecolher, classes.icone)} />
          </IconButton>
        </Tooltip>
      )}
      {exibirBotaoExpandirEditor && (
        <Tooltip
          title={
            editorExpandido
              ? intl.formatMessage({ defaultMessage: "Recolher editor" })
              : intl.formatMessage({ defaultMessage: "Expandir editor" })
          }
          position="top"
          duration={0}
        >
          <IconButton
            tabIndex={-1}
            onClick={toggleEditorExpandido}
            id="btnExpandirEditor"
            className={classes.botaoExpandirRecolher}
          >
            <IconeExpandir tabIndex={-1} className={cn(classes.iconeExpandirRecolher, classes.icone)} />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
};

BarraDestinatarios.propTypes = {
  classes: PropTypes.object,
  emailsTo: PropTypes.array.isRequired,
  emailsCc: PropTypes.array.isRequired,
  emailsCco: PropTypes.array.isRequired,
  dataSourceChip: PropTypes.array.isRequired,
  handleRequestAddChipTo: PropTypes.func.isRequired,
  handleRequestAddChipCc: PropTypes.func.isRequired,
  handleRequestAddChipCco: PropTypes.func.isRequired,
  handleRequestDeleteChipTo: PropTypes.func.isRequired,
  handleRequestDeleteChipCc: PropTypes.func.isRequired,
  handleRequestDeleteChipCco: PropTypes.func.isRequired,
  handleChangeInputChip: PropTypes.func.isRequired,
  handleOnBlurChipTo: PropTypes.func.isRequired,
  handleOnBlurChipCc: PropTypes.func.isRequired,
  handleOnBlurChipCco: PropTypes.func.isRequired,
  isCarregandoEmailsTo: PropTypes.bool,
  setFocusCampoMensagem: PropTypes.bool,
  editorExpandido: PropTypes.bool,
  toggleEditorExpandido: PropTypes.func,
  exibirBotaoExpandirEditor: PropTypes.bool,
  exibirBotaoExpandirCopias: PropTypes.bool,
  copiasExpandidas: PropTypes.bool,
  toggleCopiasExpandidas: PropTypes.func,
};

BarraDestinatarios.defaultProps = {
  isCarregandoEmailsTo: false,
};

export default injectSheet(styles)(BarraDestinatarios);
