import React from "react";
import { Avatar } from "@material-ui/core";
import PropTypes from "prop-types";
import { withTheme } from "react-jss";
import { compose } from "recompose";
import { getIniciaisDeUmNome } from "../../../_common/utils/string";
import { Tipografia, Flex } from "../../../_common";
import injectSheet from "../../../_common/hocs/injectSheet";

const styles = {
  tooltipDadosContato: {
    minWidth: 344,
    padding: 8,
  },
  avatar: {
    flex: "none",
    marginRight: 16,
  },
  dados: {
    flexDirection: "column",
    alignItems: "flex-start",
  },
  dado: {
    margin: "2px 0",
    display: "block",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: 312,
    "&:first-child": {
      marginTop: 0,
    },
    "&:last-child": {
      marginBottom: 0,
    },
  },
};

const TooltipDadosContato = ({ classes, nome, sobrenome, cargo, email, listaLeads, theme }) => (
  <Flex className={classes.tooltipDadosContato}>
    <Avatar color={theme.palette.textColor} className={classes.avatar}>
      {getIniciaisDeUmNome(nome)}
    </Avatar>
    <Flex className={classes.dados}>
      <Tipografia className={classes.dado} tipo="subtituloConteudo">
        {`${nome} ${sobrenome == null ? "" : sobrenome}`}
        {cargo && ` (${cargo})`}
      </Tipografia>
      <Tipografia className={classes.dado} cor="darkSecondaryText">
        {email}
      </Tipografia>
      {listaLeads && (
        <Tipografia className={classes.dado} cor="darkSecondaryText">
          {listaLeads.map(x => x.nome).join(", ")}
        </Tipografia>
      )}
    </Flex>
  </Flex>
);

TooltipDadosContato.propTypes = {
  classes: PropTypes.object,
  theme: PropTypes.object,
  nome: PropTypes.string.isRequired,
  sobrenome: PropTypes.string,
  email: PropTypes.string.isRequired,
  cargo: PropTypes.string,
  listaLeads: PropTypes.array,
};

const enhance = compose(
  withTheme,
  injectSheet(styles)
);

export default enhance(TooltipDadosContato);
