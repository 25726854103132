import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";

import EmailItem from "./EmailItem.jsx";
import { fecharEditorResponder } from "../../../store/reducers/emails/emailsActions";
import { validarEmail } from "../../../_common/utils/string";

class EmailItemContainer extends React.PureComponent {
  state = {
    anchorEl: null,
    mostrarPrevia: this.props.mostrarPreviaDefault,
    listaLeads: this.props.listaLeads,
  };

  getEmailFormatado = () => ({ ...this.props.email, body: this.props.email.body });

  abrirEditorResponder = (...args) => {
    if (this.props.isPVOrVendedor && !this.props.podeEnviarEmail) {
      this.props.history.push("/spotter/central-emails");
    }

    this.props.abrirEditorResponder(...args);
  };

  handleClick = event => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  isClickExpandirDestinatarios = event => {
    if (
      event.target.id === "expandirDestinatarios" ||
      event.target.localName === "path" ||
      event.target.localName === "span"
    ) {
      return true;
    }
    return false;
  };

  handleClickHeader = event => {
    if (this.isClickExpandirDestinatarios(event)) {
      return;
    }
    if (this.state.anchorEl != null) {
      return;
    }
    this.setState({ mostrarPrevia: !this.state.mostrarPrevia });
  };

  handleResponder = () => {
    if (this.verificarValidacaoEnvioEmail()) {
      return;
    }

    this.props.handleResponderOuEncaminhar(this.props.email.id, false, this.props.email.messageId);
  };

  handleEncaminhar = () => {
    if (this.verificarValidacaoEnvioEmail()) {
      return;
    }

    this.props.handleResponderOuEncaminhar(this.props.email.id, true, this.props.email.messageId);
  };

  normalizarContatoComLead = contato => {
    const contatoNormalizado = {
      id: contato.Id,
      nome: contato.Nome,
    };
    return contatoNormalizado;
  };
  normalizaContatosLeads = lead => {
    const leadModify = {
      email: lead.Email,
      id: lead.Id,
      idLead: lead.IdLead,
      nome: lead.NomeLead,
    };
    return leadModify;
  };
  normalizarDestinatario = destinatario => {
    const list = this.state.listaLeads;
    let destinatarioComContatoLead = [];

    if (list !== undefined)
      destinatarioComContatoLead = list.filter(x => x.Email === destinatario.email).map(this.normalizaContatosLeads);
    if (!validarEmail(destinatario.name)) {
      const destinatarioName = destinatario?.name || destinatario?.nome || "";
      const [name, ...lastname] = destinatarioName.split(" ");
      return {
        name,
        lastname: lastname.join(" "),
        email: destinatario.email,
        isCc: destinatario.isCc,
        isCco: destinatario.isCco,
        listaLeads: destinatarioComContatoLead,
      };
    }
    return {
      name: destinatario.name,
      lastname: null,
      email: destinatario.email,
      isCc: destinatario.isCc,
      isCco: destinatario.isCco,
      listaLeads: destinatarioComContatoLead,
    };
  };
  verificarValidacaoEnvioEmail = () => {
    if (this.props.isPVOrVendedor && !this.props.podeEnviarEmail) {
      this.props.history.push("/spotter/central-emails");

      return true;
    }

    return false;
  };
  render() {
    const { email, threadEmails, mostrarPreviaDefault, podeEnviarEmail, abrirEditorResponder, ...props } = this.props;

    const destinatariosLista = email.to
      .concat(
        email.cc.map(x => ({
          ...x,
          isCc: true,
        })),
        email.cco.map(x => ({
          ...x,
          isCco: true,
        }))
      )
      .map(this.normalizarDestinatario);

    const emailModificado = this.getEmailFormatado();

    return (
      <EmailItem
        {...props}
        abrirEditorResponder={this.abrirEditorResponder}
        email={emailModificado}
        destinatarios={destinatariosLista}
        threadEmails={threadEmails}
        mostrarPrevia={this.state.mostrarPrevia}
        handleClickHeader={this.props.email.isEmailCadencia ? undefined : this.handleClickHeader}
        anchorEl={this.state.anchorEl}
        handleClick={this.handleClick}
        handleClose={this.handleClose}
        mostrarExpandirEmail={this.state.mostrarPrevia}
        mostrarMaisOpcoes={!this.state.mostrarPrevia}
        handleResponder={this.handleResponder}
        handleEncaminhar={this.handleEncaminhar}
        esconderBotaoResponder={this.props.esconderBotaoResponder}
      />
    );
  }
}

EmailItemContainer.propTypes = {
  email: PropTypes.object.isRequired,
  threadEmails: PropTypes.object.isRequired,
  destinatarios: PropTypes.array,
  mostrarPreviaDefault: PropTypes.bool,
  listaLeads: PropTypes.array,
  podeEnviarEmail: PropTypes.bool,

  /** Propriedade responsável por ocultar o botão de menu
   * que contém os botões Responder e Encaminhar */
  modoVisualizacao: PropTypes.bool,

  abrirEditorResponder: PropTypes.func,
  isPVOrVendedor: PropTypes.bool,
  history: PropTypes.object,
  fecharEditorResponder: PropTypes.func,
  handleResponderOuEncaminhar: PropTypes.func.isRequired,
  esconderBotaoResponder: PropTypes.bool,
};

const mapStateToProps = state => ({
  isPVOrVendedor: state.usuario.isPVOrVendedor,
  listaLeads: state.emails.responderEmail.listaLeads,
  esconderBotaoResponder: state.emails.responderEmail.esconderBotaoResponder,
});

const mapDispatchToProps = dispatch => ({
  fecharEditorResponder: () => dispatch(fecharEditorResponder()),
});

const enhance = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
);

export default enhance(EmailItemContainer);
