import React from "react";
import PropType from "prop-types";
import { Divider, Avatar } from "@material-ui/core";
import cn from "classnames";

import injectSheet from "../../../_common/hocs/injectSheet";

const styles = theme => ({
  agrupador: {
    cursor: "pointer",
    padding: "8px 0",
  },
  dividers: {
    position: "relative",
  },
  divider1: {
    top: 11,
    marginTop: 8,
  },
  divider2: {
    bottom: 11,
    marginBottom: 8,
  },
  avatar: {
    border: "#e0e0e0 solid 1px",
    marginLeft: 16,
    position: "relative",
    zIndex: 1,
    color: `${theme.palette.text.darkPrimaryText} !important`,
  },
  emailItemHeader: {
    marginLeft: 16,
  },
});

const EmailItemAgrupador = ({ classes, children, agruparThreads, handleClickAgrupador }) => {
  const qtdMensagensAgrupadas = React.Children.count(children) - 2;
  if (qtdMensagensAgrupadas <= 0 || !agruparThreads) {
    return children;
  }

  const primeiroElemento = React.cloneElement(children[0][0], { esconderDivider: true });
  return [
    primeiroElemento,
    <div key="agrupador" className={classes.agrupador} onClick={agruparThreads ? handleClickAgrupador : undefined}>
      <Divider className={cn(classes.dividers, classes.divider1)} />
      <Avatar className={classes.avatar}>{qtdMensagensAgrupadas}</Avatar>
      <Divider className={cn(classes.dividers, classes.divider2)} />
    </div>,
    children[1],
  ];
};

EmailItemAgrupador.propTypes = {
  classes: PropType.object,
  children: PropType.node,
  handleClickAgrupador: PropType.func.isRequired,
  agruparThreads: PropType.bool.isRequired,
};

export default injectSheet(styles)(EmailItemAgrupador);
