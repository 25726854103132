import React from "react";
import PropTypes from "prop-types";

import { Loading } from "../../../_common";
import ModalLerEmailResponder from "../ModalLerEmailResponder/ModalLerEmailResponder";
import ModalEscolherTemplate from "../ModalEscolherTemplate/ModalEscolherTemplate";
import ModalLerEmailListaEmails from "../ModalLerEmailListaEmails/ModalLerEmailListaEmails";

const ModalLerEmailConteudo = ({
  exibeTemplates,
  handleSelecionarTemplate,
  threadEmails,
  handleExibirTemplate,
  templateId,
  provider,
  anexosVerificados,
  editorExpandido,
  toggleEditorExpandido,
  exibirBotaoExpandirEditor,
  exibirBotaoExpandirCopias,
  copiasExpandidas,
  toggleCopiasExpandidas,
  podeEnviarEmail,
  isPVOrVendedor,
  handleResponderOuEncaminhar,
  ...rest
}) => {
  if (exibeTemplates) {
    return <ModalEscolherTemplate handleSelecionarTemplate={handleSelecionarTemplate} />;
  } else if (anexosVerificados) {
    return (
      <React.Fragment>
        <ModalLerEmailListaEmails
          handleResponderOuEncaminhar={handleResponderOuEncaminhar}
          threadEmails={threadEmails}
          provider={provider}
          editorExpandido={editorExpandido}
          podeEnviarEmail={podeEnviarEmail}
        />
        {isPVOrVendedor && (
          <ModalLerEmailResponder
            templateId={templateId}
            handleSelecionarTemplate={handleSelecionarTemplate}
            handleExibirTemplate={handleExibirTemplate}
            provider={provider}
            toggleEditorExpandido={toggleEditorExpandido}
            editorExpandido={editorExpandido}
            exibirBotaoExpandirEditor={exibirBotaoExpandirEditor}
            exibirBotaoExpandirCopias={exibirBotaoExpandirCopias}
            copiasExpandidas={copiasExpandidas}
            toggleCopiasExpandidas={toggleCopiasExpandidas}
            podeEnviarEmail={podeEnviarEmail}
            {...rest}
          />
        )}
      </React.Fragment>
    );
  }

  return <Loading isLoading />;
};

ModalLerEmailConteudo.propTypes = {
  threadEmails: PropTypes.object.isRequired,
  handleSelecionarTemplate: PropTypes.func.isRequired,
  handleExibirTemplate: PropTypes.func,
  exibeTemplates: PropTypes.bool,
  templateId: PropTypes.number,
  provider: PropTypes.object,
  anexosVerificados: PropTypes.bool.isRequired,
  editorExpandido: PropTypes.bool,
  toggleEditorExpandido: PropTypes.func,
  exibirBotaoExpandirEditor: PropTypes.bool,
  exibirBotaoExpandirCopias: PropTypes.bool,
  copiasExpandidas: PropTypes.bool,
  toggleCopiasExpandidas: PropTypes.func,
  podeEnviarEmail: PropTypes.bool,
  isPVOrVendedor: PropTypes.bool,
  handleResponderOuEncaminhar: PropTypes.func.isRequired,
  resetState: PropTypes.func,
};

export default ModalLerEmailConteudo;
