import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { withTheme } from "react-jss";
import { Avatar } from "@material-ui/core";

import Popover from "@material-ui/core/Popover";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";

import { Tooltip } from "react-tippy";
import { compose } from "recompose";

import TooltipDadosContato from "../TooltipDadosContato/TooltipDadosContato";
import { Tipografia, Flex, AppWrapper } from "../../../_common";
import injectSheet from "../../../_common/hocs/injectSheet";
import { getIniciaisDeUmNome } from "../../../_common/utils/string";
import ModalDetalheDestinatarios from "./ModalDetalheDestinatarios";
import TooltipNomeAvatar from "./TooltipNomeAvatar";

const styles = theme => ({
  textoNomeAvatar: {
    paddingLeft: 16,
  },
  destinatarios: {
    paddingTop: 4,
  },
  avatar: {
    flex: "none",
    backgroundColor: theme.palette.primary3Color,
  },
  popover: {
    display: "inline",
    zIndex: "1700 !important",
    fontSize: "80%",
    color: "rgba(0, 0, 0, 0.51)",
  },
  iconArrowDropDown: {
    color: "rgba(0, 0, 0, 0.51)",
    height: "24px",
    width: "24px",
    display: "inline",
    position: "relative",
    marginBottom: "-5px",
  },
  truncate: {
    display: "inline-block",
    maxWidth: "630px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  table: {
    fontSize: 12,
    margin: "1em",
  },
  tableHeader: {
    color: "#999",
    verticalAlign: "top",
    paddingRight: "2em",
    paddingBottom: "2em",
  },
  tableData: {
    color: "#202124",
    verticalAlign: "top",
    paddingBottom: "10px",
  },
});

const NomeAvatar = ({ classes, children, destinatarios, anchorEl, handleClick, handleClose, leads }) => {
  const intl = useIntl();

  return (
    <Flex alignItems="center">
      <Avatar className={classes.avatar} size={28}>
        <Tipografia cor="lightPrimaryText">{getIniciaisDeUmNome(children)}</Tipografia>
      </Avatar>
      <Flex flexDirection="column" className={classes.textoNomeAvatar}>
        <Tipografia tipo="subtituloConteudo">
          {leads.length > 0 ? (
            <Tooltip
              key={"tooltip"}
              theme="paper light"
              position="bottom-start"
              interactive
              html={
                <AppWrapper>
                  <TooltipNomeAvatar leads={leads} />
                </AppWrapper>
              }
            >
              {children}
            </Tooltip>
          ) : (
            children
          )}
        </Tipografia>
        <Tipografia tipo="notaLegendas" className={classes.destinatarios} cor="darkSecondaryText">
          <div className="text-nowrap">
            <div className={classes.truncate}>
              {intl.formatMessage({ defaultMessage: "Para:" })}{" "}
              {destinatarios.map((destinatario, index) => (
                <Tooltip
                  key={index}
                  distance={5}
                  theme="paper light"
                  position="bottom-start"
                  duration={0}
                  html={
                    <div style={{ height: 65 }}>
                      <AppWrapper>
                        <TooltipDadosContato
                          nome={destinatario.name}
                          sobrenome={destinatario.lastname != null ? destinatario.lastname : ""}
                          email={destinatario.email}
                          listaLeads={destinatario.listaLeads}
                        />
                      </AppWrapper>
                    </div>
                  }
                >
                  {destinatario.isCco && "Cco:"}
                  {destinatario.name}
                  {index < destinatarios.length - 1 && ", "}
                </Tooltip>
              ))}
            </div>
            <ArrowDropDown
              id="expandirDestinatarios"
              style={styles.iconArrowDropDown}
              variant="contained"
              onClick={handleClick}
            />
            <div id="popoverid">
              <Popover
                className={classes.popover}
                open={anchorEl != null}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <ModalDetalheDestinatarios
                  classes={classes}
                  destinatarios={destinatarios}
                  nomeDoDestinatario={children}
                />
              </Popover>
            </div>
          </div>
        </Tipografia>
      </Flex>
    </Flex>
  );
};

NomeAvatar.propTypes = {
  classes: PropTypes.object,
  anchorEl: PropTypes.object,
  handleClick: PropTypes.func,
  handleClose: PropTypes.func,
  children: PropTypes.string.isRequired,
  destinatarios: PropTypes.array,
  leads: PropTypes.array,
};

const enhance = compose(
  injectSheet(styles),
  withTheme
);

export default enhance(NomeAvatar);
