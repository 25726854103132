import React from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { fecharModalVisualizarEmail } from "../../../store/reducers/emails/emailsActions";
import ModalVisualizarEmail from "./ModalVisualizarEmail.jsx";

class ModalVisualizarEmailContainer extends React.Component {
  static propTypes = {
    fecharModalVisualizarEmail: PropTypes.func.isRequired,
    openModalVisualizarEmail: PropTypes.bool,
    threadNormalizada: PropTypes.object,
  };

  handleFecharModal = () => {
    this.props.fecharModalVisualizarEmail();
  };

  geraTituloCabecalho() {
    if (this.props.threadNormalizada.emails.length > 1) {
      const respostas = this.props.threadNormalizada.emails.length;
      return `Respostas (${respostas})`;
    }
    return "";
  }

  render() {
    const { openModalVisualizarEmail, ...rest } = this.props;

    if (openModalVisualizarEmail) {
      return (
        <ModalVisualizarEmail
          tituloCabecalho={this.geraTituloCabecalho()}
          handleFecharModal={this.handleFecharModal}
          open
          threadEmails={this.props.threadNormalizada}
          {...rest}
        />
      );
    }

    return null;
  }
}

const mapStateToProps = state => ({
  openModalVisualizarEmail: state.emails.modais.modalVisualizarEmail.open,
  threadNormalizada: state.emails.modais.modalVisualizarEmail.thread,
});

const mapDispatchToProps = dispatch => ({
  fecharModalVisualizarEmail: () => dispatch(fecharModalVisualizarEmail()),
});

const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withRouter
);

export default enhance(ModalVisualizarEmailContainer);
