import React from "react";
import PropType from "prop-types";
import { useIntl } from "react-intl";
import MoreVert from "@material-ui/icons/MoreVert";
import Delete from "@material-ui/icons/Delete";
import { IconMenu } from "material-ui";
import { IconButton, Paper, MenuItem } from "@material-ui/core";
import ListItemIcon from "@material-ui/core/ListItemIcon";

import { Tipografia, Flex } from "../../../_common";
import injectSheet from "../../../_common/hocs/injectSheet";

const styles = {
  content: {
    height: 48,
    padding: "0 0 4px 16px",
  },
  paper: {
    marginBottom: 8,
  },
};

const ModalLerEmailSubHeader = ({ mostrarMaisOpcoes, classes, handleClickTrash, assunto }) => {
  const intl = useIntl();

  return (
    <Paper zDepth={2} className={classes.paper}>
      <Flex className={classes.content} justifyContent="space-between" alignItems="center">
        <Tipografia tipo="tituloCard">{assunto}</Tipografia>

        {mostrarMaisOpcoes && (
          <IconMenu
            desktop
            iconButtonElement={
              <IconButton
                tooltipPosition="bottom-left"
                id="btnMaisOpcoes"
                tooltip={intl.formatMessage({ defaultMessage: "Mais opções" })}
                iconStyle={{ width: 20, height: 20 }}
              >
                <MoreVert />
              </IconButton>
            }
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            targetOrigin={{ horizontal: "right", vertical: "top" }}
          >
            <MenuItem primaryText="Excluir" id="btnExcluir" onClick={handleClickTrash}>
              <ListItemIcon>
                <Delete fontSize="small" />
              </ListItemIcon>
              {intl.formatMessage({ defaultMessage: "Excluir" })}
            </MenuItem>
          </IconMenu>
        )}
      </Flex>
    </Paper>
  );
};

ModalLerEmailSubHeader.propTypes = {
  handleClickTrash: PropType.func.isRequired,
  assunto: PropType.string.isRequired,
  mostrarMaisOpcoes: PropType.bool,
  classes: PropType.object,
};

ModalLerEmailSubHeader.defaultProps = {
  mostrarMaisOpcoes: true,
};

export default injectSheet(styles)(ModalLerEmailSubHeader);
