import React from "react";
import PropTypes from "prop-types";

import injectSheet from "../../../_common/hocs/injectSheet/injectSheet";
import EmailItemAgrupador from "../EmailItemAgrupador/EmailItemAgrupador";
import EmailItem from "../EmailItem/EmailItem";

const styles = {
  modalContent: {
    "&::-webkit-scrollbar": { display: "none" },
    overflowY: "scroll",
    overflowX: "hidden",
    flex: 1,
    display: ({ editorExpandido }) => `${editorExpandido ? "none" : "block"}`,
  },
};

const ModalLerEmailListaEmails = ({
  classes,
  threadEmails,
  provider,
  podeEnviarEmail,
  isPVOrVendedor,
  handleResponderOuEncaminhar,
}) => (
  <div className={classes.modalContent}>
    <EmailItemAgrupador>
      {threadEmails.messages &&
        threadEmails.messages.map((email, index) => (
          <EmailItem
            key={email.messageId}
            email={email}
            threadEmails={threadEmails}
            provider={provider}
            handleResponderOuEncaminhar={handleResponderOuEncaminhar}
            mostrarPreviaDefault
            esconderDivider={index === threadEmails.messages.length}
            podeEnviarEmail={podeEnviarEmail}
            isPVOrVendedor={isPVOrVendedor}
          />
        ))}
      <EmailItem
        key={threadEmails.id}
        email={threadEmails}
        threadEmails={threadEmails}
        provider={provider}
        handleResponderOuEncaminhar={handleResponderOuEncaminhar}
        mostrarPreviaDefault={false}
        esconderDivider
        podeEnviarEmail={podeEnviarEmail}
        isPVOrVendedor={isPVOrVendedor}
      />
    </EmailItemAgrupador>
  </div>
);

ModalLerEmailListaEmails.propTypes = {
  classes: PropTypes.object,
  threadEmails: PropTypes.object.isRequired,
  provider: PropTypes.object,
  editorExpandido: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
  podeEnviarEmail: PropTypes.bool,
  isPVOrVendedor: PropTypes.bool,
  handleResponderOuEncaminhar: PropTypes.func.isRequired,
};

export default injectSheet(styles)(ModalLerEmailListaEmails);
