import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { IconButton, LinearProgress } from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import { compose } from "recompose";
import { withTheme } from "react-jss";
import { Tooltip } from "react-tippy";
import { truncate } from "../../../_common/utils/commonCss";
import Tipografia from "../../../_common/components/Tipografia";
import injectSheet from "../../../_common/hocs/injectSheet/injectSheet";

const styles = {
  linkAnexoContainer: {
    width: 500,
  },
  root: {
    "&:hover": {
      backgroundColor: "#f2f2f2",
    },
    "&:hover $barraDeAcao": {
      visibility: "visible",
      opacity: 1,
    },
    backgroundColor: "#f2f2f2",
    height: 30,
    border: 1,
    borderStyle: "solid",
    borderColor: "#ccc",
    marginBottom: 10,
    boxSizing: "border-box",
  },
  rootConteudo: {
    height: 30,
    display: "flex",
    paddingLeft: 10,
    minWidth: "100%",
  },
  barraDeAcao: {
    position: "relative",
    visibility: "hidden",
    opacity: 0,
  },
  textoAnexo: {
    ...truncate,
    fontWeight: "bold",
    fontFamily: "sans-serif !important",
    maxWidth: ({ modoTela }) => (modoTela ? 358 : 476),
  },
  botaoExcluir: {
    padding: "0 !important",
    height: "30px !important",
  },
  iconBotaoExcluir: {
    width: 20,
    height: 20,
  },
  linearProgress: {
    marginLeft: "auto !important",
    width: "48px !important",
    marginTop: -9,
  },
  rootTexto: {
    width: "100%",
    alignItems: "center",
  },
  alinhamentoTexto: {
    paddingTop: 7,
    width: "100%",
  },
};

const getNomeAnexo = (nome, size) => `${nome} (${(size / 1024).toFixed(0)}K)`;

const LinksAnexos = ({ classes, links, handleClickExcluir, modoTela }) => {
  const intl = useIntl();

  return (
    <div className={classes.linkAnexoContainer}>
      {links.map(elem => (
        <div key={elem.chaveBlob} className={classes.root}>
          <div className={classes.rootConteudo}>
            <div className={classes.alinhamentoTexto}>
              <Tipografia
                url={modoTela ? "#" : elem.link}
                urlExterna={!modoTela}
                tipo="notaLegendas"
                cor="darkPrimaryText"
                className={classes.textoAnexo}
              >
                {getNomeAnexo(elem.nome, elem.size)}
              </Tipografia>
              {modoTela && (
                <div className={classes.linearProgress}>
                  {elem.completed < 100 && <LinearProgress mode="indeterminate" />}
                </div>
              )}
            </div>
            {modoTela && (
              <div className={classes.barraDeAcao}>
                <Tooltip title={intl.formatMessage({ defaultMessage: "Excluir" })} position="top" duration={0}>
                  <IconButton
                    className={classes.botaoExcluir}
                    iconStyle={{ width: 15, height: 20 }}
                    onClick={() => {
                      handleClickExcluir(elem.chaveBlob);
                    }}
                  >
                    <Close />
                  </IconButton>
                </Tooltip>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

LinksAnexos.propTypes = {
  classes: PropTypes.object,
  links: PropTypes.array.isRequired,
  handleClickExcluir: PropTypes.func,
  modoTela: PropTypes.bool,
};

const enhance = compose(
  withTheme,
  injectSheet(styles)
);

export default enhance(LinksAnexos);
