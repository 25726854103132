import React from "react";
import PropTypes from "prop-types";
import { withTheme } from "react-jss";
import { compose } from "recompose";

import { Divider } from "../../../_common";
import injectSheet from "../../../_common/hocs/injectSheet";
import EditorTextoEmails from "../EditorTextoEmails/EditorTextoEmails";
import BarraResponder from "../BarraResponder/BarraResponder";

const styles = {
  editor: {
    padding: 8,
    flex: 1,
    display: "flex",
    flexDirection: "column",
    paddingTop: ({ editorExpandido }) => (editorExpandido ? 1 : 8),
  },
  divider: {
    width: "100%",
  },
  barraResponder: {
    flex: "none",
  },
};
const ModalLerEmailResponder = ({
  classes,
  mostrarEditor,
  responderEmail,
  assunto,
  editorState,
  onChange,
  handleExibirTemplate,
  provider,
  fecharModalLerEmail,
  atualizarThreads,
  resetarThreads,
  templateId,
  editorExpandido,
  toggleEditorExpandido,
  exibirBotaoExpandirEditor,
  exibirBotaoExpandirCopias,
  copiasExpandidas,
  toggleCopiasExpandidas,
  emailsTo,
  emailsCc,
  emailsCco,
  linksAnexos,
  assinatura,
  mensagensIdConteudo,
  threadNormalizada,
  isLoading,
  handleResponder,
  handleResponderTodos,
  handleEncaminhar,
  handleExcluir,
  mostrarExcluir,
  isEmailCadencia,
  podeResponderEmail,
  handleResponderEmailCadencia,

  ...rest
}) =>
  !mostrarEditor ? (
    <div className={classes.barraResponder}>
      <Divider className={classes.divider} />
      {isEmailCadencia && podeResponderEmail ? (
        <BarraResponder
          esconderBotaoResponder={responderEmail.esconderBotaoResponder}
          handleResponder={() => handleResponderEmailCadencia(false)}
          handleResponderTodos={() => handleResponderEmailCadencia(true)}
          mostrarExcluir={false}
        />
      ) : (
        <BarraResponder
          handleResponder={handleResponder}
          handleResponderTodos={handleResponderTodos}
          handleEncaminhar={handleEncaminhar}
          handleExcluir={handleExcluir}
          esconderBotaoResponder={responderEmail.esconderBotaoResponder}
          mostrarExcluir={mostrarExcluir}
          mostrarEncaminhar
        />
      )}
    </div>
  ) : (
    <div className={classes.editor}>
      <EditorTextoEmails
        key={`${templateId}-${responderEmail.isEncaminhando}-${responderEmail.emailRespondidoId}`}
        emailsTo={emailsTo}
        emailsCc={emailsCc}
        emailsCco={emailsCco}
        linksAnexos={linksAnexos}
        assinatura={assinatura}
        mensagensIdConteudo={mensagensIdConteudo}
        threadNormalizada={threadNormalizada}
        isLoading={isLoading}
        assunto={assunto}
        isRespondendo
        editorState={editorState}
        onChange={onChange}
        handleExibirTemplate={handleExibirTemplate}
        isEncaminhando={responderEmail.isEncaminhando}
        provider={provider}
        fecharModalLerEmail={fecharModalLerEmail}
        atualizarThreads={atualizarThreads}
        resetarThreads={resetarThreads}
        isCarregandoEmailsTo={responderEmail.isCarregandoEmailsTo}
        templateId={templateId}
        editorExpandido={editorExpandido}
        toggleEditorExpandido={toggleEditorExpandido}
        exibirBotaoExpandirEditor={exibirBotaoExpandirEditor}
        exibirBotaoExpandirCopias={exibirBotaoExpandirCopias}
        copiasExpandidas={copiasExpandidas}
        toggleCopiasExpandidas={toggleCopiasExpandidas}
        {...rest}
      />
    </div>
  );

ModalLerEmailResponder.propTypes = {
  classes: PropTypes.object,
  responderEmail: PropTypes.object,
  mostrarEditor: PropTypes.bool,
  assunto: PropTypes.string,
  editorState: PropTypes.object,
  onChange: PropTypes.func,
  handleExibirTemplate: PropTypes.func,
  provider: PropTypes.object,
  fecharModalLerEmail: PropTypes.func,
  atualizarThreads: PropTypes.func,
  resetarThreads: PropTypes.func,
  templateId: PropTypes.number,
  toggleEditorExpandido: PropTypes.func,
  editorExpandido: PropTypes.bool,
  exibirBotaoExpandirEditor: PropTypes.bool,
  exibirBotaoExpandirCopias: PropTypes.bool,
  copiasExpandidas: PropTypes.bool,
  toggleCopiasExpandidas: PropTypes.func,
  emailsTo: PropTypes.array.isRequired,
  emailsCc: PropTypes.array.isRequired,
  emailsCco: PropTypes.array.isRequired,
  linksAnexos: PropTypes.array.isRequired,
  assinatura: PropTypes.string,
  mensagensIdConteudo: PropTypes.array,
  threadNormalizada: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  handleResponder: PropTypes.func,
  handleResponderTodos: PropTypes.func,
  handleEncaminhar: PropTypes.func,
  handleExcluir: PropTypes.func,
  conteudoHtml: PropTypes.bool,
  conteudoTemplate: PropTypes.string,
  mostrarExcluir: PropTypes.bool,
  isEmailCadencia: PropTypes.bool,
  podeResponderEmail: PropTypes.bool,
  handleResponderEmailCadencia: PropTypes.func,
};

const enhance = compose(
  injectSheet(styles),
  withTheme
);

export default enhance(ModalLerEmailResponder);
