import { defineMessage } from "react-intl";

export const SCREEN_CONFIGS = {
  EDIT: {
    mode: "EDIT",
    successMessage: defineMessage({ defaultMessage: "Lista editada com sucesso." }),
  },
  DUPLICATE: {
    mode: "DUPLICATE",
    successMessage: defineMessage({ defaultMessage: "Lista duplicada com sucesso." }),
  },
  CREATE: {
    mode: "CREATE",
    successMessage: defineMessage({ defaultMessage: "Lista criada com sucesso." }),
  },
  VIEW: {
    mode: "VIEW",
    successMessage: "",
  },
};

SCREEN_CONFIGS.DEFAULT = SCREEN_CONFIGS.VIEW;
