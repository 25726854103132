import { initialState } from "./initialState";
import { types } from "./actions";

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.LOAD_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case types.LOAD_STOP: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case types.RECEIVE_LISTA: {
      return {
        ...state,
        lista: action.payload,
      };
    }
    case types.CHANGE_NOME_LISTA: {
      return {
        ...state,
        touchedNomeLista: true,
        lista: {
          ...state.lista,
          nome: action.payload,
        },
      };
    }
    case types.SET_TOUCHED_NOME_LISTA: {
      return {
        ...state,
        touchedNomeLista: action.payload,
      };
    }
    case types.TOGGLE_CONFIG_VALUE: {
      const { lista } = state;
      const configKey = action.payload;
      const targetConfig = lista.config[configKey];

      return {
        ...state,
        lista: {
          ...state.lista,
          config: {
            ...state.lista.config,
            [configKey]: !targetConfig,
          },
        },
      };
    }
    case types.SET_SCREEN_MODE: {
      return {
        ...state,
        screenMode: action.payload,
      };
    }
    case types.OPEN_EDIT_ALERT: {
      return {
        ...state,
        isOpenEditAlert: true,
      };
    }
    case types.CLOSE_EDIT_ALERT: {
      return {
        ...state,
        isOpenEditAlert: false,
      };
    }
    case types.RESET: {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
}
