import React from "react";
import styled from "styled-components";
import RemoveRedEye from "@material-ui/icons/RemoveRedEye";

export const RemoveRedEyeIcon = styled(({ possuiVisualizacoes, ...rest }) => <RemoveRedEye {...rest} />)`
  && {
    height: 20px;
    width: 20px;
    color: ${({ possuiVisualizacoes, theme }) =>
      possuiVisualizacoes ? theme.palette.icons.darkActiveIcon : theme.palette.icons.darkInactiveIcon};
    &:hover {
      color: ${({ possuiVisualizacoes, theme }) =>
        possuiVisualizacoes ? theme.palette.icons.darkActiveIcon : theme.palette.icons.darkInactiveIcon};
    }
  }
`;
