import React, { useCallback } from "react";
import RemoveRedEye from "@material-ui/icons/RemoveRedEye";
import { withTheme } from "react-jss";
import moment from "moment-timezone";
import { compose } from "recompose";

import { Flex, Tipografia } from "../../../_common";
import injectSheet from "../../../_common/hocs/injectSheet";

const styles = {
  tooltipVisualizacoesRoot: {
    padding: "8px 24px",
    alignContent: "stretch",
    margin: "0 -13px",
    flex: "none",
  },
  data: {
    marginTop: 2,
  },
  icon: {
    height: "20px !important",
    width: "20px !important",
    marginRight: 16,
  },
};

function IconeTrackingTooltipVisualizacoes({ theme, classes, visualizacoes }) {
  const formatDate = useCallback(
    date =>
      moment
        .utc(date)
        .tz(moment().tz())
        .format("DD MMM - HH:mm[h]")
        .toLowerCase(),
    [visualizacoes]
  );

  return visualizacoes.map((data, i) => (
    <Flex key={i} justifyContent="space-between" className={classes.tooltipVisualizacoesRoot}>
      <RemoveRedEye className={classes.icon} style={{ color: theme.palette.icons.darkActiveIcon }} />
      <Tipografia className={classes.data}>{formatDate(data)}</Tipografia>
    </Flex>
  ));
}

const enhance = compose(
  withTheme,
  injectSheet(styles)
);

export default enhance(IconeTrackingTooltipVisualizacoes);
