import React, { useState } from "react";
import { Tooltip } from "react-tippy";
import { withTheme } from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import cn from "classnames";
import { Popover } from "@material-ui/core";

import { Tipografia, Flex } from "../../../_common";
import injectSheet from "../../../_common/hocs/injectSheet";
import IconeTrackingTooltip from "../IconeTrackingTooltip/IconeTrackingTooltip";
import { RemoveRedEyeIcon } from "./styles";

const styles = {
  visualizacoes: {
    marginLeft: 8,
  },
  iconContainer: {
    marginTop: -2,
    position: "relative",
  },
  icon: {
    height: "20px !important",
    width: "20px !important",
  },
};

function IconeTracking({
  classes,
  classNameIcone,
  className,
  visualizacoes,
  escondeTooltip,
  escondeContador,
  isThread,
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const possuiVisualizacoes = visualizacoes?.length > 0;

  const IconeContainer = (
    <Flex inline alignItems="center" className={cn(classes.iconContainer, classNameIcone)}>
      <RemoveRedEyeIcon possuiVisualizacoes={possuiVisualizacoes} />
      {possuiVisualizacoes &&
        !escondeContador && (
          <Tipografia cor="darkSecondaryText" className={classes.visualizacoes}>
            {visualizacoes.length}
          </Tipografia>
        )}
    </Flex>
  );

  if (possuiVisualizacoes && !escondeTooltip) {
    return (
      <>
        <div onMouseEnter={e => setAnchorEl(e.currentTarget)}>{IconeContainer}</div>
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          anchorOrigin={{ horizontal: "center", vertical: "center" }}
          style={{ position: "absolute", left: -50, top: -50 }}
        >
          <IconeTrackingTooltip onMouseLeave={() => setAnchorEl(null)} visualizacoes={visualizacoes} />
        </Popover>
      </>
    );
  } else if (!possuiVisualizacoes && !escondeTooltip) {
    return (
      <Tooltip
        size="big"
        className={className}
        duration={0}
        position="bottom"
        title={`Destinatário ainda não leu o seu ${isThread ? "último " : ""}e-mail`}
      >
        {IconeContainer}
      </Tooltip>
    );
  }

  return IconeContainer;
}

IconeTracking.propTypes = {
  theme: PropTypes.object,
  classes: PropTypes.object,
  visualizacoes: PropTypes.array,
  escondeTooltip: PropTypes.bool,
  escondeContador: PropTypes.bool,
  className: PropTypes.string,
  classNameIcone: PropTypes.string,
  isThread: PropTypes.bool,
};

IconeTracking.defaultProps = {
  isThread: false,
};

const enhance = compose(
  withTheme,
  injectSheet(styles)
);

export default enhance(IconeTracking);
