import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { buscarTemplatesSelecao } from "../../../store/reducers/emails/emailsActions";

import ModalEscolherTemplate from "./ModalEscolherTemplate.jsx";

class ModalEscolherTemplateContainer extends React.Component {
  static propTypes = {
    templates: PropTypes.array,
    buscarTemplates: PropTypes.func,
    handleSelecionarTemplate: PropTypes.func,
    exibeSubstituirVariaveis: PropTypes.bool,
  };

  componentDidMount = () => {
    this.props.buscarTemplates();
  };

  render = () => (
    <ModalEscolherTemplate
      templates={this.props.templates}
      handleSelecionarTemplate={this.props.handleSelecionarTemplate}
      exibeSubstituirVariaveis={this.props.exibeSubstituirVariaveis}
      {...this.props}
    />
  );
}
const mapStateToProps = state => ({
  templates: state.emails.templates,
  exibeSubstituirVariaveis: state.emails.parametros.leadIdSelecionado != null,
});

const mapDispatchToProps = dispatch => ({ buscarTemplates: () => dispatch(buscarTemplatesSelecao()) });

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalEscolherTemplateContainer);
