export const NOME_LISTA_MAX_LENGTH = 100;

const findByTipoLista = tipoLista =>
  [LISTA_LEADS, LISTA_PROSPECTS, LISTA_DINAMICA].find(listaObj => listaObj.verificaTipo(tipoLista));

function verificaTipo(tipo) {
  return tipo === this.value || tipo === this.intValue;
}

export const LISTA_LEADS = {
  value: "ListaLeads",
  intValue: 1,
  icon: "business",
  route: "lista-leads",
  verificaTipo,
};

export const LISTA_PROSPECTS = {
  value: "ListaProspect",
  intValue: 2,
  icon: "person",
  route: "lista-prospects",
  verificaTipo,
};

export const LISTA_DINAMICA = {
  value: "Dinamica",
  intValue: 3,
  icon: "playlist_play",
  route: "lista-dinamica",
  verificaTipo,
};

export const getListaRoute = tipoLista => {
  const listaObj = findByTipoLista(tipoLista);
  return listaObj ? listaObj.route : "";
};

export const getListaIcon = tipoLista => {
  const listaObj = findByTipoLista(tipoLista);
  return listaObj ? listaObj.icon : "";
};
