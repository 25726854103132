import React from "react";

import injectSheet from "react-jss";
import PropTypes from "prop-types";

import { Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import { Loading } from "../../../_common";
import EditorTextoNovoEmail from "../EditorTextoNovoEmail/EditorTextoNovoEmail";
import ModalEscolherTemplate from "../ModalEscolherTemplate/ModalEscolherTemplate";

const styles = {
  paperScrollPaper: {
    height: 760,
  },
  paperScrollPaperSmall: {
    height: "auto",
  },
  modalPaperContainer: {
    borderTopRightRadius: "4px !important",
    borderTopLeftRadius: "4px !important",
  },
  modalContainer: {
    padding: "0 !important",
    overflow: "hidden",
    borderTopRightRadius: "4px !important",
    borderTopLeftRadius: "4px !important",
    display: "flex",
    flexDirection: "column",
    height: "calc(100vh - 80px)",
    maxHeight: "none !important",
  },
  modalContent: {
    height: "100%",
    overflowY: "scroll",
    overflowX: "hidden",
    padding: "0 8px 4px 8px",
  },
  modalConteudo: {
    padding: 8,
    paddingTop: 0,
    display: "flex",
    flex: "auto",
    flexDirection: "column",
    height: "100%",
  },
  dialogContent: {
    height: "calc(100vh - 80px)",
    top: -40,
  },
};

const ModalNovoEmail = ({
  classes,
  tituloCabecalho,
  exibeTemplates,
  handleExibirTemplate,
  handleSelecionarTemplate,
  templateId,
  emailsTo,
  handleFecharModal,
  linksAnexos,
  assinatura,
  mensagensIdConteudo,
  threadNormalizada,
  isLoading,
  emailsCc,
  emailsCco,
  assuntoInicial,
  conteudoHtml,
  conteudoTemplate,
  ...rest
}) => (
  <Dialog
    fullWidth
    maxWidth="lg"
    onClose={handleFecharModal}
    open
    {...rest}
    classes={{ paperScrollPaper: exibeTemplates ? classes.paperScrollPaperSmall : classes.paperScrollPaper }}
  >
    <DialogTitle>{tituloCabecalho}</DialogTitle>
    <DialogContent>
      <Loading isLoading={isLoading}>
        {exibeTemplates ? (
          <ModalEscolherTemplate handleSelecionarTemplate={handleSelecionarTemplate} />
        ) : (
          <div className={classes.modalConteudo}>
            <EditorTextoNovoEmail
              emailsTo={emailsTo}
              emailsCc={emailsCc}
              emailsCco={emailsCco}
              assuntoInicial={assuntoInicial}
              handleExibirTemplate={handleExibirTemplate}
              templateId={templateId}
              handleFecharModal={handleFecharModal}
              assinatura={assinatura}
              mensagensIdConteudo={mensagensIdConteudo}
              threadNormalizada={threadNormalizada}
              linksAnexos={linksAnexos}
              conteudoHtml={conteudoHtml}
              conteudoTemplate={conteudoTemplate}
            />
          </div>
        )}
      </Loading>
    </DialogContent>
  </Dialog>
);

ModalNovoEmail.propTypes = {
  classes: PropTypes.object.isRequired,
  tituloCabecalho: PropTypes.string,
  exibeTemplates: PropTypes.bool,
  handleExibirTemplate: PropTypes.func.isRequired,
  handleSelecionarTemplate: PropTypes.func.isRequired,
  templateId: PropTypes.number,
  emailsTo: PropTypes.array,
  emailsCc: PropTypes.array,
  emailsCco: PropTypes.array,
  assuntoInicial: PropTypes.string,
  handleFecharModal: PropTypes.func.isRequired,
  linksAnexos: PropTypes.array,
  assinatura: PropTypes.string,
  mensagensIdConteudo: PropTypes.array,
  threadNormalizada: PropTypes.object,
  isLoading: PropTypes.bool,
  conteudoHtml: PropTypes.bool,
  conteudoTemplate: PropTypes.string,
};

export default injectSheet(styles)(ModalNovoEmail);
