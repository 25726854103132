import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { compose } from "recompose";
import { withTheme } from "react-jss";

import injectSheet from "../../../_common/hocs/injectSheet";
import { validarEmail } from "../../../_common/utils/string";

const styles = {
  table: {
    fontSize: 12,
    margin: "1em",
  },
  tableHeader: {
    color: "#999",
    verticalAlign: "top",
    paddingRight: "2em",
    paddingBottom: "2em",
  },
  tableData: {
    color: "#202124",
    verticalAlign: "top",
    paddingBottom: "10px",
  },
};

const ModalDetalheDestinatarios = ({ classes, nomeDoDestinatario, destinatarios }) => {
  const intl = useIntl();

  return (
    <table className={classes.table}>
      <tr>
        <th className={classes.tableHeader}>{intl.formatMessage({ defaultMessage: "de:" })}</th>
        <td className={classes.tableData}>{nomeDoDestinatario}</td>
      </tr>
      <tr>
        <th className={classes.tableHeader}>{intl.formatMessage({ defaultMessage: "para:" })}</th>
        <td className={classes.tableData}>
          {destinatarios.map(
            (destinatario, index) =>
              destinatario.isCco !== true &&
              destinatario.isCc !== true && (
                <p key={index}>
                  {destinatario.name}
                  {destinatario.lastname !== null ? ` ${destinatario.lastname}` : ""}
                  {!validarEmail(destinatario.name) && <div>{`<${destinatario.email}>`}</div>}
                </p>
              )
          )}
        </td>
      </tr>
      {destinatarios.filter(item => item.isCc === true).length !== 0 && (
        <tr>
          <th className={classes.tableHeader}>cc:</th>
          <td className={classes.tableData}>
            {destinatarios.map(
              (destinatario, index) =>
                destinatario.isCc === true && (
                  <p key={index}>
                    {destinatario.name}
                    {destinatario.lastname !== null ? ` ${destinatario.lastname}` : ""}
                    {!validarEmail(destinatario.name) && <div>{`<${destinatario.email}>`}</div>}
                  </p>
                )
            )}
          </td>
        </tr>
      )}
      {destinatarios.filter(item => item.isCco === true).length !== 0 && (
        <tr>
          <th className={classes.tableHeader}>cco:</th>
          <td className={classes.tableData}>
            {destinatarios.map(
              (destinatario, index) =>
                destinatario.isCco === true && (
                  <p key={index}>
                    {destinatario.name}
                    {destinatario.lastname !== null ? ` ${destinatario.lastname}` : ""}
                    {!validarEmail(destinatario.name) && <div>{`<${destinatario.email}>`}</div>}
                  </p>
                )
            )}
          </td>
        </tr>
      )}
    </table>
  );
};

ModalDetalheDestinatarios.propTypes = {
  classes: PropTypes.object,
  destinatarios: PropTypes.array,
  nomeDoDestinatario: PropTypes.string.isRequired,
};

const enhance = compose(
  injectSheet(styles),
  withTheme
);

export default enhance(ModalDetalheDestinatarios);
