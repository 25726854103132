import React from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { compose } from "recompose";
import { injectIntl } from "react-intl";
import PropTypes from "prop-types";
import { isBoolean } from "lodash";

import {
  abrirEditorResponder as abrirEditorResponderAction,
  atualizarThreads as atualizarThreadsAction,
  resetarThreads as resetarThreadsAction,
  fecharModalLerEmail as fecharModalLerEmailAction,
  limparResponderEmails,
  selecionarTemplate,
  abrirModalLerEmail as abrirModalLerEmailAction,
  fecharEditorResponder,
  excluirEmail,
  getIdMensagemUnica,
} from "../../../store/reducers/emails/emailsActions";
import ModalLerEmail from "./ModalLerEmail.jsx";
import CreateSnackBar from "../../../_common/utils/snackbar/createSnackbar";
import { BUSCAR } from "../constantes";
import { AjaxBlackout } from "../../../_common/index";

class ModalLerEmailContainer extends React.Component {
  state = {
    exibeTemplates: false,
    templateId: null,
    editorExpandido: false,
    copiasExpandidas: false,
  };

  componentDidMount = () => {
    if (this.props.match.params.idOuQuery && this.props.abaAtual !== BUSCAR) {
      const thread = { id: this.props.match.params.idOuQuery, isIncompleta: true };
      this.props.abrirModalLerEmail(thread);
    }
  };

  handleClickTrash = async () => {
    AjaxBlackout.Show();
    try {
      await excluirEmail(this.props.thread.id, this.props.thread.folder);

      this.props.fecharModalLerEmail();
      this.props.resetarThreads();
      this.props.atualizarThreads();
      CreateSnackBar(this.props.intl.formatMessage({ defaultMessage: "E-mail removido com sucesso." }));
    } catch (e) {
      CreateSnackBar(this.props.intl.formatMessage({ defaultMessage: "Houve um erro ao excluir o e-mail." }));
    }
    AjaxBlackout.Hide();
  };

  handleExibirTemplate = () => {
    this.setState({ exibeTemplates: true });
  };

  handleSelecionarTemplate = templateId => {
    let templateSelecionado = this.props.templates.find(x => x.id === templateId);
    if (!templateSelecionado) templateSelecionado = { conteudoHtml: false, templateSelecionado: "" };
    this.setState({
      templateId,
      exibeTemplates: false,
      conteudoHtml: templateSelecionado.conteudoHtml,
      conteudoTemplate: templateSelecionado.conteudo,
    });

    this.props.selecionarTemplate(templateId);
  };

  handleFecharModal = () => {
    this.props.fecharModalLerEmail();
    this.props.limparResponderEmails();
    if (this.props.match.params.idOuQuery && this.props.abaAtual !== BUSCAR) {
      this.props.history.push(`/spotter/central-emails/${this.props.abaAtual}`);
    }
    this.toggleEditorExpandido(false);
    this.toggleCopiasExpandidas(false);
    this.resetState();
  };

  resetState = () => {
    this.setState({ templateId: null, exibeTemplates: false, conteudoHtml: false, conteudoTemplate: "" });
  };

  geraTituloCabecalho = () => {
    const { thread, mostrarEditor } = this.props;

    if (thread.isEmailCadencia) {
      return thread.subject;
    }
    if (thread && !thread.isIncompleta) {
      return mostrarEditor ? `${thread.subject}` : thread.subject;
    }
    return "";
  };

  toggleEditorExpandido = editorExpandido => {
    this.setState(state => ({
      editorExpandido: isBoolean(editorExpandido) ? editorExpandido : !state.editorExpandido,
    }));
  };

  toggleCopiasExpandidas = copiasExpandidas => {
    this.setState(state => ({
      copiasExpandidas: isBoolean(copiasExpandidas) ? copiasExpandidas : !state.copiasExpandidas,
    }));
  };

  abrirEditorResponder = (...args) => {
    this.resetState();
    this.props.abrirEditorResponder(...args);
  };

  handleResponderOuEncaminhar = (emailId, isEncaminhando, messageId) => {
    if (
      this.props.isEncaminhando === isEncaminhando &&
      this.props.emailRespondidoId === emailId &&
      this.props.mostrarEditor
    ) {
      return;
    }
    this.props.fecharEditorResponder();

    const conteudoEmail = getIdMensagemUnica(emailId, this.props.thread, messageId);

    let emailsTo = [];
    if (this.props.usuario.email === this.props.thread.from.email) {
      emailsTo = this.props.thread.to;
    } else {
      emailsTo = [this.props.thread.from];
    }

    if (isEncaminhando) {
      emailsTo = [];
    }

    this.abrirEditorResponder(emailId, { emailsTo }, isEncaminhando, conteudoEmail);
  };

  render() {
    const {
      open,
      thread,
      openModalLerEmail,
      anexosVerificados,
      mostrarEditor,
      podeEnviarEmail,
      isPVOrVendedor,
      ...rest
    } = this.props;

    const { editorExpandido, copiasExpandidas } = this.state;

    return this.props.openModalLerEmail ? (
      <ModalLerEmail
        handleResponderOuEncaminhar={this.handleResponderOuEncaminhar}
        tituloCabecalho={this.geraTituloCabecalho()}
        handleFecharModal={this.handleFecharModal}
        exibeTemplates={this.state.exibeTemplates}
        handleClickTrash={this.handleClickTrash}
        handleExibirTemplate={this.handleExibirTemplate}
        handleSelecionarTemplate={this.handleSelecionarTemplate}
        templateId={this.state.templateId}
        open={openModalLerEmail}
        threadEmails={thread}
        anexosVerificados={anexosVerificados}
        editorExpandido={editorExpandido}
        toggleEditorExpandido={this.toggleEditorExpandido}
        exibirBotaoExpandirEditor
        exibirBotaoExpandirCopias
        copiasExpandidas={copiasExpandidas}
        toggleCopiasExpandidas={this.toggleCopiasExpandidas}
        podeEnviarEmail={podeEnviarEmail}
        isPVOrVendedor={isPVOrVendedor}
        conteudoHtml={this.state.conteudoHtml}
        conteudoTemplate={this.state.conteudoTemplate}
        resetState={this.resetState}
        {...rest}
      />
    ) : null;
  }
}

const mapStateToProps = state => ({
  podeEnviarEmail: state.emails && state.emails.isAutenticado && !state.emails.isAutenticacaoComFalha,
  thread: state.emails.responderEmail.threadNormalizada,
  usuario: state.emails.responderEmail.usuarioLogado,
  mostrarEditor: state.emails.responderEmail.mostrarEditor,
  openModalLerEmail: state.emails.mostrarModalLeitura,
  abaAtual: state.emails.abaAtual,
  anexosVerificados: state.emails.responderEmail.anexosVerificados,
  isEncaminhando: state.emails.responderEmail.isEncaminhando,
  emailRespondidoId: state.emails.responderEmail.emailRespondidoId,
  isPVOrVendedor: state.usuario.isPVOrVendedor,
  templates: state.emails.templates,
});

const mapDispatchToProps = dispatch => ({
  limparResponderEmails: () => dispatch(limparResponderEmails()),
  fecharModalLerEmail: () => dispatch(fecharModalLerEmailAction()),
  abrirEditorResponder: (emailId, destinatarios, isEncaminhando, mensagensIdConteudo) =>
    dispatch(abrirEditorResponderAction(emailId, destinatarios, isEncaminhando, mensagensIdConteudo)),
  atualizarThreads: () => dispatch(atualizarThreadsAction()),
  resetarThreads: () => dispatch(resetarThreadsAction()),
  selecionarTemplate: templateId => dispatch(selecionarTemplate(templateId)),
  abrirModalLerEmail: thread => dispatch(abrirModalLerEmailAction(thread)),
  fecharEditorResponder: () => dispatch(fecharEditorResponder()),
});

ModalLerEmailContainer.propTypes = {
  thread: PropTypes.object,
  usuario: PropTypes.object,
  limparResponderEmails: PropTypes.func,
  fecharModalLerEmail: PropTypes.func.isRequired,
  abrirEditorResponder: PropTypes.func.isRequired,
  atualizarThreads: PropTypes.func.isRequired,
  resetarThreads: PropTypes.func.isRequired,
  history: PropTypes.object,
  open: PropTypes.bool,
  openModalLerEmail: PropTypes.bool,
  selecionarTemplate: PropTypes.func,
  abrirModalLerEmail: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  abaAtual: PropTypes.string.isRequired,
  anexosVerificados: PropTypes.bool,
  mostrarEditor: PropTypes.bool,
  fecharEditorResponder: PropTypes.func,
  isEncaminhando: PropTypes.bool,
  emailRespondidoId: PropTypes.string,
  podeEnviarEmail: PropTypes.bool,
  isPVOrVendedor: PropTypes.bool,
  templates: PropTypes.array,
  intl: PropTypes.object,
};

const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withRouter,
  injectIntl
);

export default enhance(ModalLerEmailContainer);
