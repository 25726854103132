import React from "react";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { injectIntl } from "react-intl";

import ModalNovoEmail from "./ModalNovoEmail.jsx";
import {
  limparResponderEmails,
  fecharModalEscreverEmail as fecharModalEscreverEmailAction,
  setParametrosEmail,
} from "../../../store/reducers/emails/emailsActions";

const initialState = {
  exibeTemplates: true,
  templateId: null,
};

class ModalNovoEmailContainer extends React.PureComponent {
  state = { ...initialState };

  handleExibirTemplate = () => {
    this.props.setParametrosModalEmail({ permiteUtilizarTemplates: true });
  };

  handleSelecionarTemplate = async (templateId, substituiVariaveis) => {
    let templateSelecionado = this.props.templates.find(x => x.id === templateId);

    const parametros = {
      permiteUtilizarTemplates: false,
      substituiVariaveisTemplate: false,
    };

    if (templateSelecionado && substituiVariaveis && this.props.leadIdSelecionado) {
      parametros.substituiVariaveisTemplate = true;
    }

    if (!templateSelecionado) templateSelecionado = { conteudoHtml: false, templateSelecionado: "" };

    this.setState({
      templateId,
      conteudoHtml: templateSelecionado.conteudoHtml,
      conteudoTemplate: templateSelecionado.conteudo,
    });

    this.props.setParametrosModalEmail(parametros);
  };

  handleFecharModal = () => {
    this.props.limparResponderEmails();
    this.props.fecharModalEscreverEmail();
    this.setState({ ...initialState });
  };

  render = () =>
    this.props.open ? (
      <ModalNovoEmail
        emailsTo={this.props.responderEmail.emailsTo}
        emailsCc={this.props.responderEmail.emailsCc}
        emailsCco={this.props.responderEmail.emailsCco}
        assuntoInicial={this.props.responderEmail?.threadNormalizada?.subject ?? ""}
        templateId={this.state.templateId}
        exibeTemplates={this.props.exibeListaTemplates}
        tituloCabecalho={this.props.intl.formatMessage({ defaultMessage: "Novo e-mail" })}
        handleExibirTemplate={this.handleExibirTemplate}
        handleSelecionarTemplate={this.handleSelecionarTemplate}
        handleFecharModal={this.handleFecharModal}
        linksAnexos={this.props.responderEmail.linksAnexos}
        assinatura={this.props.responderEmail.assinatura}
        mensagensIdConteudo={this.props.responderEmail.mensagensIdConteudo}
        threadNormalizada={this.props.responderEmail.threadNormalizada}
        isLoading={!this.props.usuarioLogado}
        conteudoHtml={this.state.conteudoHtml}
        conteudoTemplate={this.state.conteudoTemplate}
      />
    ) : null;
}

ModalNovoEmailContainer.propTypes = {
  open: PropTypes.bool,
  exibeListaTemplates: PropTypes.bool,
  limparResponderEmails: PropTypes.func,
  setParametrosModalEmail: PropTypes.func,
  fecharModalEscreverEmail: PropTypes.func.isRequired,
  responderEmail: PropTypes.object,
  usuarioLogado: PropTypes.object,
  templates: PropTypes.array,
  intl: PropTypes.object,
  leadIdSelecionado: PropTypes.number,
};

const mapStateToProps = state => ({
  open: state.emails.mostrarModalEscrever,
  usuarioLogado: state.emails.usuarioLogado,
  responderEmail: state.emails.responderEmail,
  templates: state.emails.templates,
  exibeListaTemplates: state.emails.parametros.permiteUtilizarTemplates,
  leadIdSelecionado: state.emails.parametros.leadIdSelecionado,
});

const mapDispatchToProps = dispatch => ({
  fecharModalEscreverEmail: () => dispatch(fecharModalEscreverEmailAction()),
  limparResponderEmails: () => dispatch(limparResponderEmails()),
  setParametrosModalEmail: parametros => dispatch(setParametrosEmail(parametros)),
});

const enhance = compose(
  injectIntl,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
);

export default enhance(ModalNovoEmailContainer);
