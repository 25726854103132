import React, { useState, useMemo } from "react";
import { Button, TextField, Divider, MenuItem, Menu } from "@material-ui/core";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import Delete from "@material-ui/icons/Delete";
import Receipt from "@material-ui/icons/Receipt";
import cn from "classnames";
import EditorTexto from "../../../_common/containers/EditorTexto";
import { Flex, ActionsEditorTexto, Divider as DividerCommon } from "../../../_common";
import {
  ActionsEditorTextoButton,
  ActionsEditorTextoDivider,
  ActionsEditorTextoAttachments,
} from "../../../_common/components/EditorTexto/Actions";
import injectSheet from "../../../_common/hocs/injectSheet";
import ComposerMentorIA from "../../../ComposerMentorIA/ComposerMentorIA";

import EmailConteudoExpandir from "../EmailConteudoExpandir/EmailConteudoExpandir";
import LinksAnexos from "../LinksAnexos/LinksAnexos";
import ModalEnviarSemContatos from "../ModalEnviarSemContatos/ModalEnviarSemContatos";
import ModalEnviarPreencherVariaveis from "../ModalEnviarPreencherVariaveis/ModalEnviarPreencherVariaveis";
import ModalEnviarSelecionarContato from "../ModalEnviarSelecionarContato/ModalEnviarSelecionarContato";
import BarraDestinatarios from "../BarraDestinatarios/BarraDestinatarios";
import Variable from "../../../_common/icones/Variable";
import EmailItemConteudo from "../EmailItemConteudo/EmailItemConteudo";
import { useTipoPlanoSpotter } from "../../../hooks";

const styles = theme => ({
  rootEstragado: {
    top: "0px !important",
    left: "0px !important",
  },
  botaoEnviar: {
    marginRight: 8,
  },
  editor: {
    flex: "none",
    margin: "8px 0",
    fontWeight: "400",
    fontSize: 14,
    minHeight: "calc(100% - 42px)",
    height: "200px",
  },
  containerEditor: {
    overflowY: "auto",
    flex: "auto",
    height: "100%",
  },
  containerAnexos: {
    flex: "none",
  },
  chip: {
    height: 24,
    alignItems: "center",
    marginTop: "4px !important",
  },
  chipFocused: {
    backgroundColor: "#BDBDBD !important",
  },
  avatar: {
    fontSize: "14px !important",
    height: "24px !important",
    width: "24px !important",
    backgroundColor: "#616161 !important",
    marginRight: 8,
  },
  botaoExcluir: {
    width: "25px !important",
    height: "25px !important",
    marginRight: "25px !important",
    cursor: "pointer",
  },
  divAssunto: {
    display: "flex",
    margin: "2px 2px 15px 4px",
  },
  campoAssunto: {
    fontWeight: "500",
    fontSize: 16,
    float: "rigth",
  },
  flex: {
    alignItems: "center",
    flex: "none",
  },
  noFlex: {
    flex: "none",
  },
  tooltipContainer: {
    flex: "none",
    width: 48,
    marginTop: 6,
    marginLeft: -12,
  },
  loading: {
    height: "32px !important",
  },
  wrapperDestinatarios: {
    display: "flex",
    flex: "none",
    alignItems: "center",
  },
  icone: {
    fill: `${theme.palette.icons.darkInactiveIcon} !important`,
    "&:hover": {
      fill: `${theme.palette.icons.darkActiveIcon} !important`,
    },
    "&.active": {
      fill: `${theme.palette.icons.darkActiveIcon} !important`,
      backgroundColor: "rgba(0,0,0,.12)",
    },
  },
  iconeExpandirRecolher: {
    height: "20px !important",
    width: "20px !important",
  },
  avatarMagicWrite: {
    height: "25px !important",
    width: "25px !important",
  },
  expandirEditor: {
    width: 40,
    height: 40,
    padding: 0,
  },
  divider: {
    height: 20,
    marginLeft: "auto",
  },
  dividerHorizontal: {
    margin: "-1px -8px 0 -8px !important",
  },
  dividerBotoesEditor: {
    marginBottom: "8px !important",
  },
  varIcon: {
    fill: "rgba(0, 0, 0, 0.70) !important",
  },
});

const EditorTextoEmails = ({
  classes,
  editorState,
  onChange,
  enviarEmail,
  handleExcluirRascunho,
  handleExibirTemplate,
  handleFieldChanges,
  emailsTo,
  emailsCc,
  emailsCco,
  assunto,
  isRespondendo,
  isEncaminhando,
  isCarregandoEmailsTo,
  dataSourceChip,
  handleRequestAddChipTo,
  handleRequestAddChipCc,
  handleRequestAddChipCco,
  handleRequestDeleteChipTo,
  handleRequestDeleteChipCc,
  handleRequestDeleteChipCco,
  handleChangeInputChip,
  handleOnBlurChipTo,
  handleOnBlurChipCc,
  handleOnBlurChipCco,
  activeButtons,
  handleExcluirLinkAnexo,
  linksAnexos,
  attachmentClick,
  setFocusCampoMensagem,
  preencherContato,
  editorExpandido,
  toggleEditorExpandido,
  exibirBotaoExpandirEditor,
  esconderDivider,
  exibirBotaoExpandirCopias,
  copiasExpandidas,
  toggleCopiasExpandidas,
  handleClickMenuVariaveis,
  openMenuVariaveis,
  handleCloseMenuVariaveis,
  menuVariaveisAnchorEl,
  handleClickVariavel,
  listaDeVariaveis,
  ocultaBotaoVariaveis,
  conteudoHtml,
  conteudoTemplate,
  setFocusFn,
  escreverConteudoMagicWrite,
  ocultarDescartarRascunho,
  ocultarBarraDestinatarios,
}) => {
  const dataSourceChipParaComRender = dataSourceChip?.map(emailTo => ({
    ...emailTo,
    render: <MenuItem>{emailTo.text}</MenuItem>,
  }));
  const intl = useIntl();
  const isSpotterFull = useTipoPlanoSpotter();

  const [textoMagic, setTextoMagic] = useState();
  const textoPesquisadoMagic = useMemo(() => textoMagic, [textoMagic]);

  const escreverConteudo = (pergunta, resposta) => {
    escreverConteudoMagicWrite(pergunta, resposta);
  };

  return (
    <React.Fragment>
      {!esconderDivider && <Divider className={cn(classes.noFlex, classes.dividerHorizontal)} />}
      {!ocultarBarraDestinatarios && (
        <>
          <BarraDestinatarios
            emailsTo={emailsTo}
            emailsCc={emailsCc}
            emailsCco={emailsCco}
            isCarregandoEmailsTo={isCarregandoEmailsTo}
            dataSourceChip={dataSourceChipParaComRender}
            handleRequestAddChipTo={handleRequestAddChipTo}
            handleRequestAddChipCc={handleRequestAddChipCc}
            handleRequestAddChipCco={handleRequestAddChipCco}
            handleRequestDeleteChipTo={handleRequestDeleteChipTo}
            handleRequestDeleteChipCc={handleRequestDeleteChipCc}
            handleRequestDeleteChipCco={handleRequestDeleteChipCco}
            handleChangeInputChip={handleChangeInputChip}
            handleOnBlurChipTo={handleOnBlurChipTo}
            handleOnBlurChipCc={handleOnBlurChipCc}
            handleOnBlurChipCco={handleOnBlurChipCco}
            setFocusCampoMensagem={setFocusCampoMensagem}
            editorExpandido={editorExpandido}
            toggleEditorExpandido={toggleEditorExpandido}
            exibirBotaoExpandirEditor={exibirBotaoExpandirEditor}
            exibirBotaoExpandirCopias={exibirBotaoExpandirCopias}
            copiasExpandidas={copiasExpandidas}
            toggleCopiasExpandidas={toggleCopiasExpandidas}
          />
          <Divider className={cn(classes.noFlex, classes.dividerHorizontal)} />
        </>
      )}

      {!isRespondendo &&
        !isEncaminhando && (
          <div className={classes.divAssunto}>
            <TextField
              id="campoAssunto"
              name="campoAssunto"
              placeholder={intl.formatMessage({
                defaultMessage: "Assunto",
              })}
              className={classes.campoAssunto}
              value={assunto}
              fullWidth
              onChange={e => handleFieldChanges("assunto", e)}
              tabIndex={0}
              InputProps={{ disableUnderline: true }}
            />
            {!ocultaBotaoVariaveis && (
              <ActionsEditorTextoButton
                tabIndex={-1}
                IconComponent={Variable}
                onClick={handleClickMenuVariaveis}
                tooltip={intl.formatMessage({ defaultMessage: "Inserir variável" })}
                id="btnInserirVariavelAssunto"
              />
            )}
          </div>
        )}
      {conteudoHtml ? (
        <React.Fragment>
          <Flex className={classes.containerEditor} flexDirection="column">
            <EmailItemConteudo emailBody={conteudoTemplate} />
            {linksAnexos.length > 0 && (
              <Flex className={classes.containerAnexos}>
                <LinksAnexos links={linksAnexos} handleClickExcluir={handleExcluirLinkAnexo} modoTela />
              </Flex>
            )}
            <EmailConteudoExpandir />
          </Flex>
        </React.Fragment>
      ) : (
        <Flex className={classes.containerEditor} flexDirection="column">
          <EditorTexto
            setFocusFn={setFocusFn}
            setFocus={setFocusCampoMensagem}
            className={classes.editor}
            editorState={editorState}
            onChange={onChange}
            placeholder={intl.formatMessage({ defaultMessage: "Mensagem" })}
          />
          {linksAnexos.length > 0 && (
            <Flex className={classes.containerAnexos}>
              <LinksAnexos links={linksAnexos} handleClickExcluir={handleExcluirLinkAnexo} modoTela />
            </Flex>
          )}
          <EmailConteudoExpandir />
        </Flex>
      )}
      <Divider className={cn(classes.noFlex, classes.dividerHorizontal, classes.dividerBotoesEditor)} />
      <Flex className={classes.flex}>
        <Button
          id="btnEnviar"
          disabled={isCarregandoEmailsTo}
          onClick={e => {
            e.preventDefault();
            enviarEmail();
          }}
          variant="contained"
          color="primary"
          className={classes.botaoEnviar}
        >
          {intl.formatMessage({ defaultMessage: "Enviar" })}
        </Button>
        <ActionsEditorTexto
          bloquearBotoes={conteudoHtml}
          activeButtons={activeButtons}
          onChange={onChange}
          editorState={editorState}
        >
          <ActionsEditorTextoAttachments attachmentClick={attachmentClick} ordem="1" />
          <ActionsEditorTextoDivider />
          <ActionsEditorTextoButton
            IconComponent={Receipt}
            onClick={handleExibirTemplate}
            tooltip={intl.formatMessage({ defaultMessage: "Templates" })}
          />
          <ActionsEditorTextoDivider />
          {!ocultaBotaoVariaveis && (
            <>
              <ActionsEditorTextoButton
                IconComponent={Variable}
                onClick={handleClickMenuVariaveis}
                tooltip={intl.formatMessage({ defaultMessage: "Inserir variável" })}
                id="btnInserirVariavelCorpo"
                disabled={conteudoHtml}
              />

              <Menu
                open={openMenuVariaveis}
                anchorEl={menuVariaveisAnchorEl}
                onClose={handleCloseMenuVariaveis}
                PaperProps={{ style: { maxHeight: 350 } }}
              >
                {listaDeVariaveis.map(variavel => {
                  if (variavel.nome === "Linha") return <Divider variant="middle" />;
                  const nomeVariavel =
                    !isSpotterFull && variavel.nome.includes("pré-vendedor")
                      ? variavel.nome.replace("pré-vendedor", "usuário")
                      : variavel.nome;
                  return (
                    <MenuItem
                      onClick={e => handleClickVariavel(e, `{{${variavel.chave}}}`)}
                      key={variavel.id}
                      value={`{{${variavel.chave}}}`}
                    >
                      {nomeVariavel}
                    </MenuItem>
                  );
                })}
              </Menu>
            </>
          )}
          <DividerCommon className={classes.divider} />
          <Flex style={{ width: 9 }}>
            <ComposerMentorIA
              escreverConteudoMagicWrite={escreverConteudo}
              textoBuscaMagicWrite={textoPesquisadoMagic}
              atualizarTextoPesquisado={setTextoMagic}
              classes={classes}
            />
          </Flex>
          {!ocultarDescartarRascunho && (
            <>
              <DividerCommon style={{ height: 20 }} />

              <ActionsEditorTextoButton
                id="btnDescartarRascunho"
                className={cn(classes.botaoExcluir, classes.icone)}
                IconComponent={Delete}
                onClick={handleExcluirRascunho}
              />
            </>
          )}
        </ActionsEditorTexto>

        <ModalEnviarSemContatos />
        <ModalEnviarPreencherVariaveis />
        <ModalEnviarSelecionarContato
          isEncaminhando={isEncaminhando}
          isRespondendo={isRespondendo}
          preencherContato={preencherContato}
        />
      </Flex>
    </React.Fragment>
  );
};

EditorTextoEmails.propTypes = {
  editorState: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  classes: PropTypes.object,
  enviarEmail: PropTypes.func.isRequired,
  handleExcluirRascunho: PropTypes.func.isRequired,
  handleExibirTemplate: PropTypes.func.isRequired,
  handleFieldChanges: PropTypes.func.isRequired,
  emailsTo: PropTypes.array.isRequired,
  emailsCc: PropTypes.array.isRequired,
  emailsCco: PropTypes.array.isRequired,
  assunto: PropTypes.string,
  isRespondendo: PropTypes.bool.isRequired,
  isEncaminhando: PropTypes.bool,
  dataSourceChip: PropTypes.array.isRequired,
  handleRequestAddChipTo: PropTypes.func.isRequired,
  handleRequestAddChipCc: PropTypes.func.isRequired,
  handleRequestAddChipCco: PropTypes.func.isRequired,
  handleRequestDeleteChipTo: PropTypes.func.isRequired,
  handleRequestDeleteChipCc: PropTypes.func.isRequired,
  handleRequestDeleteChipCco: PropTypes.func.isRequired,
  handleChangeInputChip: PropTypes.func.isRequired,
  handleOnBlurChipTo: PropTypes.func.isRequired,
  handleOnBlurChipCc: PropTypes.func.isRequired,
  handleOnBlurChipCco: PropTypes.func.isRequired,
  isCarregandoEmailsTo: PropTypes.bool,
  handleExcluirLinkAnexo: PropTypes.func.isRequired,
  linksAnexos: PropTypes.array.isRequired,
  attachmentClick: PropTypes.func.isRequired,
  preencherContato: PropTypes.func.isRequired,
  activeButtons: PropTypes.object,
  setFocusCampoMensagem: PropTypes.bool,
  editorExpandido: PropTypes.bool,
  toggleEditorExpandido: PropTypes.func,
  exibirBotaoExpandirEditor: PropTypes.bool,
  esconderDivider: PropTypes.bool,
  exibirBotaoExpandirCopias: PropTypes.bool,
  copiasExpandidas: PropTypes.bool,
  toggleCopiasExpandidas: PropTypes.func,
  handleClickMenuVariaveis: PropTypes.func,
  openMenuVariaveis: PropTypes.bool.isRequired,
  handleCloseMenuVariaveis: PropTypes.func.isRequired,
  menuVariaveisAnchorEl: PropTypes.object,
  handleClickVariavel: PropTypes.func.isRequired,
  listaDeVariaveis: PropTypes.array.isRequired,
  conteudoHtml: PropTypes.bool,
  conteudoTemplate: PropTypes.string,
  setFocusFn: PropTypes.func,
  ocultaBotaoVariaveis: PropTypes.bool,
  escreverConteudoMagicWrite: PropTypes.func,
  ocultarDescartarRascunho: PropTypes.bool,
  ocultarBarraDestinatarios: PropTypes.bool,
};

EditorTextoEmails.defaultProps = {
  isCarregandoEmailsTo: false,
  ocultaBotaoVariaveis: false,
  ocultarDescartarRascunho: false,
  ocultarBarraDestinatarios: false,
};

export default injectSheet(styles)(EditorTextoEmails);
