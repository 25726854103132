import React from "react";
import PropTypes from "prop-types";
import { Divider } from "@material-ui/core";
import { withTheme } from "react-jss";
import { compose } from "recompose";
import injectSheet from "../../../_common/hocs/injectSheet";
import EmailItemHeader from "../EmailItemHeader/EmailItemHeader";
import EmailItemConteudo from "../EmailItemConteudo/EmailItemConteudo";

const styles = {
  emailItemContainer: {
    padding: "0 0 0 16px",
    width: "100%",
    backgroundColor: ({ mostrarPrevia }) => (mostrarPrevia ? "#F5F5F5" : "transparent"),
  },
  textoDataEnvio: {
    marginLeft: "auto",
  },
  divider: {
    marginTop: "16px !important",
    marginLeft: "-16px !important",
  },
};

const EmailItem = ({
  email,
  anchorEl,
  handleClick,
  handleClose,
  mostrarPrevia,
  modoVisualizacao,
  esconderDivider,
  destinatarios,
  threadEmails,
  fecharEditorResponder,
  handleClickHeader,
  mostrarMaisOpcoes,
  handleResponder,
  handleEncaminhar,
  isPVOrVendedor,
  classes,
  theme,
  esconderBotaoResponder,
  ...rest
}) => (
  <div className={classes.emailItemContainer}>
    <EmailItemHeader
      enviadoHa={email.date}
      {...rest}
      {...{
        email,
        threadEmails,
        anchorEl,
        mostrarMaisOpcoes,
        modoVisualizacao,
        isPVOrVendedor,
        destinatarios,
        handleClick,
        handleClose,
        handleEncaminhar,
        handleResponder,
        handleClickHeader,
        fecharEditorResponder,
        esconderBotaoResponder,
      }}
    />
    <EmailItemConteudo emailBody={mostrarPrevia ? email.preview : email.body} />
    {!esconderDivider && <Divider className={classes.divider} />}
  </div>
);

EmailItem.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  email: PropTypes.object.isRequired,
  anchorEl: PropTypes.object,
  handleClick: PropTypes.func,
  handleClose: PropTypes.func,
  mostrarPrevia: PropTypes.bool,

  /** Propriedade responsável por ocultar o botão de menu
   * que contém os botões Responder e Encaminhar */
  modoVisualizacao: PropTypes.bool,

  esconderDivider: PropTypes.bool,
  destinatarios: PropTypes.array.isRequired,

  threadEmails: PropTypes.object,
  fecharEditorResponder: PropTypes.func,
  handleClickHeader: PropTypes.func,
  mostrarExpandirEmail: PropTypes.bool.isRequired,
  mostrarMaisOpcoes: PropTypes.bool,
  handleResponder: PropTypes.func.isRequired,
  handleEncaminhar: PropTypes.func.isRequired,
  isPVOrVendedor: PropTypes.bool,
  esconderBotaoResponder: PropTypes.bool,
};

const enhance = compose(
  injectSheet(styles),
  withTheme
);

export default enhance(EmailItem);
