import axios from "axios";
import { objectKeysToCamelCase } from "../../../_common/utils/string";

import { ABAS_GMAIL, ABAS_OUTLOOK, ABAS_SMTP, PROVIDERS, retornarEndPoints } from "../constantes";
import {
  errorResponseInterceptor,
  requestInterceptor,
  requestInterceptorError,
  responseInterceptor,
} from "../../../_common/utils/api/interceptors";

const instanceAxios = axios.create();
const interceptor = ({ data }) => objectKeysToCamelCase(data);

let TOKEN_JWT;

let ENDPOINTS = {};

function RetornarUrlAutenticacaoPorProvider(provider) {
  switch (provider) {
    case PROVIDERS.Gmail:
      return ENDPOINTS.AutenticacaoGmail;
    case PROVIDERS.Outlook:
      return ENDPOINTS.AutenticacaoOutlook;
    default:
      return "";
  }
}

const getHeaderAuthorization = params => ({
  headers: {
    ...params,
    Authorization: `Bearer ${TOKEN_JWT}`,
  },
});

export const listarEmails = async filtros => {
  const emails = await instanceAxios.get(ENDPOINTS.Listar, getHeaderAuthorization(filtros));

  return emails.data;
};

export const buscarPorThreadId = (threadId, folder) =>
  instanceAxios
    .get(`${ENDPOINTS.BuscarPorThreadId}?threadId=${threadId}&folder=${folder}`, getHeaderAuthorization())
    .then(interceptor);

export function setApiInfo({ UrlSpotterMailService, tokenJwt }) {
  TOKEN_JWT = tokenJwt;
  ENDPOINTS = retornarEndPoints(UrlSpotterMailService);
}

export function autenticar(tipoProvider) {
  const url = `${RetornarUrlAutenticacaoPorProvider(tipoProvider)}?callbackUrl=${window.location.href}`;
  return instanceAxios.get(url, getHeaderAuthorization());
}

export function desautenticar() {
  return instanceAxios.get(`${ENDPOINTS.Desautenticar}`, getHeaderAuthorization());
}

export function buscarAbas(tipoProvider) {
  switch (PROVIDERS[tipoProvider]) {
    default:
    case PROVIDERS.Gmail:
      return ABAS_GMAIL;
    case PROVIDERS.Outlook:
      return ABAS_OUTLOOK;
    case PROVIDERS.SMTP:
      return ABAS_SMTP;
  }
}

export function buscarTotalThreads(pasta) {
  return instanceAxios.get(`${ENDPOINTS.TotalThreads}?pasta=${pasta}`, getHeaderAuthorization());
}

export function buscarThreads(pasta, pageToken, pesquisa) {
  try {
    return instanceAxios
      .get(
        `${ENDPOINTS.ListarEmail}`,
        getHeaderAuthorization({ search: JSON.stringify({ pasta, pageToken, pesquisa }) })
      )
      .then(interceptor);
  } catch (error) {
    return {
      threads: [],
      nextPageToken: null,
      error,
    };
  }
}

export function alterarFlagEmail(id, isFlagged) {
  const url = `${ENDPOINTS.AlterarFlag}`;
  return instanceAxios.post(url, { id, isFlagged: !isFlagged }, getHeaderAuthorization());
}

export function alterarImportant(id, isImportant) {
  const url = `${ENDPOINTS.AlterarImportant}`;
  return instanceAxios.post(url, { id, isImportant: !isImportant }, getHeaderAuthorization());
}

export function excluirEmail(id, folder) {
  const url = `${ENDPOINTS.RemoverEmail}`;
  return instanceAxios.post(url, { id, folder }, getHeaderAuthorization());
}

export function marcarComoLido(id) {
  return instanceAxios.post(ENDPOINTS.MarcarEmailLido, { id }, getHeaderAuthorization());
}

export async function salvarMensagemEnviada(threadId, threadNormalizada, usuarioSpotter, listaLeads) {
  if (listaLeads && listaLeads.length > 0) {
    const threadSalvar = {
      ...threadNormalizada,
      anexosLista: [],
      messages: threadNormalizada?.messages?.map(el => ({
        ...el,
        bodyOriginal: "",
      })),
    };

    const dadosThread = {
      preVendedorId: usuarioSpotter.id,
      threadId,
      empresaClienteId: usuarioSpotter.empresaClienteId,
      listaLeads,
      threadSalvar,
    };

    await instanceAxios.post(ENDPOINTS.SalvarThread, dadosThread, getHeaderAuthorization());
  }
}

export function getEmailsLeadTimeLine(leadId) {
  return instanceAxios
    .get(`${ENDPOINTS.EmailLeadTimeLine}?leadId=${leadId}`, getHeaderAuthorization())
    .then(interceptor);
}

export function retornarAssinatura(email) {
  return instanceAxios.get(`${ENDPOINTS.Assinatura}?email=${email}`, getHeaderAuthorization());
}

export function salvarLinkAnexo(chaveBlob, base64file) {
  return instanceAxios.post(ENDPOINTS.LinkAnexo, { chaveBlob, base64file }, getHeaderAuthorization());
}
export function removerLinkAnexo(chaveBlob) {
  return instanceAxios.delete(ENDPOINTS.LinkAnexo, getHeaderAuthorization({ chaveBlob }));
}

export function enviarEmail(threadEnviar) {
  return instanceAxios.post(ENDPOINTS.EnviarEmail, threadEnviar, getHeaderAuthorization());
}

/* METODOS API SPOTTER */

export const verificaAutenticacao = () =>
  axios
    .get("/api/pipeline/CentralEmails/VerificarAutenticacao")
    .then(interceptor)
    .catch({ autenticado: false });

export async function buscarContatosComLead(emails, agruparResultadosERetornarPrimeiro, idPublico) {
  const url = idPublico
    ? "/api/pipeline/CentralEmailsPublico/BuscarContatosLeadByListaEmails"
    : "/api/pipeline/CentralEmails/BuscarContatosLeadByListaEmails";

  const params = { emails, agruparResultadosERetornarPrimeiro };
  if (idPublico) params.idPublico = idPublico;
  const result = await axios.get(url, { params });
  return result.data;
}

export async function buscarLeadsPorEmail(emails) {
  const result = await axios.get("/api/pipeline/CentralEmails/BuscarLeadsPorContatos", {
    params: { emails },
  });
  return result.data;
}

export function BuscaContatosLead(busca, cancelToken) {
  return axios.get("/api/pipeline/CentralEmails/BuscarContatosLead", { params: { busca }, cancelToken });
}

export async function buscarDadosContato(leadId, email, possuiCP) {
  const Axios = axios.create();
  Axios.interceptors.response.use(responseInterceptor, errorResponseInterceptor);
  Axios.interceptors.request.use(requestInterceptor, requestInterceptorError);
  const result = await Axios.get("/api/pipeline/CentralEmails/BuscarDetalhesLeadPorContatoLead", {
    params: { leadId, emailContatoLead: email },
  });

  let dadosContato = result.data;

  if (possuiCP) {
    const campos = await buscarCamposPersonalizadosDoLead(leadId);

    let cpToObj;

    campos.forEach(item => {
      const itemToObj = { [`cp_${item.chave}`]: item.valor };
      cpToObj = { ...cpToObj, ...itemToObj };
    });

    dadosContato = {
      ...dadosContato,
      ...cpToObj,
    };
  }

  return dadosContato;
}

export async function buscarCamposPersonalizadosDoLead(leadId) {
  const result = await axios.get("/api/pipeline/Lead/CamposPersonalizadosValores", {
    params: { id: leadId },
  });
  return result.data;
}
