import { set } from "dot-prop-immutable";
import pipe from "pipe-now";
import { v4 } from "uuid";

import {
  LISTA_STATUS_CAMPANHA,
  LISTA_STATUS_LISTA_DINAMICA,
  LISTA_TIPO_DE_LISTA,
} from "../../../cadencia-emails/constantesCadenciaEmails";
import types from "./actionTypes";
import returnInitialState, { zerarFiltros, RESET_PAGE, getInitalDetalheLista } from "./initialState";

const CadenciaEmails = (state = returnInitialState(), action) => {
  switch (action.type) {
    case types.CE__ABRIR_MODAL_SELECIONA_TIPO_LISTA:
      return { ...state, modals: { ...state.modals, selecionaTipoLista: { open: true } } };
    case types.CE__FECHAR_MODAL_SELECIONA_TIPO_LISTA:
      return { ...state, modals: { ...state.modals, selecionaTipoLista: { open: false } } };
    case types.CE__BUSCAR_LISTAS_REQUEST: {
      let newState = set(state, "tabs.listas.isLoading", true);
      if (action.payload.zerarPaginacao) {
        newState = set(newState, "tabs.listas.items", []);
      }
      return newState;
    }
    case types.CE__BUSCAR_LISTAS_RESPONSE: {
      const items = action.payload.zerarPaginacao
        ? action.payload.listasResult
        : [...state.tabs.listas.items, ...action.payload.listasResult];
      let newState = set(state, "tabs.listas.items", items);
      newState = set(newState, "tabs.listas.isLoading", false);
      return newState;
    }

    case types.CE__BUSCAR_CAMPANHAS_REQUEST: {
      let newState = set(state, "tabs.campanhas.isLoading", true);
      if (action.payload.zerarPaginacao) {
        newState = set(newState, "tabs.campanhas.items", []);
      }
      return newState;
    }
    case types.CE__BUSCAR_CAMPANHAS_RESPONSE: {
      const items = action.payload.zerarPaginacao
        ? action.payload.campanhasResult
        : [...state.tabs.campanhas.items, ...action.payload.campanhasResult];

      let newState = set(state, "tabs.campanhas.items", items);
      newState = set(newState, "tabs.campanhas.isLoading", false);
      return newState;
    }
    case types.CE__BUSCAR_TEMPLATES_REQUEST: {
      let newState = set(state, "tabs.templates.isLoading", true);
      if (action.payload.zerarPaginacao) {
        newState = set(newState, "tabs.templates.items", []);
      }
      return newState;
    }
    case types.CE__BUSCAR_TEMPLATES_RESPONSE: {
      const items = action.payload.zerarPaginacao
        ? action.payload.templatesResult
        : [...state.tabs.templates.items, ...action.payload.templatesResult];
      let newState = set(state, "tabs.templates.items", items);
      newState = set(newState, "tabs.templates.isLoading", false);
      return newState;
    }
    case types.CE__BUSCAR_DETALHES_LISTA_REQUEST:
      return { ...state, detalhes: { ...state.detalhes, lista: { ...state.detalhes.lista, isLoading: true } } };
    case types.CE__BUSCAR_DETALHES_LISTA_RESPONSE: {
      const dados = action.payload;
      dados.contatos = dados?.contatos?.map(c => ({ ...c, uid: v4() }));
      return set(state, "detalhes.lista", lista => ({ ...lista, isLoading: false, dados }));
    }
    case types.CE__LIMPAR_DETALHES_LISTA:
      return set(state, "detalhes.lista", getInitalDetalheLista());
    case types.CE__ABRIR_MODAL_CONTATO: {
      const { contato } = action.payload;
      const contatoEdicao = contato && {
        ...contato,
        email: contato.email || "",
      };
      return { ...state, modals: { ...state.modals, contato: { open: true, contatoEdicao } } };
    }
    case types.CE__FECHAR_MODAL_CONTATO:
      return { ...state, modals: { ...state.modals, contato: { ...state.modals.contato, open: false } } };
    case types.CE__ABRIR_MODAL_IMPORTAR_CONTATOS:
      return {
        ...state,
        modals: { ...state.modals, importarContatos: { ...state.modals.importarContatos, open: true } },
      };
    case types.CE__FECHAR_MODAL_IMPORTAR_CONTATOS:
      return {
        ...state,
        modals: { ...state.modals, importarContatos: { ...state.modals.importarContatos, open: false, contatos: [] } },
      };
    /** DESCADASTROS */
    case types.CE__ABRIR_MODAL_DESCADASTROS:
      return { ...state, modals: { ...state.modals, descadastros: { open: true } } };
    case types.CE__FECHAR_MODAL_DESCADASTROS:
      return { ...state, modals: { ...state.modals, descadastros: { open: false } } };
    case types.CE__ADICIONAR_CONTATO_LISTA:
      return {
        ...state,
        detalhes: {
          ...state.detalhes,
          lista: {
            ...state.detalhes.lista,
            dados: {
              ...state.detalhes.lista.dados,
              contatos: [...state.detalhes.lista.dados.contatos, action.payload],
            },
          },
        },
      };
    case types.CE__EDITAR_CONTATO_LISTA: {
      const newContato = action.payload;
      const contatos = state.detalhes.lista.dados.contatos.map(contato => {
        if (contato.uid === newContato.uid) {
          return newContato;
        }
        return contato;
      });

      return {
        ...state,
        detalhes: {
          ...state.detalhes,
          lista: {
            ...state.detalhes.lista,
            dados: {
              ...state.detalhes.lista.dados,
              contatos,
            },
          },
        },
      };
    }
    case types.CE__EXCLUIR_CONTATO_LISTA: {
      const uid = action.payload;
      return {
        ...state,
        detalhes: {
          ...state.detalhes,
          lista: {
            ...state.detalhes.lista,
            dados: {
              ...state.detalhes.lista.dados,
              contatos: state.detalhes.lista.dados.contatos.filter(c => c.uid !== uid),
            },
          },
        },
      };
    }

    case types.CE__ON_CHANGE_NOME_LISTA:
      return {
        ...state,
        detalhes: {
          ...state.detalhes,
          lista: {
            ...state.detalhes.lista,
            dados: {
              ...state.detalhes.lista.dados,
              nome: action.payload,
            },
          },
        },
      };
    case types.CE__BUSCAR_DESCADASTROS_REQUEST: {
      let newState = set(state, "tabs.descadastros.isLoading", true);
      if (action.payload.zerarPaginacao) {
        newState = set(newState, "tabs.descadastros.items", []);
      }
      return newState;
    }
    case types.CE__BUSCAR_DESCADASTROS_RESPONSE: {
      const items = action.payload.zerarPaginacao
        ? action.payload.descadastrosResult
        : [...state.tabs.descadastros.items, ...action.payload.descadastrosResult];
      let newState = set(state, "tabs.descadastros.items", items);
      newState = set(newState, "tabs.descadastros.isLoading", false);
      return newState;
    }

    /* REDUCERS PARA EXIBIR MENSAGEM EXCLUIR DESCADASTRO */
    case types.CE__ON_CLICK_CHECK_DESCADASTRO: {
      const descadastros = state.tabs.descadastros.items.map(
        descadastro =>
          descadastro.descadastroId === action.payload ? { ...descadastro, checked: !descadastro.checked } : descadastro
      );
      return { ...state, tabs: { ...state.tabs, descadastros: { ...state.tabs.descadastros, items: descadastros } } };
    }
    case types.CE__ON_CLICK_CHECK_ALL_DESCADASTRO: {
      const todosChecados = state.tabs.descadastros.items.every(descadastro => descadastro.checked);
      const descadastros = state.tabs.descadastros.items.map(descadastro => ({
        ...descadastro,
        checked: !todosChecados,
      }));
      return { ...state, tabs: { ...state.tabs, descadastros: { ...state.tabs.descadastros, items: descadastros } } };
    }
    case types.CE__FECHAR_MODAL_EXCLUIR_DESCADASTROS_SELECIONADOS:
      return { ...state, modals: { ...state.modals, excluirDescadastro: { open: false } } };

    case types.CE__ABRIR_MODAL_EXCLUIR_DESCADASTROS_SELECIONADOS:
      return { ...state, modals: { ...state.modals, excluirDescadastro: { open: true } } };
    /* REDUCERS PARA EXIBIR MENSAGEM EXCLUIR DESCADASTRO */

    /* REDUCERS PARA EXIBIR MENSAGEM EXCLUIR ENVIOS */
    case types.CE__ON_CLICK_CHECK_ENVIO: {
      const { id, checked } = action.payload;

      const items = state.detalheCampanha.envios.items.map(
        item => (item.id === id ? { ...item, checked: !checked } : item)
      );

      let newState = set(state, "detalheCampanha.envios.items", items);
      newState = set(newState, "detalheCampanha.envios.todosContatosSelecionados", false);
      return newState;
    }
    case types.CE__ON_CLICK_CHECK_ALL_ENVIO: {
      const todosChecados = state.detalheCampanha.envios.todosContatosSelecionados;
      const items = state.detalheCampanha.envios.items.map(item => ({
        ...item,
        checked: !todosChecados,
      }));
      let newState = set(state, "detalheCampanha.envios.items", items);
      newState = set(newState, "detalheCampanha.envios.todosContatosSelecionados", !todosChecados);
      return newState;
    }
    case types.CE__ON_CLICK_UNCHECK_ALL_ENVIO: {
      const items = state.detalheCampanha.envios.items.map(item => ({
        ...item,
        checked: false,
      }));
      let newState = set(state, "detalheCampanha.envios.items", items);
      newState = set(newState, "detalheCampanha.envios.todosContatosSelecionados", false);
      return newState;
    }
    /* REDUCERS PARA EXIBIR MENSAGEM EXCLUIR ENVIOS */

    case types.CE__CHANGE_ORDENACAO:
      return {
        ...state,
        filtros: {
          ...state.filtros,
          ordenacao: action.payload.colunaOrdenacao,
          tipoOrdenacao: action.payload.tipoOrdenacao,
        },
      };
    case types.CE__BUSCAR_LISTAS_AUX_REQUEST:
      return {
        ...state,
        listasAuxiliares: { ...state.listasAuxiliares, isLoading: true },
      };
    case types.CE__BUSCAR_LISTAS_AUX_RESPONSE: {
      const { campanhas, preVendedores, etapasCampanha } = action.payload;
      return {
        ...state,
        listasAuxiliares: {
          ...state.listasAuxiliares,
          isLoading: false,
          campanhas,
          preVendedores,
          etapasCampanha,
        },
        filtros: {
          ...state.filtros,
          campanhas: campanhas ? campanhas.map(a => +a.id) : [],
          preVendedores: preVendedores ? preVendedores.map(a => a.id) : [],
          etapasCampanha: etapasCampanha ? etapasCampanha.map(a => a.id).join(",") : "",
          statusCampanha: LISTA_STATUS_CAMPANHA.map(a => a.id).join(","),
          filtroTipoLista: LISTA_TIPO_DE_LISTA.map(a => a.id).join(","),
          filtroStatusLista: LISTA_STATUS_LISTA_DINAMICA.map(a => a.id).join(","),
        },
      };
    }
    case types.CE__IMPORTAR_CONTATOS:
      return {
        ...state,
        modals: { ...state.modals, importarContatos: { ...state.modals.importarContatos, contatos: action.payload } },
      };
    case types.CE__ON_CLICK_CHECKBOX_IMPORTAR_CONTATOS: {
      const contatos = [
        ...state.modals.importarContatos.contatos.map(
          contato => (contato.email !== action.payload ? contato : { ...contato, checked: !contato.checked })
        ),
      ];

      return {
        ...state,
        modals: { ...state.modals, importarContatos: { ...state.modals.importarContatos, contatos } },
      };
    }
    case types.CE__ON_CLICK_CHECKBOX_TODOS_IMPORTAR_CONTATOS: {
      const allChecked = action.payload;
      const contatos = [
        ...state.modals.importarContatos.contatos.map(contato => ({
          ...contato,
          checked: !contato.isLead ? !allChecked : false,
        })),
      ];

      return {
        ...state,
        modals: { ...state.modals, importarContatos: { ...state.modals.importarContatos, contatos } },
      };
    }
    case types.CE__ADICIONAR_CONTATOS_SELECIONADOS: {
      const contatosAdicionar = action.payload.map(c => ({ ...c, uid: v4() }));
      return {
        ...state,
        detalhes: {
          ...state.detalhes,
          lista: {
            ...state.detalhes.lista,
            isLoading: false,
            dados: {
              ...state.detalhes.lista.dados,
              contatos: [...state.detalhes.lista.dados.contatos, ...contatosAdicionar],
            },
          },
        },
      };
    }
    case types.CE__FECHAR_MODAL_DISTRIBUIR_ENTRE_PREVENDEDORES:
      return { ...state, modals: { ...state.modals, distribuirEntrePreVendedores: { open: false } } };
    case types.CE__ABRIR_MODAL_DISTRIBUIR_ENTRE_PREVENDEDORES:
      return { ...state, modals: { ...state.modals, distribuirEntrePreVendedores: { open: true } } };
    case types.CE__INCREMENTAR_PAGINACAO: {
      const newState = set(state, "filtros.pagina", state.filtros.pagina + 1);
      return newState;
    }
    case types.CE__ZERAR_PAGINACAO: {
      let newState = set(state, "filtros.pagina", RESET_PAGE);
      newState = set(newState, "filtros.hasMore", true);

      return newState;
    }
    case types.CE__PARAR_PAGINACAO: {
      const newState = set(state, "filtros.hasMore", false);
      return newState;
    }
    case types.CE__LIMPAR_FILTROS:
      return {
        ...state,
        filtros: zerarFiltros(state),
      };
    case types.CE__LIMPAR_FILTROS_DETALHE_CAMPANHA:
      return {
        ...state,
        detalheCampanha: {
          ...state.detalheCampanha,
          filtroStatusResposta: null,
          filtroStatusEnvio: null,
        },
      };
    case types.CE__ON_CHANGE_FILTRO_BUSCA:
      return { ...state, filtros: { ...state.filtros, busca: action.payload } };

    case types.CE__ON_CHANGE_FILTRO_STATUS:
      return { ...state, filtros: { ...state.filtros, statusCampanha: action.payload } };
    case types.CE__ON_CHANGE_FILTRO_TIPO_LISTA:
      return { ...state, filtros: { ...state.filtros, filtroTipoLista: action.payload } };
    case types.CE__ON_CHANGE_FILTRO_STATUS_LISTA_DINAMICA:
      return { ...state, filtros: { ...state.filtros, filtroStatusLista: action.payload } };
    case types.CE__ON_CHANGE_FILTRO_PREVENDEDORES:
      return { ...state, filtros: { ...state.filtros, preVendedores: action.payload } };
    case types.CE__ON_CHANGE_FILTRO_CAMPANHAS:
      return { ...state, filtros: { ...state.filtros, campanhas: action.payload } };
    case types.CE__PRE_CARREGAMENTO_NOVA_CAMAPMHA_REQUEST: {
      let newState = set(state, "errosValidacaoNovaCampanha", null);
      newState = set(newState, "listasAuxiliares.isLoading", true);
      return newState;
    }
    case types.CE__PRE_CARREGAMENTO_NOVA_CAMAPMHA_RESPONSE: {
      let newState = {};
      if (action.payload.errosValidacao.length > 0) {
        newState = set(state, "errosValidacaoNovaCampanha", action.payload.errosValidacao);
      } else {
        newState = pipe(
          state,
          val => set(val, "listasAuxiliares.listas", action.payload.listas),
          val => set(val, "listasAuxiliares.preVendedores", action.payload.prevendedores),
          val => set(val, "listasAuxiliares.templates", action.payload.templates),
          val => set(val, "quantidadeEtapasPermitidas", action.payload.quantidadeEtapasPermitidas),
          val => set(val, "exibeOpcaoMinutos", action.payload.exibeOpcaoMinutos)
        );
      }
      newState = set(newState, "listasAuxiliares.isLoading", false);
      return newState;
    }
    case types.CE__ABRIR_MODAL_NOVO_TEMPLATE:
      return set(state, "modals.novoTemplate", novoTemplate => ({
        ...novoTemplate,
        podeEditar: action.payload.podeEditar,
        open: true,
      }));
    case types.CE__FECHAR_MODAL_NOVO_TEMPLATE:
      return set(state, "modals.novoTemplate", novoTemplate => ({
        ...novoTemplate,
        open: false,
        podeEditar: true,
        templateId: null,
        readOnly: false,
      }));
    case types.CE__SET_TEMPLATE_ID_MODAL_NOVO_TEMPLATE:
      return set(state, "modals.novoTemplate.templateId", action.payload.id);
    case types.CE__SET_READONLY_MODAL_NOVO_TEMPLATE:
      return set(state, "modals.novoTemplate.readOnly", action.payload.readOnly);
    case types.CE__ABRIR_MODAL_BUSCAR_CONTATOS_BASE_LEADS:
      return {
        ...state,
        modals: { ...state.modals, buscarContatosBaseLeads: { ...state.modals.buscarContatosBaseLeads, open: true } },
      };
    case types.CE__FECHAR_MODAL_BUSCAR_CONTATOS_BASE_LEADS:
      return {
        ...state,
        modals: {
          ...state.modals,
          buscarContatosBaseLeads: { ...state.modals.buscarContatosBaseLeads, open: false, contatos: [] },
        },
      };
    case types.CE__BUSCAR_CONTATOS_BASE_LEADS_RESPONSE:
      return {
        ...state,
        modals: {
          ...state.modals,
          buscarContatosBaseLeads: { ...state.modals.buscarContatosBaseLeads, contatos: action.payload },
        },
      };
    case types.CE__ON_CLICK_CHECKBOX_TODOS_BUSCAR_CONTATOS_BASE_LEADS: {
      const contatos = [
        ...state.modals.buscarContatosBaseLeads.contatos.map(contato => ({ ...contato, checked: !action.payload })),
      ];

      return {
        ...state,
        modals: { ...state.modals, buscarContatosBaseLeads: { ...state.modals.buscarContatosBaseLeads, contatos } },
      };
    }
    case types.CE__ON_CLICK_CHECKBOX_BUSCAR_CONTATOS_BASE_LEADS: {
      const contatos = [
        ...state.modals.buscarContatosBaseLeads.contatos.map(
          contato => (contato.contatosLeadId !== action.payload ? contato : { ...contato, checked: !contato.checked })
        ),
      ];
      return {
        ...state,
        modals: { ...state.modals, buscarContatosBaseLeads: { ...state.modals.buscarContatosBaseLeads, contatos } },
      };
    }

    case types.CE__BUSCAR_RESPOSTAS_REQUEST: {
      let newState = set(state, "detalheCampanha.respostas.isLoading", true);
      if (action.payload.zerarPaginacao) {
        newState = set(newState, "detalheCampanha.respostas.items", []);
      }
      return newState;
    }
    case types.CE__BUSCAR_RESPOSTAS_RESPONSE: {
      const items = action.payload.zerarPaginacao
        ? action.payload.respostasResult
        : [...state.detalheCampanha.respostas.items, ...action.payload.respostasResult];
      let newState = set(state, "detalheCampanha.respostas.items", items);
      newState = set(newState, "detalheCampanha.respostas.isLoading", false);
      return newState;
    }

    case types.CE__ON_CHANGE_FILTRO_STATUS_RESPOSTA:
      return { ...state, filtros: { ...state.filtros, statusResposta: action.payload } };
    case types.CE__PREENCHE_FILTROS_INICIAIS_DETALHE_CAMPANHA:
      return {
        ...state,
        filtros: {
          ...state.filtros,
          campanhaId: action.payload,
        },
      };
    case types.CE__BUSCAR_ENVIOS_REQUEST: {
      let newState = set(state, "detalheCampanha.envios.isLoading", true);
      if (action.payload.zerarPaginacao) {
        newState = set(newState, "detalheCampanha.envios.items", []);
        newState = set(newState, "detalheCampanha.envios.quantidadeTotal", null);
      }
      return newState;
    }
    case types.CE__BUSCAR_ENVIOS_RESPONSE: {
      const envios = action.payload.enviosResult.envios || [];
      let items = action.payload.zerarPaginacao ? envios : [...state.detalheCampanha.envios.items, ...envios];
      if (action.payload.todosChecados) items = items.map(item => ({ ...item, checked: true }));
      let newState = set(state, "detalheCampanha.envios.items", items);
      newState = set(newState, "detalheCampanha.envios.quantidadeTotal", action.payload.enviosResult.quantidadeTotal);
      newState = set(newState, "detalheCampanha.envios.isLoading", false);
      return newState;
    }
    case types.CE__BUSCAR_CAMPANHA_CONTATOS_REQUEST:
      return {
        ...state,
        detalheCampanha: {
          ...state.detalheCampanha,
          campanhaContatos: {
            isLoading: true,
            data: {},
          },
        },
      };
    case types.CE__BUSCAR_CAMPANHA_CONTATOS_RESPONSE: {
      return {
        ...state,
        detalheCampanha: {
          ...state.detalheCampanha,
          campanhaContatos: {
            isLoading: false,
            data: action.payload,
          },
        },
      };
    }
    case types.CE__DEFINIR_TODOS_CONTATOS_COMO_SELECIONADOS: {
      return set(state, "detalheCampanha.envios.todosContatosSelecionados", true);
    }

    case types.CE__DEFINIR_TODOS_CONTATOS_COMO_NAO_SELECIONADOS: {
      return set(state, "detalheCampanha.envios.todosContatosSelecionados", false);
    }

    case types.CE__ON_CHANGE_FILTRO_STATUS_ENVIO:
      return { ...state, filtros: { ...state.filtros, statusEnvio: action.payload } };
    case types.CE__ON_CHANGE_FILTRO_ETAPAS_ENVIO:
      return { ...state, filtros: { ...state.filtros, etapasCampanha: action.payload } };
    case types.CE__ON_CHANGE_MODO_EDICAO_LISTA:
      return set(state, "detalhes.lista.modoEdicao", action.payload);
    case types.CE__GRAVA_FILTRO_STATUS: {
      const { filterName, value } = action.payload;
      return {
        ...state,
        detalheCampanha: {
          ...state.detalheCampanha,
          [filterName]: value,
        },
      };
    }
    case types.CE__BUSCAR_LISTAS_AUX_DETALHE_CAMPANHA_REQUEST:
      return {
        ...state,
        listasAuxiliares: { ...state.listasAuxiliares, isLoading: true },
      };
    case types.CE__BUSCAR_LISTAS_AUX_DETALHE_CAMPANHA_RESPONSE:
      return {
        ...state,
        listasAuxiliares: {
          ...state.listasAuxiliares,
          isLoading: false,
          preVendedores: action.payload.preVendedores,
          etapasCampanha: action.payload.etapasCampanha,
        },
      };
    case types.CE__CONFIGURACOES_SETAR_NOME_DOMINIO: {
      return set(state, "configuracoes.dominio.nome", action.payload);
    }

    /** FUNÇÕES DA MODAL DE CAMPANHAS */
    case types.CE__ABRIR_MODAL_CAMPANHAS: {
      let newState = set(state, "modals.campanhas.lista", action.payload);
      newState = set(newState, "modals.campanhas.open", true);
      return newState;
    }
    case types.CE__FECHAR_MODAL_CAMPANHAS: {
      let newState = set(state, "modals.campanhas.lista", []);
      newState = set(newState, "modals.campanhas.open", false);
      return newState;
    }
    /** FUNÇÕES DA MODAL DE CAMPANHAS */

    case types.CE__CONVERTER_CONTEUDO_PARA_HTML: {
      return set(state, "modals.novoTemplate.textoAlternadoParaHtml", true);
    }
    case types.CE__CONVERTER_CONTEUDO_PARA_TEXTO: {
      return set(state, "modals.novoTemplate.textoAlternadoParaHtml", false);
    }

    case types.CE__ESCREVER_ERRO_DE_VARIAVEIS: {
      return set(state, "modals.novoTemplate.erroVariaveis", action.payload);
    }

    case types.CE__SET_CURRENT_EMAIL_VISUALIZACAO: {
      const email = action.payload;
      return set(state, "modals.currentEmailVisualizacao", email);
    }

    case types.CE__SET_INTERESSE_RESPOSTA_ID: {
      const respostaId = action.payload;
      return set(state, "interesseAtividade.respostaId", respostaId);
    }
    case types.CE__SET_FILTRO_TEMPORARIO: {
      const { filterName, value } = action.payload;
      return {
        ...state,
        filtrosTemporarios: {
          ...state.filtrosTemporarios,
          [filterName]: value,
        },
      };
    }
    case types.CE__INJETAR_FILTROS_TEMPORARIOS: {
      const { statusEnvio } = state.filtrosTemporarios;
      const { statusResposta } = state.filtrosTemporarios;

      return {
        ...state,
        filtros: {
          ...state.filtros,
          statusEnvio: statusEnvio ? [...statusEnvio] : "all",
          statusResposta: statusResposta ? [...statusResposta] : "all",
        },
      };
    }
    case types.CE__LIMPAR_FILTROS_TEMPORARIOS: {
      return {
        ...state,
        filtrosTemporarios: returnInitialState().filtrosTemporarios,
      };
    }
    default:
      return state;
  }
};

export default CadenciaEmails;
