import React from "react";

import { connect } from "react-redux";
import PropTypes from "prop-types";

import ModalEnviarPreencherVariaveis from "./ModalEnviarPreencherVariaveis.jsx";

import { fecharModalAlertaVariaveis } from "../../../store/reducers/emails/emailsActions";

class ModalEnviarPreencherVariaveisContainer extends React.Component {
  static propTypes = {
    listaVariaveis: PropTypes.array,
    exibirModalAlertaVariaveis: PropTypes.bool,
    fecharModalAlertaVariaveis: PropTypes.func,
    leadId: PropTypes.string,
  };

  handleOpenDetalhesLead = () => {
    window.open(`${window.location.origin}/spotter/detalhes-lead/${this.props.leadId}`, "_blank");
    this.props.fecharModalAlertaVariaveis();
  };

  render = () =>
    this.props.exibirModalAlertaVariaveis ? (
      <ModalEnviarPreencherVariaveis {...this.props} handleOpenDetalhesLead={this.handleOpenDetalhesLead} />
    ) : (
      ""
    );
}

const mapStateToProps = state => ({
  listaVariaveis: state.emails.responderEmail.listaVariaveis,
  leadId: state.emails.responderEmail.leadId,
  exibirModalAlertaVariaveis: state.emails.responderEmail.exibirModalAlertaVariaveis,
});

const mapDispatchToProps = dispatch => ({
  fecharModalAlertaVariaveis: () => dispatch(fecharModalAlertaVariaveis()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalEnviarPreencherVariaveisContainer);
