import React from "react";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { injectIntl } from "react-intl";
import createSnackBar from "../../../_common/utils/snackbar/createSnackbar";

import ModalEnviarSelecionarContato from "./ModalEnviarSelecionarContato.jsx";

import { fecharModalEscolherContato } from "../../../store/reducers/emails/emailsActions";

class ModalEnviarSelecionarContatoContainer extends React.Component {
  static propTypes = {
    listaContatos: PropTypes.array,
    exibirModalEscolherContato: PropTypes.bool,
    fecharModalEscolherContato: PropTypes.func,
    preencherContato: PropTypes.func.isRequired,
    isEncaminhando: PropTypes.bool,
    isRespondendo: PropTypes.bool,
    intl: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => {
    const initialState = {
      leadId: undefined,
      email: "",
    };
    return initialState;
  };

  changeContato = (event, value) => {
    this.setState({ leadId: Number(event.target.attributes.lead.value), email: value });
  };

  handlePreencherEEnviar = () => {
    if (!this.state.leadId) {
      createSnackBar(this.props.intl.formatMessage({ defaultMessage: "Selecione um ou mais contato ." }));
      return;
    }

    this.fecharModal();
    this.props.preencherContato(
      this.state.leadId,
      this.state.email,
      this.props.isEncaminhando,
      this.props.isRespondendo
    );
  };

  fecharModal = () => {
    this.setState(this.getInitialState());
    this.props.fecharModalEscolherContato();
  };

  render = () =>
    this.props.exibirModalEscolherContato ? (
      <ModalEnviarSelecionarContato
        {...this.props}
        changeContato={this.changeContato}
        handlePreencherEEnviar={this.handlePreencherEEnviar}
        fecharModal={this.fecharModal}
      />
    ) : (
      ""
    );
}

const mapStateToProps = state => ({
  listaContatos: state.emails.responderEmail.listaContatos,
  exibirModalEscolherContato: state.emails.responderEmail.exibirModalEscolherContato,
});

const mapDispatchToProps = dispatch => ({
  fecharModalEscolherContato: () => dispatch(fecharModalEscolherContato()),
});

const enhance = compose(
  injectIntl,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
);

export default enhance(ModalEnviarSelecionarContatoContainer);
