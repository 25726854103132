import React from "react";
import PropTypes from "prop-types";
import WorkIcon from "@material-ui/icons/Work";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { withStyles } from "@material-ui/core";

import { Tipografia, Flex } from "../../../_common";

const TooltipNomeAvatar = ({ classes, leads }) => {
  // Considera apenas o lead mais recente, que é o primeiro da lista.
  const lead = leads[0];

  return (
    <Flex className={classes.tooltipDadosContato}>
      <Flex className={classes.linhaIconWork}>
        <WorkIcon className={classes.iconWork} />
      </Flex>
      <Flex className={classes.dados}>
        <Flex alignItems="center" style={{ maxWidth: "95%", flexDirection: "row" }}>
          <Tipografia className={classes.dado} tipo="subtituloConteudo">
            {lead.nomeLead}{" "}
          </Tipografia>
          <OpenInNewIcon
            className={classes.iconOpenInNew}
            onClick={() => window.open(`/spotter/detalhes-lead/${lead.idLead}`, "_blank")}
          />
        </Flex>

        <Tipografia className={classes.dado} cor="darkSecondaryText">
          {lead.email}
        </Tipografia>
        <Tipografia className={classes.dado} cor="darkSecondaryText">
          {lead.cargo}
        </Tipografia>
      </Flex>
    </Flex>
  );
};

TooltipNomeAvatar.propTypes = {
  classes: PropTypes.object,
  leads: PropTypes.array.isRequired,
};

export default withStyles({
  tooltipDadosContato: {
    minWidth: 344,
    padding: 8,
  },
  iconWork: {
    color: "rgba(0, 0, 0, 0.51)",
    display: "inline",
    position: "relative",
    fontSize: "37px",
  },
  iconOpenInNew: {
    fontSize: "17px",
  },
  linhaIconWork: {
    flex: "none",
    marginRight: 16,
    alignItems: "center",
  },
  dados: {
    flexDirection: "column",
    alignItems: "flex-start",
  },
  dado: {
    display: "block",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: 312,
    "&:first-child": {
      marginTop: 0,
    },
    "&:last-child": {
      marginBottom: 0,
    },
  },
})(TooltipNomeAvatar);
