import React, { useState } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { Button, Checkbox, FormControlLabel } from "@material-ui/core";

import { Flex, Tipografia } from "../../../_common";
import injectSheet from "../../../_common/hocs/injectSheet";
import AsyncSelect from "../../../components/AsyncSelect";

const styles = {
  modalContainer: {
    padding: "0 !important",
    overflow: "hidden",
    borderTopRightRadius: "4px !important",
    borderTopLeftRadius: "4px !important",
  },
  modalContent: {
    overflowY: "auto",
    paddingTop: 10,
    paddingLeft: 10,
    gap: "15px",
  },
  colunaTemplate: {
    display: "flex",
    minWidth: 28,
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: 10,
  },
};

const ModalEscolherTemplate = ({ templates, classes, handleSelecionarTemplate, exibeSubstituirVariaveis }) => {
  const intl = useIntl();
  const [substituirVariaveis, setSubstituirVariaveis] = useState(false);
  const [templateId, setTemplateId] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const templatesComOpcaoEmBranco = [
    { id: " ", nome: intl.formatMessage({ defaultMessage: "Template em branco" }) },
    ...templates,
  ];

  const handleCheckboxChange = event => {
    const isChecked = event.target.checked;
    setSubstituirVariaveis(isChecked);
  };

  const handleTemplateChange = id => {
    setTemplateId(id);
    if (isSubmitted) setIsSubmitted(false);
  };

  const handleTemplateSelect = () => {
    if (!templateId) {
      setIsSubmitted(true);
    } else {
      handleSelecionarTemplate(templateId, substituirVariaveis);
    }
  };

  return (
    <div className={classes.modalContainer}>
      <Flex className={classes.modalContent} flexDirection="column">
        <Tipografia tipo="subtituloConteudo" cor="darkPrimaryText">
          {intl.formatMessage({ defaultMessage: "Template de e-mail" })}
        </Tipografia>
        <AsyncSelect
          className={classes.selectEtapa}
          options={templatesComOpcaoEmBranco}
          label={intl.formatMessage({
            defaultMessage: "Template de e-mail",
          })}
          value={templateId}
          onChange={e => handleTemplateChange(e.target.value)}
          getLabel={item => item.nome}
          id="templateId"
          error={isSubmitted && !templateId}
          helperText={isSubmitted && !templateId ? intl.formatMessage({ defaultMessage: "Campo obrigatório*" }) : ""}
        />
        {exibeSubstituirVariaveis && (
          <Flex flexDirection="column">
            <Tipografia tipo="subtituloConteudo" cor="darkPrimaryText">
              {intl.formatMessage({ defaultMessage: "Configurações de variáveis" })}
            </Tipografia>
            <FormControlLabel
              control={<Checkbox checked={substituirVariaveis} onChange={handleCheckboxChange} color="primary" />}
              label={intl.formatMessage({ defaultMessage: "Substituir variáveis do template" })}
            />
          </Flex>
        )}
        <div className={classes.buttonContainer}>
          <Button color="primary" onClick={handleTemplateSelect}>
            {intl.formatMessage({ defaultMessage: "Escrever e-mail" })}
          </Button>
        </div>
      </Flex>
    </div>
  );
};

ModalEscolherTemplate.propTypes = {
  templates: PropTypes.array,
  classes: PropTypes.object,
  handleSelecionarTemplate: PropTypes.func.isRequired,
  exibeSubstituirVariaveis: PropTypes.bool,
};

export default injectSheet(styles)(ModalEscolherTemplate);
