import React from "react";
import { Divider } from "@material-ui/core";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import { Tipografia, Flex } from "../../../_common";
import injectSheet from "../../../_common/hocs/injectSheet";
import IconeTracking from "../IconeTracking/IconeTracking";
import IconeTrackingTooltipVisualizacoes from "../IconeTrackingTooltipVisualizacoes/IconeTrackingTooltipVisualizacoes";

const styles = {
  container: {
    maxHeight: 400,
    overflowY: "auto",
    overflowX: "hidden",
    display: "flex",
    flexDirection: "column",
  },
  title: {
    margin: "8px 16px",
  },
  divider: {
    margin: "0 -13px !important",
    marginBottom: "4px !important",
  },
};

const IconeTrackingTooltip = ({ classes, visualizacoes, onMouseLeave }) => {
  const intl = useIntl();

  return (
    <div onMouseLeave={onMouseLeave} className={classes.container}>
      <Flex flexDirection="column">
        <Flex justifyContent="space-between" className={classes.title}>
          <Tipografia tipo="navbar">{intl.formatMessage({ defaultMessage: "Tracking" })}</Tipografia>
          <IconeTracking visualizacoes={visualizacoes} escondeTooltip />
        </Flex>
        <Divider className={classes.divider} />
        <IconeTrackingTooltipVisualizacoes visualizacoes={visualizacoes} />
      </Flex>
    </div>
  );
};

IconeTrackingTooltip.propTypes = {
  classes: PropTypes.object,
  visualizacoes: PropTypes.array,
  onMouseLeave: PropTypes.func,
};

export default injectSheet(styles)(IconeTrackingTooltip);
