import React from "react";
import PropTypes from "prop-types";
import injectSheet, { withTheme } from "react-jss";
import { compose } from "recompose";

import Close from "@material-ui/icons/Close";
import Drafts from "@material-ui/icons/Drafts";
import ReplyAll from "@material-ui/icons/ReplyAll";
import Forward from "@material-ui/icons/Forward";
import Reply from "@material-ui/icons/Reply";

import { IconButton } from "@material-ui/core";
import cn from "classnames";

import { Tipografia, Flex } from "../../../_common";

const styles = {
  wrapper: {
    height: 48,
    minHeight: 48,
    backgroundColor: "#394554",
    paddingLeft: 16,
    borderRadius: "4px 4px 0 0",
  },
  botaoFechar: {
    marginLeft: "auto !important",
  },
  iconeModalHeader: {
    paddingRight: 5,
  },
  truncate: {
    display: "inline-block",
    maxWidth: "950px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    color: "white",
  },
};

const ModalLerEmailHeader = ({
  mostrarEditor,
  isEncaminhando,
  isResponderTodos,
  classes,
  className,
  theme,
  tituloCabecalho,
  handleFecharModal,
}) => (
  <Flex className={cn(classes.wrapper, className)} alignItems="center">
    {!mostrarEditor && <Drafts className={classes.iconeModalHeader} color={theme.palette.icons.lightActiveIcon} />}
    {isEncaminhando && <Forward className={classes.iconeModalHeader} color={theme.palette.icons.lightActiveIcon} />}
    {isResponderTodos && <ReplyAll className={classes.iconeModalHeader} color={theme.palette.icons.lightActiveIcon} />}
    {mostrarEditor &&
      !isEncaminhando &&
      !isResponderTodos && <Reply className={classes.iconeModalHeader} color={theme.palette.icons.lightActiveIcon} />}
    <div className={classes.truncate}>
      <Tipografia cor="lightPrimaryText">{tituloCabecalho}</Tipografia>
    </div>
    <div className={classes.botaoFechar}>
      <IconButton
        id="btnFecharModalLerEmail"
        onClick={handleFecharModal}
        touchRippleColor={theme.palette.icons.lightActiveIcon}
      >
        <Close color={theme.palette.icons.lightActiveIcon} />
      </IconButton>
    </div>
  </Flex>
);

ModalLerEmailHeader.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  mostrarEditor: PropTypes.bool,
  isEncaminhando: PropTypes.bool,
  isResponderTodos: PropTypes.bool,
  theme: PropTypes.object.isRequired,
  tituloCabecalho: PropTypes.string.isRequired,
  handleFecharModal: PropTypes.func.isRequired,
};

const enhance = compose(
  injectSheet(styles),
  withTheme
);

export default enhance(ModalLerEmailHeader);
