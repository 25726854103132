import axios from "axios";
import { defineMessage } from "react-intl";

import { createAction } from "../../../../../_common/utils/redux";
import {
  setFields,
  setResponses,
  mergeOptionsIntoField,
  mergeFieldIntoResponse,
  setQueryConfig,
  updateQuery,
} from "../../../../../_common/components/QueryBuilder/reducer/actions";
import createSnackBar from "../../../../../_common/utils/snackbar/createSnackbar";
import {
  descriptionTransformer,
  ApiPostQueryTransformer,
  ApiGetQueryTransformer,
  configTransformer,
  responseTransformer,
} from "./transformers";
import { map, prop } from "../../../../../_common/utils/fp";
import { DEFAULT_RULE } from "../../../../../_common/components/QueryBuilder/reducer/initialState";
import { validateLista } from "./validators";
import { prependCopiaDe } from "../../../../../_common/utils/string";
import { NOME_LISTA_MAX_LENGTH } from "../../../constantesListas";
import { appIntl } from "../../../../../_common/containers/ConnectedIntlProvider";

export const types = {
  CHANGE_NOME_LISTA: "LISTA_DINAMICA/CHANGE_NOME_LISTA",
  RESET: "LISTA_DINAMICA/RESET",
  CHANGE_SCREEN_MODE: "LISTA_DINAMICA/CHANGE_SCREEN_MODE",
  TOGGLE_CONFIG_VALUE: "LISTA_DINAMICA/TOGGLE_CONFIG_VALUE",
  SET_SCREEN_MODE: "LISTA_DINAMICA/SET_SCREEN_MODE",
  LOAD_START: "LISTA_DINAMICA/LOAD_START",
  LOAD_STOP: "LISTA_DINAMICA/LOAD_STOP",
  RECEIVE_LISTA: "LISTA_DINAMICA/RECEIVE_LISTA",
  OPEN_EDIT_ALERT: "LISTA_DINAMICA/OPEN_EDIT_ALERT",
  CLOSE_EDIT_ALERT: "LISTA_DINAMICA/CLOSE_EDIT_ALERT",
  SET_TOUCHED_NOME_LISTA: "LISTA_DINAMICA/SET_TOUCHED_NOME_LISTA",
};

const apiPostQueryTransformer = ApiPostQueryTransformer();
const apiGetQueryTransformer = ApiGetQueryTransformer();

export const changeNomeLista = value => createAction(types.CHANGE_NOME_LISTA, value);
export const reset = value => createAction(types.RESET, value);
export const toggleConfigValue = configKey => createAction(types.TOGGLE_CONFIG_VALUE, configKey);
export const setScreenMode = screenMode => createAction(types.SET_SCREEN_MODE, screenMode);
export const openEditAlert = () => createAction(types.OPEN_EDIT_ALERT);
export const closeEditAlert = () => createAction(types.CLOSE_EDIT_ALERT);
export const setTouchedNomeLista = value => createAction(types.SET_TOUCHED_NOME_LISTA, value);

const loadStart = () => createAction(types.LOAD_START);
export const loadStop = () => createAction(types.LOAD_STOP);
const receiveLista = lista => createAction(types.RECEIVE_LISTA, lista);

export const getLista = listaId => async dispatch => {
  const intl = appIntl();
  dispatch(loadStart());
  try {
    const { data: listaDinamica } = await axios.get(`/Api/Pipeline/Lista/ConsultarSegmentacao?listaId=${+listaId}`);
    if (!listaDinamica) {
      throw new Error(intl.formatMessage({ defaultMessage: "Lista não encontrada." }));
    }
    const query = { ...apiGetQueryTransformer(listaDinamica.query) };

    dispatch(
      receiveLista({
        ...listaDinamica,
        query,
      })
    );

    dispatch(updateQuery(query));
  } catch (e) {
    throw e;
  } finally {
    dispatch(loadStop());
  }
};

export const getListaDuplicar = listaId => async dispatch => {
  const intl = appIntl();
  dispatch(loadStart());
  try {
    const { data: listaDinamica } = await axios.get(
      `/Api/Pipeline/Lista/DuplicarListaDinamica?listaIdDuplicar=${+listaId}`
    );
    if (!listaDinamica) {
      throw new Error(intl.formatMessage({ defaultMessage: "Lista não encontrada." }));
    }
    const query = { ...apiGetQueryTransformer(listaDinamica.query) };

    dispatch(
      receiveLista({
        ...listaDinamica,
        nome: prependCopiaDe(listaDinamica.nome, NOME_LISTA_MAX_LENGTH),
        query,
      })
    );

    dispatch(updateQuery(query));
  } catch (e) {
    throw e;
  } finally {
    dispatch(loadStop());
  }
};

const getListaDinamica = state => {
  const { lista } = state.cadenciaEmails.listaDinamica;
  const { query } = state.queryBuilder;
  return {
    ...lista,
    query,
  };
};

const formatListaDinamica = listaDinamica => ({
  ...listaDinamica,
  query: apiPostQueryTransformer(listaDinamica.query),
});

export const postLista = () => async (dispatch, getState) => {
  const state = getState();
  const listaDinamica = getListaDinamica(state);
  try {
    dispatch(setTouchedNomeLista(true));
    validateLista(listaDinamica);
    const listaToPost = formatListaDinamica(listaDinamica);
    await axios.post("/Api/Pipeline/Lista/SalvarSegmentacao", listaToPost);
  } catch (e) {
    throw e;
  }

  return true;
};

export const getQueryFields = () => async dispatch => {
  try {
    const { data } = await axios.get("/Api/Pipeline/Lista/CarregarCamposSegmentacao");
    const fields = map(descriptionTransformer, data);
    dispatch(setFields(fields));
  } catch (e) {
    createSnackBar(defineMessage({ defaultMessage: "Ocorreu um erro." }));
    // console.error(e);
  }
};

export const getQueryResponses = () => async (dispatch, getState) => {
  const state = getState();
  const criterio = state.queryBuilder.targetFieldValue;
  const regras = state.queryBuilder.query.groups.map(c => c.rules).flat();
  const optionsValue = regras.map(r => r.value);
  try {
    if (optionsValue.find(f => f != null)) {
      const { data } = await axios.post("/api/pipeline/regra/carregarValorChaveCriterio", {
        criterio,
        chaveIds: [Number(optionsValue)],
      });
      const responses = map(responseTransformer, data);
      dispatch(setResponses(responses));
    }
  } catch (e) {
    createSnackBar("Ocorreu um erro ao carregar segmentações.");
  }
};

const shouldLoadValues = (fieldValue, state) => {
  if (fieldValue === DEFAULT_RULE().field) {
    return false;
  }

  const { queryBuilder } = state;
  const targetField = queryBuilder.fieldsOptions.find(({ id }) => id === fieldValue);
  const alreadyLoadedOptions = targetField && targetField.options;

  if (alreadyLoadedOptions) {
    return false;
  }

  return true;
};

export const getQueryFieldValues = fieldValue => async (dispatch, getState) => {
  const state = getState();
  if (!shouldLoadValues(fieldValue, state)) {
    return;
  }

  try {
    const options = [];
    dispatch(mergeOptionsIntoField(fieldValue, options));
    // eslint-disable-next-line no-empty
    if (!options.length) {
    }
    // eslint-disable-next-line no-empty
  } catch (e) {
    createSnackBar("Ocorreu um erro ao carregar segmentações.");
  }
};

export const getQueryOptionValues = optionsValue => async (dispatch, getState) => {
  const state = getState();
  if (!shouldLoadValues(optionsValue, state)) {
    return;
  }

  try {
    const responses = [];
    dispatch(mergeFieldIntoResponse(responses));
    // eslint-disable-next-line no-empty
    if (!responses.length) {
    }
  } catch (e) {
    createSnackBar("Ocorreu um erro ao carregar segmentações.");
  }
};

export const loadInitialFields = () => async (dispatch, getState) => {
  const { groups } = getState().queryBuilder.query;

  const groupedRules = map(prop("rules"), groups);
  const flattenedRules = groupedRules.flat(1);
  const fieldsValues = map(prop("field"), flattenedRules);
  const uniqueFieldsValues = [...new Set(fieldsValues)]; // para evitar requisições repetidas

  const requests = uniqueFieldsValues.map(async id => dispatch(getQueryFieldValues(id)));

  await Promise.all(requests);
};

export const loadConfig = () => async dispatch => {
  try {
    const { data } = await axios.get("/Api/Pipeline/Lista/ConsultarLimiteSegmentacoes");
    const config = configTransformer(data);
    dispatch(setQueryConfig(config));
  } catch (e) {
    throw e;
  }
};
