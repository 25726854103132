import React from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "recompose";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router";
import CreateSnackBar from "../../../_common/utils/snackbar/createSnackbar";
import {
  fecharEditorResponder as fecharEditorResponderAction,
  fecharModalLerEmail as fecharModalLerEmailAction,
  abrirEditorResponder as abrirEditorResponderAction,
  atualizarThreads,
  atualizarTotalThreads,
  resetarThreads,
  adicionarEmailTo as adicionarEmailToAction,
  removerEmailTo as removerEmailToAction,
  excluirEmail,
} from "../../../store/reducers/emails/emailsActions";
import ModalLerEmailResponder from "./ModalLerEmailResponder.jsx";
import { LIXEIRA } from "../constantes";

import { responderEmail as responderEmailService } from "../../../store/reducers/emails/services";
import { AjaxBlackout } from "../../../_common/index";

class ModalLerEmailResponderContainer extends React.Component {
  static propTypes = {
    responderEmail: PropTypes.object,
    abrirEditorResponder: PropTypes.func,
    fecharEditorResponder: PropTypes.func,
    handleExibirTemplate: PropTypes.func.isRequired,
    provider: PropTypes.object,
    fecharModalLerEmail: PropTypes.func,
    templateId: PropTypes.number,
    editorExpandido: PropTypes.bool,
    toggleEditorExpandido: PropTypes.func,
    exibirBotaoExpandirEditor: PropTypes.bool,
    exibirBotaoExpandirCopias: PropTypes.bool,
    copiasExpandidas: PropTypes.bool,
    toggleCopiasExpandidas: PropTypes.func,
    usuarioLogado: PropTypes.object,
    isPVOrVendedor: PropTypes.bool,
    podeEnviarEmail: PropTypes.bool,
    history: PropTypes.object,
    conteudoHtml: PropTypes.bool,
    conteudoTemplate: PropTypes.string,
    resetState: PropTypes.func,
    atualizarThreads: PropTypes.func,
    atualizarTotalThreads: PropTypes.func,
    aba: PropTypes.string,
    podeResponderEmail: PropTypes.bool,
    intl: PropTypes.object,
  };

  handleResponder = () => {
    let emailPara;
    if (this.verificarValidacaoEnvioEmail()) {
      return;
    }
    const { threadNormalizada, usuarioLogado } = this.props.responderEmail;

    if (usuarioLogado.email === threadNormalizada.from.email) {
      emailPara = threadNormalizada.to;
    } else {
      emailPara = [threadNormalizada.from];
    }

    this.props.resetState();
    this.props.abrirEditorResponder(
      threadNormalizada.id,
      { emailsTo: emailPara },
      false,
      [threadNormalizada.messageId],
      false
    );
  };

  handleResponderTodos = () => {
    if (this.verificarValidacaoEnvioEmail()) {
      return;
    }

    const { threadNormalizada, usuarioLogado } = this.props.responderEmail;

    const emailsToFiltrado = threadNormalizada.mailRecipents.filter(
      x => x.email.toLowerCase() !== usuarioLogado.email.toLowerCase()
    );
    this.props.resetState();
    this.props.abrirEditorResponder(
      threadNormalizada.id,
      {
        emailsTo: emailsToFiltrado.length > 0 ? emailsToFiltrado : threadNormalizada.mailRecipents,
      },
      false,
      [threadNormalizada.messageId],
      true
    );
  };

  handleEncaminhar = () => {
    if (this.verificarValidacaoEnvioEmail()) {
      return;
    }

    const { threadNormalizada } = this.props.responderEmail;
    this.props.resetState();
    this.props.abrirEditorResponder(threadNormalizada.id, { emailsTo: [] }, true, [threadNormalizada.messageId], false);
  };

  handleExcluir = () => {
    AjaxBlackout.Show();
    const { threadNormalizada } = this.props.responderEmail;
    AjaxBlackout.Show();
    excluirEmail(threadNormalizada.id, threadNormalizada.folder).then(() => {
      this.props.atualizarThreads();
      this.props.atualizarTotalThreads();
      CreateSnackBar(this.props.intl.formatMessage({ defaultMessage: "E-mail removido com sucesso." }));
      this.props.fecharModalLerEmail();
      AjaxBlackout.Hide();
    });
  };

  verificarValidacaoEnvioEmail = () => {
    if (this.props.isPVOrVendedor && !this.props.podeEnviarEmail) {
      this.props.history.push("/spotter/central-emails");

      return true;
    }

    return false;
  };

  handleResponderEmailCadencia = responderTodos => {
    const { campanha, origemAcaoResposta } = this.props.responderEmail.threadNormalizada;
    responderEmailService(campanha, {
      responderTodos,
      ocultaBotaoVariaveis: true,
      origemAcaoResposta: origemAcaoResposta || "TIMELINE",
    });
    this.props.fecharModalLerEmail();
  };

  render() {
    const {
      responderEmail,
      fecharEditorResponder,
      handleExibirTemplate,
      provider,
      fecharModalLerEmail,
      templateId,
      toggleEditorExpandido,
      editorExpandido,
      exibirBotaoExpandirEditor,
      exibirBotaoExpandirCopias,
      copiasExpandidas,
      toggleCopiasExpandidas,
      podeResponderEmail,
    } = this.props;

    const isEmailCadencia = this.props.responderEmail?.threadNormalizada?.isEmailCadencia ?? false;

    return responderEmail.threadNormalizada ? (
      <ModalLerEmailResponder
        emailsTo={responderEmail.emailsTo}
        emailsCc={responderEmail.emailsCc}
        emailsCco={responderEmail.emailsCco}
        linksAnexos={responderEmail.linksAnexos}
        assinatura={responderEmail.assinatura}
        mensagensIdConteudo={responderEmail.mensagensIdConteudo}
        threadNormalizada={responderEmail.threadNormalizada}
        isLoading={!this.props.usuarioLogado}
        mostrarEditor={responderEmail.mostrarEditor}
        isEncaminhando={responderEmail.isEncaminhando}
        fecharEditorResponder={fecharEditorResponder}
        responderEmail={responderEmail}
        handleExibirTemplate={handleExibirTemplate}
        provider={provider}
        fecharModalLerEmail={fecharModalLerEmail}
        resetarThreads={resetarThreads}
        templateId={templateId}
        editorExpandido={editorExpandido}
        toggleEditorExpandido={toggleEditorExpandido}
        exibirBotaoExpandirEditor={exibirBotaoExpandirEditor}
        exibirBotaoExpandirCopias={exibirBotaoExpandirCopias}
        copiasExpandidas={copiasExpandidas}
        toggleCopiasExpandidas={toggleCopiasExpandidas}
        handleResponder={this.handleResponder}
        handleResponderTodos={this.handleResponderTodos}
        handleEncaminhar={this.handleEncaminhar}
        handleExcluir={this.handleExcluir}
        conteudoHtml={this.props.conteudoHtml}
        conteudoTemplate={this.props.conteudoTemplate}
        mostrarExcluir={this.props.aba !== LIXEIRA}
        isEmailCadencia={isEmailCadencia}
        podeResponderEmail={podeResponderEmail}
        handleResponderEmailCadencia={this.handleResponderEmailCadencia}
      />
    ) : null;
  }
}

const mapStateToProps = state => ({
  podeResponderEmail: state.emails.isAutenticado,
  responderEmail: state.emails.responderEmail,
  usuarioLogado: state.emails.usuarioLogado,
  isPVOrVendedor: state.usuario.isPVOrVendedor,
  aba: state.emails.abaAtual,
});

const mapDispatchToProps = dispatch => ({
  abrirEditorResponder: (emailId, destinatarios, isEncaminhando, mensagensIdConteudo, isResponderTodos) =>
    dispatch(abrirEditorResponderAction(emailId, destinatarios, isEncaminhando, mensagensIdConteudo, isResponderTodos)),
  fecharModalLerEmail: () => dispatch(fecharModalLerEmailAction()),
  fecharEditorResponder: () => dispatch(fecharEditorResponderAction()),
  atualizarThreads: () => dispatch(atualizarThreads()),
  resetarThreads: () => dispatch(resetarThreads()),
  adicionarEmailTo: emailTo => dispatch(adicionarEmailToAction(emailTo)),
  removerEmailTo: emailTo => dispatch(removerEmailToAction(emailTo)),
  atualizarTotalThreads: () => dispatch(atualizarTotalThreads()),
});

const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withRouter,
  injectIntl
);

export default enhance(ModalLerEmailResponderContainer);
