import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "recompose";
import { injectIntl } from "react-intl";

import EmailConteudoExpandir from "./EmailConteudoExpandir.jsx";
import {
  adicionarAssinaturaEnvioEmail,
  getAssinaturaEmail,
  getConteudoEmail,
} from "../../../store/reducers/emails/emailsActions";

class EmailConteudoExpandirContainer extends React.Component {
  static propTypes = {
    responderEmail: PropTypes.object,
    adicionarAssinaturaEnvioEmail: PropTypes.func,
    emailUsuarioLogado: PropTypes.string,
    intl: PropTypes.object,
  };

  state = {
    expandirConteudo: false,
    conteudoParaExpandir: "",
  };

  componentDidMount = async () => {
    this.preencheConteudoExpandir();
  };

  preencheConteudoExpandir = async () => {
    const { responderEmail, emailUsuarioLogado } = this.props;
    const objetoAssinatura = await getAssinaturaEmail(emailUsuarioLogado);
    let assinatura = "";
    if (objetoAssinatura !== "") {
      assinatura = `<br><br>${objetoAssinatura}`;
    }

    let conteudo = assinatura;

    if (responderEmail.mensagensIdConteudo) {
      const variaveis = [
        responderEmail.isEncaminhando,
        responderEmail.threadNormalizada,
        [...new Set(responderEmail.mensagensIdConteudo)],
      ];
      conteudo += getConteudoEmail(...variaveis, "body", this.props.intl);
    }

    this.setState({ conteudoParaExpandir: conteudo });
    this.props.adicionarAssinaturaEnvioEmail(assinatura);
  };

  handleExpandirConteudo = () => {
    this.setState({ expandirConteudo: !this.state.expandirConteudo });
  };

  render() {
    return (
      <EmailConteudoExpandir
        {...this.props}
        conteudoParaExpandir={this.state.conteudoParaExpandir}
        handleExpandirConteudo={this.handleExpandirConteudo}
        expandirConteudo={this.state.expandirConteudo}
      />
    );
  }
}

const mapStateToProps = state => ({
  responderEmail: state.emails.responderEmail,
  emailUsuarioLogado: state.emails.usuarioLogado.email,
});

const mapDispatchToProps = dispatch => ({
  adicionarAssinaturaEnvioEmail: conteudoExpandir => dispatch(adicionarAssinaturaEnvioEmail(conteudoExpandir)),
});

const enhance = compose(
  injectIntl,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
);

export default enhance(EmailConteudoExpandirContainer);
