import React from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { v4 } from "uuid";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, withStyles } from "@material-ui/core";

import { Tipografia } from "../../../_common";
import CadastrarLead from "../../lead/components/cadastrar";
import { emptyContact } from "../../lead/components/contatos/FormContatos";
import { fecharModalSemContatos } from "../../../store/reducers/emails/emailsActions";

const styles = {
  tamanhoPadraoModal: {
    width: "544px !important",
  },
};

const FIRST_UID = v4();

const initialFormValuesCreateLead = ({ email }) => {
  const contatoInicial = emptyContact(true);
  contatoInicial.emails = [{ uid: FIRST_UID, endereco: email, principal: true }];
  return { contatos: [contatoInicial] };
};

const ModalEnviarSemContatos = ({ classes }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { exibirModalSemContatos, emailsTo } = useSelector(state => state.emails.responderEmail);

  const handleFecharModalSemContatos = () => {
    dispatch(fecharModalSemContatos());
  };

  return (
    <Dialog contentClassName={classes.tamanhoPadraoModal} open={exibirModalSemContatos}>
      <DialogTitle>
        {intl.formatMessage({ defaultMessage: "Não foram encontrados destinatários cadastrados." })}
      </DialogTitle>
      <DialogContent>
        <Tipografia>{intl.formatMessage({ defaultMessage: "Deseja cadastrar um novo lead?" })}</Tipografia>
      </DialogContent>
      <DialogActions>
        <Button id="btnCancelar" color="primary" onClick={handleFecharModalSemContatos}>
          {intl.formatMessage({ defaultMessage: "Cancelar" })}
        </Button>
        <CadastrarLead
          id="btnCadastrar"
          callback={handleFecharModalSemContatos}
          initialFormValues={initialFormValuesCreateLead({ email: emailsTo[0]?.email })}
        />
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(ModalEnviarSemContatos);
