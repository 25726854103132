import { defineMessage } from "react-intl";

export const TAB_CAMAPANHAS = "campanhas";
export const TAB_LISTAS = "listas";
export const TAB_TEMPLATES = "templates";
export const TAB_DESCARTADOS = "descadastros";

export const TAB_CAMPANHA_DASHBOARD = "dashboard";
export const TAB_CAMPANHA_RESPOSTAS = "respostas";
export const TAB_CAMPANHA_ENVIOS = "historico";
export const TAB_CAMPANHA_CONTATOS = "contatos";

export const TAB_DOMINIO = "dominio";

export const LISTA_STATUS_CAMPANHA = [
  { id: 1, nome: defineMessage({ defaultMessage: "Ativa" }) },
  { id: 2, nome: defineMessage({ defaultMessage: "Agendada" }) },
  { id: 3, nome: defineMessage({ defaultMessage: "Finalizada" }) },
  { id: 5, nome: defineMessage({ defaultMessage: "Pausada" }) },
  { id: 4, nome: defineMessage({ defaultMessage: "Rascunho" }) },
];

export const LISTA_TIPO_DE_LISTA = [
  { id: 1, nome: defineMessage({ defaultMessage: "Lista de Leads" }) },
  { id: 2, nome: defineMessage({ defaultMessage: "Lista de Prospects" }) },
  { id: 3, nome: defineMessage({ defaultMessage: "Lista Dinâmica" }) },
];

export const STATUS_LISTA_DINAMICA_PAUSADA = 1;
export const STATUS_LISTA_DINAMICA_ATIVA = 2;

export const LISTA_STATUS_LISTA_DINAMICA = [
  { id: STATUS_LISTA_DINAMICA_PAUSADA, nome: defineMessage({ defaultMessage: "Pausada" }) },
  { id: STATUS_LISTA_DINAMICA_ATIVA, nome: defineMessage({ defaultMessage: "Ativa" }) },
];

export const CAMPANHAS_COLUNA_CAMPANHA = "Campanha";
export const CAMPANHAS_COLUNA_CONTATOS = "Contatos";
export const CAMPANHAS_COLUNA_ENVIOS = "Envios";
export const CAMPANHAS_COLUNA_ABERTURAS = "Aberturas";
export const CAMPANHAS_COLUNA_RESPOSTAS = "Respostas";
export const CAMPANHAS_COLUNA_CONVERSOES = "Conversoes";
export const CAMPANHAS_COLUNA_STATUS = "Status";
export const CAMPANHAS_COLUNA_DATACADASTRO = "DataCriacao";

export const LISTAS_COLUNA_LISTA = "Lista";
export const LISTAS_COLUNA_CONTATOS = "Contatos";
export const LISTAS_COLUNA_CAMPANHAATUAL = "CampanhaAtual";
export const LISTAS_COLUNA_RESPONSAVEL = "Responsavel";
export const LISTAS_COLUNA_DATA = "Data";
export const LISTAS_COLUNA_DATACRIACAO = "DataCriacao";

export const TEMPLATES_COLUNA_NOME = "Nome";
export const TEMPLATES_COLUNA_CAMPANHA = "Campanha";
export const TEMPLATES_COLUNA_RESPONSAVEL = "Responsavel";
export const TEMPLATES_COLUNA_DATA = "Data";
export const TEMPLATES_COLUNA_STATUS = "Status";
export const TEMPLATES_COLUNA_DATASTATUS = "DataStatus";

export const DESCADASTROS_COLUNA_CONTATO = "Contato";
export const DESCADASTROS_COLUNA_EMAIL = "Email";
export const DESCADASTROS_COLUNA_EMPRESA = "Empresa";
export const DESCADASTROS_COLUNA_CAMPANHA = "Campanha";
export const DESCADASTROS_COLUNA_LISTA = "Lista";

export const TAMANHO_MAXIMO_ETAPAS_CAMPANHA = 3;

export const TIPO_ENVIO_NOVO_EMAIL = 1;
export const TIPO_ENVIO_MESMA_THREAD = 2;

export const CONDICOES_ETAPA = [
  { id: 1, nome: defineMessage({ defaultMessage: "Não abriram e-mail anterior" }), disabled: false },
  { id: 2, nome: defineMessage({ defaultMessage: "Não responderam e-mail anterior" }), disabled: false },
  { id: 4, nome: defineMessage({ defaultMessage: "Não clicou no link" }), disabled: false },
  { id: 0, nome: defineMessage({ defaultMessage: "Não baixou anexo (em breve)" }), disabled: true },
  { id: 3, nome: defineMessage({ defaultMessage: "Sem restrição, enviar esse e-mail para todos" }), disabled: false },
];

export const LIMITES_ENVIO_DIARIO = [
  { id: 50, nome: "50" },
  { id: 100, nome: "100" },
  { id: 200, nome: "200" },
  { id: 400, nome: "400" },
];

export const TIPO_ORDENACAO_ASC = "asc";
export const TIPO_ORDENACAO_DESC = "desc";

export const RESPOSTAS_COLUNA_CONTATO = "Contato";
export const RESPOSTAS_COLUNA_EMPRESA = "Empresa";
export const RESPOSTAS_COLUNA_MENSAGEM = "Mensagem";
export const RESPOSTAS_COLUNA_DATA = "Data";
export const RESPOSTAS_COLUNA_STATUS = "Status";

export const FILTRO_STATUS_RESPOSTA = [
  { id: 7, nome: defineMessage({ defaultMessage: "Interessado" }) },
  { id: 2, nome: defineMessage({ defaultMessage: "Interessado, com atividade" }) },
  { id: 3, nome: defineMessage({ defaultMessage: "Interessado, sem atividade" }) },
  { id: 6, nome: defineMessage({ defaultMessage: "Não interessado" }) },
  { id: 5, nome: defineMessage({ defaultMessage: "Sem avaliação" }) },
];

export const FILTROS_PREDEF = {
  statusEnvio: {
    aberturas: [4],
    bounce: [9, 10],
    interesses: [7],
  },
};

export const ENVIOS_COLUNA_CONTATO = "Contato";
export const ENVIOS_COLUNA_EMAIL = "Email";
export const ENVIOS_COLUNA_EMPRESA = "Empresa";
export const ENVIOS_COLUNA_PREVENDEDOR = "PreVendedor";

export const STATUS_CAMPANHA_ACOMPANHAMENTO = {
  AGENDADO: { id: "1", nome: defineMessage({ defaultMessage: "Agendado" }) },
  A_CAMINHO: { id: "2", nome: defineMessage({ defaultMessage: "A caminho" }) },
  ENTREGUE: { id: "3", nome: defineMessage({ defaultMessage: "Entregue" }) },
  ABRIU_EMAIL: { id: "4", nome: defineMessage({ defaultMessage: "Abriu e-mail" }) },
  RESPONDEU: { id: "5", nome: defineMessage({ defaultMessage: "Respondeu" }) },
  SEM_INTERESSE: { id: "6", nome: defineMessage({ defaultMessage: "Sem interesse" }) },
  INTERESSADO: { id: "7", nome: defineMessage({ defaultMessage: "Interessado" }) },
  SOFT_BOUNCE: { id: "9", nome: defineMessage({ defaultMessage: "Soft Bounce" }) },
  HARD_BOUNCE: { id: "10", nome: defineMessage({ defaultMessage: "Hard Bounce" }) },
  CLICADO: { id: "13", nome: defineMessage({ defaultMessage: "Clicou no link" }) },
};

export const FILTRO_STATUS_ENVIO = [
  STATUS_CAMPANHA_ACOMPANHAMENTO.AGENDADO,
  STATUS_CAMPANHA_ACOMPANHAMENTO.A_CAMINHO,
  STATUS_CAMPANHA_ACOMPANHAMENTO.ENTREGUE,
  STATUS_CAMPANHA_ACOMPANHAMENTO.ABRIU_EMAIL,
  STATUS_CAMPANHA_ACOMPANHAMENTO.RESPONDEU,
  STATUS_CAMPANHA_ACOMPANHAMENTO.SEM_INTERESSE,
  STATUS_CAMPANHA_ACOMPANHAMENTO.INTERESSADO,
  STATUS_CAMPANHA_ACOMPANHAMENTO.SOFT_BOUNCE,
  STATUS_CAMPANHA_ACOMPANHAMENTO.HARD_BOUNCE,
  STATUS_CAMPANHA_ACOMPANHAMENTO.CLICADO,
];

export const MAXIMO_REGISTROS_POR_PAGINA = 40;
export const MAXIMO_REGISTROS_PAGINA_ENVIOS = 30;

export const MODO_LISTA_CRIACAO = 1;
export const MODO_LISTA_VISUALIZACAO = 2;
export const MODO_LISTA_EDICAO = 3;

export const LIMITE_CONTATOS_LISTA = 2000;

export const TEMPO_MINIMO_INTERVALO_ETAPA = "TempoMinimoIntervaloEtapasCadencia";
export const STATUS_CAMPANHA_AGENDADA = 2;

export const UNIDADE_TEMPO_ETAPA = [
  { id: 1, nome: defineMessage({ defaultMessage: "Hora(s)" }) },
  { id: 2, nome: defineMessage({ defaultMessage: "Dia(s)" }) },
  { id: 3, nome: defineMessage({ defaultMessage: "Semana(s)" }) },
  { id: 4, nome: defineMessage({ defaultMessage: "Mês(es)" }) },
];

export const UNIDADE_TEMPO_ETAPA_MINUTOS = { id: 5, nome: defineMessage({ defaultMessage: "Minuto(s)" }) };

export const UNIDADE_TEMPO_ETAPA_INICIAL = 1;

export const RESPOSTA_SEM_AVALIACAO = 5;
export const RESPOSTA_SEM_INTERESSE = 6;
export const RESPOSTA_INTERESSADA = 7;

export const REGISTROMX = "Registro MX";

export const TIPO_EMAIL = {
  Enviado: 1,
  Resposta: 2,
};
