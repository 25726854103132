import { defineMessages } from "react-intl";

export const ENTRADA = "entrada";
export const IMPORTANTE = "importante";
export const ENVIADOS = "enviados";
export const ARQUIVADOS = "arquivados";
export const LIXEIRA = "lixeira";
export const SPAM = "spam";
export const BUSCAR = "buscar";

export const URLSPOTTERMAILSERVICE = "UrlSpotterMailService";

export const PROVIDERS = {
  Gmail: "GMAIL",
  Outlook: "OUTLOOK",
  SMTP: "SMTP",
};

export const rotuloAbas = defineMessages({
  Entrada: {
    defaultMessage: "Entrada",
  },
  Enviados: {
    defaultMessage: "Enviados",
  },
  Lixeira: {
    defaultMessage: "Lixeira",
  },
  Importante: {
    defaultMessage: "Importante",
  },
});

export const ABAS_GMAIL = [
  {
    label: "Entrada",
    rota: ENTRADA,
  },
  {
    label: "Enviados",
    rota: ENVIADOS,
  },
  {
    label: "Lixeira",
    rota: LIXEIRA,
  },
  {
    label: "Importante",
    rota: IMPORTANTE,
  },
];

export const ABAS_OUTLOOK = [
  {
    label: "Entrada",
    rota: ENTRADA,
  },
  {
    label: "Enviados",
    rota: ENVIADOS,
  },
  {
    label: "Lixeira",
    rota: LIXEIRA,
  },
];

export const ABAS_SMTP = [
  {
    label: "Entrada",
    rota: ENTRADA,
  },
  {
    label: "Enviados",
    rota: ENVIADOS,
  },
  {
    label: "Lixeira",
    rota: LIXEIRA,
  },
];

export function retornarEndPoints(URL_SPOTTER_MAIL_SERVICE) {
  return {
    AutenticacaoGmail: `${URL_SPOTTER_MAIL_SERVICE}/auth/retornarUrlAutenticacaoGmail`,
    AutenticacaoOutlook: `${URL_SPOTTER_MAIL_SERVICE}/auth/retornarUrlAutenticacaoOutlook`,
    Desautenticar: `${URL_SPOTTER_MAIL_SERVICE}/auth/desautenticar`,
    Listar: `${URL_SPOTTER_MAIL_SERVICE}/emails/listar`,
    TotalThreads: `${URL_SPOTTER_MAIL_SERVICE}/emails/TotalThreads`,
    ListarEmail: `${URL_SPOTTER_MAIL_SERVICE}/emails/Listar`,
    AlterarFlag: `${URL_SPOTTER_MAIL_SERVICE}/emails/AlterarFlag`,
    AlterarImportant: `${URL_SPOTTER_MAIL_SERVICE}/emails/AlterarImportant`,
    RemoverEmail: `${URL_SPOTTER_MAIL_SERVICE}/emails/RemoverEmail`,
    BuscarPorThreadId: `${URL_SPOTTER_MAIL_SERVICE}/emails/BuscarPorThreadId`,
    SalvarThread: `${URL_SPOTTER_MAIL_SERVICE}/emails/SalvarCopiaThread`,
    EmailLeadTimeLine: `${URL_SPOTTER_MAIL_SERVICE}/emails/RetornarEmailsLead`,
    Assinatura: `${URL_SPOTTER_MAIL_SERVICE}/emails/RetornarAssinatura`,
    LinkAnexo: `${URL_SPOTTER_MAIL_SERVICE}/attachment`,
    EnviarEmail: `${URL_SPOTTER_MAIL_SERVICE}/emails/enviarEmail`,
    MarcarEmailLido: `${URL_SPOTTER_MAIL_SERVICE}/emails/marcarEmailLido`,
  };
}
