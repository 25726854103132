import React from "react";
import PropType from "prop-types";
import { useIntl } from "react-intl";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from "@material-ui/core";

import { Tipografia } from "../../../_common";
import injectSheet from "../../../_common/hocs/injectSheet";

const styles = {
  tamanhoPadraoModal: {
    width: "468px !important",
  },
  tamanhoPadraoBody: {
    heigth: "200px !important",
  },
};

const ModalEnviarPreencherVariaveis = ({ listaVariaveis, handleOpenDetalhesLead, fecharModalAlertaVariaveis }) => {
  const intl = useIntl();

  return (
    <Dialog open>
      <DialogTitle>
        {intl.formatMessage({ defaultMessage: "As seguintes variáveis não foram encontradas:" })}
      </DialogTitle>
      <DialogContent>
        {listaVariaveis.map(elem => <p key={elem}>{elem}</p>)}
        <Tipografia>
          {intl.formatMessage({
            defaultMessage: "As variáveis podem ser preenchidas na página de detalhes do lead ou no corpo do e-mail.",
          })}
        </Tipografia>
      </DialogContent>
      <DialogActions>
        <Button id="btnCancelar" color="primary" onClick={fecharModalAlertaVariaveis}>
          {intl.formatMessage({ defaultMessage: "CANCELAR" })}
        </Button>,
        <Button id="btnDetalhes" color="primary" variant="contained" onClick={handleOpenDetalhesLead}>
          {intl.formatMessage({ defaultMessage: "IR PARA DETALHES" })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ModalEnviarPreencherVariaveis.propTypes = {
  listaVariaveis: PropType.array.isRequired,
  handleOpenDetalhesLead: PropType.func.isRequired,
  fecharModalAlertaVariaveis: PropType.func.isRequired,
};

export default injectSheet(styles)(ModalEnviarPreencherVariaveis);
