import React from "react";
import PropTypes from "prop-types";

import injectSheet, { withTheme } from "react-jss";
import { compose } from "recompose";

import { Modal } from "../../../_common";
import ModalLerEmailSubHeader from "../ModalLerEmailSubHeader/ModalLerEmailSubHeader";
import ModalLerEmailHeader from "../ModalLerEmailHeader/ModalLerEmailHeader";
import EmailItem from "../EmailItem/EmailItem";
import EmailItemAgrupador from "../EmailItemAgrupador/EmailItemAgrupador";

const styles = {
  modalContainer: {
    padding: "0 !important",
    height: 760,
    overflow: "auto",
    // borderTopRightRadius: "4px !important",
    // borderTopLeftRadius: "4px !important",
    display: "flex",
    flexDirection: "column",
  },
  modalPaperContainer: {
    borderTopRightRadius: "4px !important",
    borderTopLeftRadius: "4px !important",
  },
  modalContent: {
    "&::-webkit-scrollbar": { display: "none" },
    overflowY: "scroll",
    overflowX: "hidden",
    flexGrow: 1,
  },
};

const ModalVisualizarEmail = ({ classes, tituloCabecalho, threadEmails, open, handleFecharModal, ...rest }) => (
  <Modal bodyClassName={classes.modalContainer} paperClassName={classes.modalPaperContainer} open={open} {...rest}>
    <ModalLerEmailHeader handleFecharModal={handleFecharModal} tituloCabecalho={tituloCabecalho} />

    <ModalLerEmailSubHeader key="subheader" assunto={threadEmails.assunto} mostrarMaisOpcoes={false} {...rest} />
    <div className={classes.modalContent} key="modalContent">
      <EmailItemAgrupador threadEmails={threadEmails} email={threadEmails.emails[0]}>
        {threadEmails.emails.map(email => (
          <EmailItem
            key={email.mensagemId}
            email={email}
            threadEmails={threadEmails}
            podeEnviarEmail={false}
            modoVisualizacao
          />
        ))}
      </EmailItemAgrupador>
    </div>
  </Modal>
);

ModalVisualizarEmail.propTypes = {
  classes: PropTypes.object,
  threadEmails: PropTypes.object.isRequired,
  tituloCabecalho: PropTypes.string,
  handleFecharModal: PropTypes.func.isRequired,
  exibeTemplates: PropTypes.bool,
  provider: PropTypes.object,
  open: PropTypes.bool.isRequired,
};

const enhance = compose(
  injectSheet(styles),
  withTheme
);

export default enhance(ModalVisualizarEmail);
