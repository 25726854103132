import React from "react";

import pipe from "pipe-now";
import PropTypes from "prop-types";
import { EditorState, Modifier, ContentState } from "draft-js";
import { get, CancelToken } from "axios";
import { connect } from "react-redux";
import { debounce } from "lodash";
import v4 from "uuid/v4";
import { compose } from "recompose";
import { injectIntl } from "react-intl";

import { validarEmail } from "../../../_common/utils/string";
import createSnackbar from "../../../_common/utils/snackbar/createSnackbar";
import {
  convertFromHTML,
  convertToHTML,
  decorators,
  getActiveButtons,
} from "../../../_common/utils/editorTexto/editorTexto";

import EditorTextoEmails from "../EditorTextoEmails/EditorTextoEmails.jsx";
import { getDataFromResponse } from "../../../_common/utils/object/object";
import {
  adicionarEmailTo as adicionarEmailToAction,
  removerEmailTo as removerEmailToAction,
  adicionarEmailCc as adicionarEmailCcAction,
  removerEmailCc as removerEmailCcAction,
  adicionarEmailCco as adicionarEmailCcoAction,
  removerEmailCco as removerEmailCcoAction,
  fecharEditorResponder,
  salvarLinkAnexo,
  removerLinkAnexo,
  getListaEmpresasContato,
  buscarContatosLead,
  enviarEmail as enviarEmailAction,
  enviarEmailPreencherContatos,
  getAliasDefault,
  getDadosEmail,
  substituiVariaveisTemplate,
} from "../../../store/reducers/emails/emailsActions";
import { AjaxBlackout } from "../../../_common/index";

class EditorTextoEmailsBaseContainer extends React.PureComponent {
  state = {
    dataSourceChip: [],
    assunto: this.props.assuntoInicial,
    openMenuVariaveis: false,
    menuVariaveisAnchorEl: null,
    listaDeVariaveis: [],
    inserirVariavelCorpo: false,
    inserirVariavelAssunto: false,
    conteudoTemplate: "",
    conteudoHtml: false,
  };

  componentDidMount = async () => {
    let content = "";

    const listaDeVariaveis = await pipe(
      await get("/api/pipeline/TemplateEmail/BuscarVariaveisTemplateComVariaveisDinamicas"),
      getDataFromResponse
    );

    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({ listaDeVariaveis });

    if (this.props.templateId) {
      const result = await get("/api/pipeline/TemplateEmail/Buscar", {
        params: {
          id: this.props.templateId,
        },
      });

      if (this.props.substituiVariaveisTemplate) {
        await substituiVariaveisTemplate(this.props.leadIdSelecionado, this.props.emailSelecionado, result.data);
      }

      this.setState({ conteudoTemplate: result.data.conteudo, conteudoHtml: result.data.conteudoHtml });
      if (this.props.substituiVariaveisTemplate) {
        await substituiVariaveisTemplate(this.props.leadIdSelecionado, this.props.emailSelecionado, result.data);
      }

      const conteudo = convertFromHTML(result.data.conteudo);
      content = EditorState.createWithContent(conteudo, decorators);

      if (!this.props.isRespondendo) {
        // eslint-disable-next-line react/no-did-mount-set-state
        this.setState({ assunto: result.data.assunto });
      }
    } else {
      content = EditorState.createEmpty(decorators);
    }

    this.props.onChange(content);
  };

  onAttachmentClick = event => {
    try {
      this.props.salvarLinkAnexo(event, this.props.linksAnexos);
    } catch (error) {
      createSnackbar(error.message);
    }
  };

  setFocusFn = fn => {
    this.focus = fn;
  };

  handleCloseMenuVariaveis = () => {
    this.setState({ openMenuVariaveis: false, menuVariaveisAnchorEl: null });
  };

  handleClickMenuVariaveis = ({ currentTarget }) => {
    this.setState({ openMenuVariaveis: true, menuVariaveisAnchorEl: currentTarget });
    if (currentTarget.id === "btnInserirVariavelAssunto") {
      this.setState({ inserirVariavelAssunto: true });
      this.setState({ inserirVariavelCorpo: false });
    }
    if (currentTarget.id === "btnInserirVariavelCorpo") {
      this.setState({ inserirVariavelCorpo: true });
      this.setState({ inserirVariavelAssunto: false });
    }
  };

  handleClickInserirVariavelAssunto = (evt, val) => {
    const campo = document.querySelector("#campoAssunto");
    const textoInicial = campo.value.substring(0, campo.selectionStart);
    const textoFinal = campo.value.substring(campo.selectionStart);
    campo.value = `${textoInicial}${val}${textoFinal}`;
    this.setState({ assunto: campo.value });
    this.handleCloseMenuVariaveis();
  };
  handleClickInserirVariavelCorpo = (evt, val) => {
    const { editorState } = this.props;
    const selection = editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    const nextContentState = Modifier.replaceText(contentState, selection, val);
    const nextEditorState = EditorState.push(editorState, nextContentState, "insert-characters");
    this.props.onChange(nextEditorState);
    this.focus();
    this.handleCloseMenuVariaveis();
  };

  handleClickVariavel = (evt, val) => {
    if (this.state.inserirVariavelCorpo) {
      this.handleClickInserirVariavelCorpo(evt, val);
    }
    if (this.state.inserirVariavelAssunto) {
      this.handleClickInserirVariavelAssunto(evt, val);
    }
  };

  handleExcluirLinkAnexo = async chave => {
    try {
      await this.props.removerLinkAnexo(chave, this.props.linksAnexos);
    } catch (error) {
      createSnackbar(error.message);
    }
  };

  preencherDadosNoEditorSeNecessario = async () => {
    if (this.props.templateId) {
      const result = await get("/api/pipeline/TemplateEmail/Buscar", { params: { id: this.props.templateId } });
      const contentState = convertFromHTML(result.data.Conteudo);
      const content = EditorState.createWithContent(contentState, decorators);

      this.props.onChange(content);
    }
  };

  buscaEmailsCancelTokenSource = CancelToken.source();
  focus = () => {};

  cancelarTokenBuscaEmails = () => {
    this.buscaEmailsCancelTokenSource.cancel();
    this.buscaEmailsCancelTokenSource = CancelToken.source();
  };

  handleRequestAddChipBase = (chip, dataSourceChip, listaEmails, adicionarChip) => {
    this.cancelarTokenBuscaEmails();

    let email = chip.email || chip.id;
    email = typeof email === "string" ? email.trim() : email;

    const name = email.split("@")[0];

    const { nomeLead, idLead, ...datasource } = dataSourceChip.find(x => x.email === chip.email) || {
      email,
      name,
      id: v4(),
    };

    if (datasource.email && !listaEmails.find(x => x.email.toLowerCase() === datasource.email.toLowerCase())) {
      if (validarEmail(datasource.email)) {
        const listaLeads = getListaEmpresasContato(dataSourceChip, datasource.email);

        this.setState({
          dataSourceChip: [],
        });
        adicionarChip({ ...datasource, listaLeads });
      } else {
        createSnackbar(this.props.intl.formatMessage({ defaultMessage: "E-mail inválido." }));
      }
    }
  };

  handleRequestAddChipTo = chip => {
    this.handleRequestAddChipBase(chip, this.state.dataSourceChip, this.props.emailsTo, this.props.adicionarEmailTo);
  };

  handleRequestAddChipCc = chip => {
    this.handleRequestAddChipBase(chip, this.state.dataSourceChip, this.props.emailsCc, this.props.adicionarEmailCc);
  };

  handleRequestAddChipCco = chip => {
    this.handleRequestAddChipBase(chip, this.state.dataSourceChip, this.props.emailsCco, this.props.adicionarEmailCco);
  };

  handleRequestDeleteChipTo = (render, index) => {
    const emailTo = this.props.emailsTo[index];

    this.props.removerEmailTo(emailTo);
  };

  handleRequestDeleteChipCc = (render, index) => {
    const emailCc = this.props.emailsCc[index];

    this.props.removerEmailCc(emailCc);
  };

  handleRequestDeleteChipCco = (render, index) => {
    const emailCco = this.props.emailsCco[index];

    this.props.removerEmailCco(emailCco);
  };

  handleChangeInputChip = value => {
    this.debounceBuscaEmails(value);
  };

  debounceBuscaEmails = debounce(async value => {
    const emailsExistentes = [...this.props.emailsTo, ...this.props.emailsCc, ...this.props.emailsCco];
    const dataSourceChip = await buscarContatosLead(value, emailsExistentes, this.buscaEmailsCancelTokenSource.token);
    if (dataSourceChip) {
      this.setState({
        dataSourceChip,
      });
    }
  }, 400);

  enviarEmail = async () => {
    if (this.props.enviarEmail !== undefined) {
      const dadosCallback = await this.preencherDadosEmail(this.props.isEncaminhando, this.props.isRespondendo);
      this.props.enviarEmail(dadosCallback);
    } else {
      if (!this.props.emailsTo || this.props.emailsTo.length === 0) {
        createSnackbar(this.props.intl.formatMessage({ defaultMessage: "O Para é obrigatório." }));
        return;
      }

      AjaxBlackout.Show();
      const dadosEmail = await this.preencherDadosEmail(this.props.isEncaminhando, this.props.isRespondendo);
      await this.props.enviarEmailAction(dadosEmail);
    }
  };

  preencherContato = async (leadId, email, isEncaminhando, isRespondendo) => {
    const dadosEmail = await this.preencherDadosEmail(isEncaminhando, isRespondendo);
    this.props.enviarEmailPreencherContatos(leadId, email, dadosEmail);
  };

  preencherDadosEmail = async (isEncaminhando, isRespondendo) => {
    const corpoEmail = this.state.conteudoHtml
      ? this.state.conteudoTemplate
      : convertToHTML(this.props.editorState.getCurrentContent());
    const alias = await getAliasDefault();

    const dadosEmail = await getDadosEmail(
      {
        corpoEmail,
        alias,
        isEncaminhando,
        isRespondendo,
        assinatura: this.props.assinatura,
        mensagensIdConteudo: this.props.mensagensIdConteudo,
        usuarioLogado: this.props.usuario,
        threadNormalizada: this.props.threadNormalizada,
        linksAnexos: this.props.linksAnexos,
        emailsTo: this.props.emailsTo?.map(x => x.email),
        emailsCc: this.props.emailsCc?.map(x => x.email),
        emailsCco: this.props.emailsCco?.map(x => x.email),
        assuntoEmail: this.state.assunto,
        handleFecharModal: this.props.fecharModalLerEmail,
        usuarioSpotter: this.props.usuario,
        isCadencia: this.props.isCadencia,
      },
      this.props.intl
    );

    if (this.props.isRespondendo && !this.props.isEncaminhando) {
      dadosEmail.messageIdentifier = this.props.threadNormalizada.messageId || this.props.threadNormalizada.mensagemId;
      dadosEmail.threadId = this.props.threadNormalizada.id || this.props.threadNormalizada.threadId;
    }

    return dadosEmail;
  };

  handleOnBlurChipBase = (value, addChip) => {
    if (value && validarEmail(value)) {
      const email = { id: value };
      addChip(email);
    }
  };

  handleOnBlurChipTo = event => {
    this.handleOnBlurChipBase(event.target.value, this.handleRequestAddChipTo);
  };

  handleOnBlurChipCc = event => {
    this.handleOnBlurChipBase(event.target.value, this.handleRequestAddChipCc);
  };

  handleOnBlurChipCco = event => {
    this.handleOnBlurChipBase(event.target.value, this.handleRequestAddChipCco);
  };

  handleFieldChanges = (nomeInput, evento) => {
    const campo = {};
    campo[nomeInput] = evento.target.value;
    this.setState(campo);
  };

  escreverConteudoMagicWrite = (pergunta, resposta) => {
    const content = ContentState.createFromText(resposta);
    const newContentState = EditorState.createWithContent(content, decorators);

    this.props.onChange(newContentState);
    this.setState({ ...this.state, textoBuscaMagic: pergunta, abrirMagicComposer: false });
  };

  render = () => (
    <EditorTextoEmails
      listaDeVariaveis={this.state.listaDeVariaveis}
      handleClickMenuVariaveis={this.handleClickMenuVariaveis}
      handleCloseMenuVariaveis={this.handleCloseMenuVariaveis}
      menuVariaveisAnchorEl={this.state.menuVariaveisAnchorEl}
      openMenuVariaveis={this.state.openMenuVariaveis}
      handleClickVariavel={this.handleClickVariavel}
      templateId={this.props.templateId}
      editorState={this.props.editorState}
      onChange={this.props.onChange}
      attachmentClick={this.onAttachmentClick}
      handleExcluirLinkAnexo={this.handleExcluirLinkAnexo}
      handleFieldChanges={this.handleFieldChanges}
      activeButtons={getActiveButtons(this.props.editorState)}
      assunto={this.state.assunto}
      enviarEmail={this.enviarEmail}
      handleExcluirRascunho={this.props.handleExcluirRascunho}
      dataSourceChip={this.state.dataSourceChip}
      handleRequestAddChipTo={this.handleRequestAddChipTo}
      handleRequestAddChipCc={this.handleRequestAddChipCc}
      handleRequestAddChipCco={this.handleRequestAddChipCco}
      handleRequestDeleteChipTo={this.handleRequestDeleteChipTo}
      handleRequestDeleteChipCc={this.handleRequestDeleteChipCc}
      handleRequestDeleteChipCco={this.handleRequestDeleteChipCco}
      handleChangeInputChip={this.handleChangeInputChip}
      handleOnBlurChipTo={this.handleOnBlurChipTo}
      handleOnBlurChipCc={this.handleOnBlurChipCc}
      handleOnBlurChipCco={this.handleOnBlurChipCco}
      handleExibirTemplate={this.props.handleExibirTemplate}
      emailsTo={this.props.emailsTo}
      emailsCc={this.props.emailsCc}
      emailsCco={this.props.emailsCco}
      isRespondendo={this.props.isRespondendo}
      linksAnexos={this.props.linksAnexos}
      preencherContato={this.preencherContato}
      editorExpandido={this.props.editorExpandido}
      toggleEditorExpandido={this.props.toggleEditorExpandido}
      exibirBotaoExpandirEditor={this.props.exibirBotaoExpandirEditor}
      esconderDivider={this.props.esconderDivider}
      isCarregandoEmailsTo={this.props.isCarregandoEmailsTo}
      exibirBotaoExpandirCopias={this.props.exibirBotaoExpandirCopias}
      copiasExpandidas={this.props.copiasExpandidas}
      toggleCopiasExpandidas={this.props.toggleCopiasExpandidas}
      conteudoHtml={this.state.conteudoHtml}
      conteudoTemplate={this.state.conteudoTemplate}
      isEncaminhando={this.props.isEncaminhando}
      setFocusCampoMensagem={this.props.isRespondendo && !this.props.isEncaminhando}
      setFocusFn={this.setFocusFn}
      ocultaBotaoVariaveis={this.props.ocultaBotaoVariaveis}
      escreverConteudoMagicWrite={this.escreverConteudoMagicWrite}
      ocultarDescartarRascunho={this.props.ocultarDescartarRascunho}
      ocultarBarraDestinatarios={this.props.ocultarBarraDestinatarios}
    />
  );
}

EditorTextoEmailsBaseContainer.propTypes = {
  onChange: PropTypes.func.isRequired,
  editorState: PropTypes.object.isRequired,
  handleExcluirRascunho: PropTypes.func.isRequired,
  handleExibirTemplate: PropTypes.func.isRequired,
  emailsTo: PropTypes.array.isRequired,
  emailsCc: PropTypes.array,
  emailsCco: PropTypes.array,
  linksAnexos: PropTypes.array,
  isEncaminhando: PropTypes.bool,
  templateId: PropTypes.number,
  adicionarEmailTo: PropTypes.func,
  removerEmailTo: PropTypes.func,
  adicionarEmailCc: PropTypes.func,
  removerEmailCc: PropTypes.func,
  adicionarEmailCco: PropTypes.func,
  removerEmailCco: PropTypes.func,
  fecharModalLerEmail: PropTypes.func,
  usuario: PropTypes.object,
  assuntoInicial: PropTypes.string,
  isRespondendo: PropTypes.bool,
  assinatura: PropTypes.string,
  mensagensIdConteudo: PropTypes.array,
  threadNormalizada: PropTypes.object,
  editorExpandido: PropTypes.bool,
  toggleEditorExpandido: PropTypes.func,
  exibirBotaoExpandirEditor: PropTypes.bool,
  esconderDivider: PropTypes.bool,
  isCarregandoEmailsTo: PropTypes.bool,
  exibirBotaoExpandirCopias: PropTypes.bool,
  copiasExpandidas: PropTypes.bool,
  toggleCopiasExpandidas: PropTypes.func,
  salvarLinkAnexo: PropTypes.func,
  removerLinkAnexo: PropTypes.func,
  enviarEmailPreencherContatos: PropTypes.func,
  enviarEmailAction: PropTypes.func,
  ocultaBotaoVariaveis: PropTypes.bool,
  isCadencia: PropTypes.bool,
  intl: PropTypes.object,
  ocultarDescartarRascunho: PropTypes.bool,
  ocultarBarraDestinatarios: PropTypes.bool,
  enviarEmail: PropTypes.func,
  substituiVariaveisTemplate: PropTypes.bool,
  leadIdSelecionado: PropTypes.number,
  emailSelecionado: PropTypes.string,
};

const mapStateToProps = state => ({
  usuario: state.usuario,
  ocultaBotaoVariaveis: state.emails.parametros.ocultaBotaoVariaveis,
  isCadencia: state.emails.parametros.isCadencia,
  substituiVariaveisTemplate: state.emails.parametros.substituiVariaveisTemplate,
  leadIdSelecionado: state.emails.parametros.leadIdSelecionado,
  emailSelecionado: state.emails.parametros.emailSelecionado,
});

const mapDipatchToProps = dispatch => ({
  adicionarEmailTo: emailTo => dispatch(adicionarEmailToAction(emailTo)),
  removerEmailTo: emailTo => dispatch(removerEmailToAction(emailTo)),
  adicionarEmailCc: emailCc => dispatch(adicionarEmailCcAction(emailCc)),
  removerEmailCc: emailCc => dispatch(removerEmailCcAction(emailCc)),
  adicionarEmailCco: emailCco => dispatch(adicionarEmailCcoAction(emailCco)),
  removerEmailCco: emailCco => dispatch(removerEmailCcoAction(emailCco)),
  fecharEditorResponder: () => dispatch(fecharEditorResponder()),
  salvarLinkAnexo: (event, linksAnexos) => dispatch(salvarLinkAnexo(event, linksAnexos)),
  removerLinkAnexo: (chaveBlob, linksAnexos) => dispatch(removerLinkAnexo(chaveBlob, linksAnexos)),
  enviarEmailAction: dadosEmail => dispatch(enviarEmailAction(dadosEmail)),
  enviarEmailPreencherContatos: (leadId, email, dadosEmail) =>
    dispatch(enviarEmailPreencherContatos(leadId, email, dadosEmail)),
});

const enhance = compose(
  injectIntl,
  connect(
    mapStateToProps,
    mapDipatchToProps
  )
);

export default enhance(EditorTextoEmailsBaseContainer);
