import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { compose } from "recompose";

import ModalEmailHeader from "./ModalLerEmailHeader.jsx";

class ModalLerEmailHeaderContainer extends React.PureComponent {
  render() {
    const {
      isEncaminhando,
      isResponderTodos,
      mostrarEditor,
      classes,
      className,
      tituloCabecalho,
      handleFecharModal,
    } = this.props;

    return (
      <ModalEmailHeader
        mostrarEditor={mostrarEditor}
        isEncaminhando={isEncaminhando}
        isResponderTodos={isResponderTodos}
        classes={classes}
        className={className}
        tituloCabecalho={tituloCabecalho}
        handleFecharModal={handleFecharModal}
      />
    );
  }
}

ModalLerEmailHeaderContainer.propTypes = {
  mostrarEditor: PropTypes.bool,
  isEncaminhando: PropTypes.bool,
  isResponderTodos: PropTypes.bool,
  classes: PropTypes.object,
  className: PropTypes.string,
  tituloCabecalho: PropTypes.string.isRequired,
  handleFecharModal: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isEncaminhando: state.emails.responderEmail.isEncaminhando,
  isResponderTodos: state.emails.responderEmail.isResponderTodos,
  mostrarEditor: state.emails.responderEmail.mostrarEditor,
});

const enhance = compose(
  connect(mapStateToProps),
  withRouter
);
export default enhance(ModalLerEmailHeaderContainer);
