import { DEFAULT_RULE } from "../../../../../_common/components/QueryBuilder/reducer/initialState";
import { appIntl } from "../../../../../_common/containers/ConnectedIntlProvider";

const invalidFields = [null, undefined, DEFAULT_RULE().field];

const errorMessage = groupIndex => {
  const intl = appIntl();
  return intl.formatMessage(
    { defaultMessage: "Segmentação {index}: Precisamos de critério(s) para salvar essa lista." },
    { index: groupIndex + 1 }
  );
};

const validateRule = (rule, groupIndex) => {
  const possuiTerceiraOpiniao = rule.field === "-8" || parseInt(rule.field, 0) > 0;
  if (invalidFields.includes(rule.field) || rule.response === null) {
    throw new Error(errorMessage(groupIndex));
  }

  if (rule.response === null) {
    throw new Error(errorMessage(groupIndex));
  }

  const respostas = rule.response.length;
  let validarTerceiraOpcao = false;

  for (let i = 0; i < respostas; i++) {
    validarTerceiraOpcao = rule.response[i].includes("/");
  }

  if (possuiTerceiraOpiniao && !validarTerceiraOpcao) throw new Error(errorMessage(groupIndex));

  if (possuiTerceiraOpiniao) {
    if (rule.id != null && rule.response.length === 0) throw new Error(errorMessage(groupIndex));
    if (rule.value === 0 || rule.value.length === 0) throw new Error(errorMessage(groupIndex));
    if (rule.response === 0 || rule.response.length === 0) throw new Error(errorMessage(groupIndex));
  } else if (rule.response === 0 || rule.response.length === 0) throw new Error(errorMessage(groupIndex));
};
const validateGroup = ({ rules }, groupIndex) => rules.forEach(rule => validateRule(rule, groupIndex));
const validateQuery = ({ groups }) => groups.forEach((group, groupIndex) => validateGroup(group, groupIndex));

export const validateLista = lista => {
  const intl = appIntl();
  if (!lista.nome) throw new Error(intl.formatMessage({ defaultMessage: "Precisamos de um nome para lista." }));
  validateQuery(lista.query);
};
