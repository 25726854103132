import React from "react";
import PropType from "prop-types";
import { withTheme } from "react-jss";
import { compose } from "recompose";

import injectSheet from "../../../_common/hocs/injectSheet";
import { tratarHtmlPerigoso } from "../../../_common/utils/html/html";

const styles = {
  emailItemConteudoContainer: {
    paddingRight: 16,
    paddingBottom: 8,
  },
  iframe: {
    border: "none",
    width: "100%",
    height: "auto",
  },
};

class EmailItemConteudo extends React.PureComponent {
  render = () => (
    <div className={this.props.classes.emailItemConteudoContainer}>
      <div
        className={this.props.classes.iframe}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: tratarHtmlPerigoso(this.props.emailBody) }}
      />
    </div>
  );
}

EmailItemConteudo.propTypes = {
  classes: PropType.object.isRequired,
  emailBody: PropType.string.isRequired,
};

const enhance = compose(
  injectSheet(styles),
  withTheme
);

export default enhance(EmailItemConteudo);
