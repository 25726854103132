import { compose } from "lodash/fp";
import { map } from "../../../../../_common/utils/fp";
import { COMBINATORS } from "../../../../../_common/components/QueryBuilder/constants";

const AND = COMBINATORS[0];
const OR = COMBINATORS[1];

const CombinatorEnum = Object.freeze({
  0: AND,
  1: OR,
  AND,
  OR,
});

export const descriptionTransformer = ({ descricao: description, ...props }) => ({ description, ...props });

const idValueTransformer = ({ id, value, ...props }) => ({
  value: value || id,
  ...props,
});

export const optionTransformer = compose(
  idValueTransformer,
  descriptionTransformer
);

export const responseTransformer = ({ key, value }) => ({
  value: key,
  description: value,
});

export const ApiPostQueryTransformer = () => {
  const ruleTransformer = ({ field, value, response, ...rest }) => ({
    campo: field,
    valor: value,
    resposta: response,
    ...rest,
  });
  const groupTransformer = ({ combinator, rules, ...rest }) => ({
    operadorSegmentacao: CombinatorEnum[combinator].intValue,
    rules: map(ruleTransformer, rules),
    ...rest,
  });

  return ({ globalCombinator, groups }) => ({
    operadorSegmentacao: CombinatorEnum[globalCombinator].intValue,
    segmentacoes: map(groupTransformer, groups),
  });
};

export const ApiGetQueryTransformer = () => {
  const ruleTransformer = ({ id, campo: field, valor: value, resposta: response, operator }) => ({
    id,
    field,
    value,
    response,
    operator,
  });
  const groupTransformer = ({ id, operadorSegmentacao, rules }) => ({
    id,
    combinator: CombinatorEnum[operadorSegmentacao].value,
    rules: map(ruleTransformer, rules),
  });

  return ({ operadorSegmentacao, segmentacoes }) => ({
    globalCombinator: CombinatorEnum[operadorSegmentacao].value,
    groups: map(groupTransformer, segmentacoes),
  });
};

export const configTransformer = ({ limiteSegmentacoes, limiteRegras }) => ({
  MAX_GROUPS: limiteSegmentacoes,
  MAX_RULES: limiteRegras,
});
