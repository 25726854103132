import React from "react";
import PropTypes from "prop-types";
import { withTheme } from "react-jss";
import moment from "moment-timezone";
import MoreVert from "@material-ui/icons/MoreVert";
import Reply from "@material-ui/icons/Reply";
import Forward from "@material-ui/icons/Forward";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import { useIntl } from "react-intl";

import { IconMenu } from "material-ui";
import { IconButton, MenuItem } from "@material-ui/core";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { compose } from "recompose";
import cn from "classnames";
import { Tooltip } from "react-tippy";

import { Tipografia, Flex } from "../../../_common";
import injectSheet from "../../../_common/hocs/injectSheet";
import NomeAvatar from "../NomeAvatar/NomeAvatar";
import IconeTracking from "../IconeTracking/IconeTracking";

const styles = {
  wrapper: {
    paddingTop: 8,
    paddingRight: 16,
    paddingBottom: 16,
  },
  periodoRight: {
    marginLeft: "auto",
    flex: "none",
  },
  botaoMaisOpcoes: {
    marginRight: "-16px",
  },
  iconTrackingContainer: {
    marginLeft: 16,
    marginTop: 6,
  },
  expandirEmail: {
    flex: "auto",
    cursor: "pointer",
  },
  expandirEmailDisabled: {
    flex: "auto",
    cursor: "default",
  },
  btnExpandirEmail: {
    height: 48,
    width: 48,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: -16,
    flex: "none",
  },
};

const enviadoHa = (dataEnvio, intl) =>
  `${moment(dataEnvio).format("DD/MM/YYYY HH:mm")} (${intl.formatMessage(
    {
      defaultMessage: "há {tempo}",
    },
    {
      tempo: moment(dataEnvio).fromNow(true),
    }
  )})`;

function EmailItemHeader({
  classes,
  theme,
  className,
  anchorEl,
  handleClick,
  handleClose,
  fecharEditorResponder,
  mostrarMaisOpcoes,
  modoVisualizacao,
  handleClickHeader,
  mostrarExpandirEmail,
  destinatarios,
  isPVOrVendedor,
  handleResponder,
  handleEncaminhar,
  esconderBotaoResponder,
  ...rest
}) {
  const intl = useIntl();

  return (
    <Flex className={cn(classes.wrapper, className)} justifyContent="space-between" alignItems="center">
      <Flex
        onClick={handleClickHeader}
        className={cn(classes.expandirEmail, className, {
          [classes.expandirEmail]: !rest.email.isEmailCadencia,
          [classes.expandirEmailDisabled]: rest.email.isEmailCadencia,
        })}
        alignItems="center"
      >
        <NomeAvatar
          anchorEl={anchorEl}
          handleClick={handleClick}
          handleClose={handleClose}
          destinatarios={destinatarios}
          leads={rest.listaLeads}
        >
          {rest.email.from.name}
        </NomeAvatar>
        <Tipografia className={classes.periodoRight} tipo="notaLegendas" cor="darkSecondaryText">
          {enviadoHa(rest.enviadoHa, intl)}
        </Tipografia>
        {rest.email.visualizacoes && (
          <div className={classes.iconTrackingContainer}>
            <IconeTracking escondeContador visualizacoes={rest.email.visualizacoes} />
          </div>
        )}
        {mostrarExpandirEmail && (
          <div className={classes.btnExpandirEmail}>
            <KeyboardArrowDown />
          </div>
        )}
      </Flex>
      {isPVOrVendedor &&
        mostrarMaisOpcoes &&
        !modoVisualizacao &&
        !esconderBotaoResponder &&
        !rest.email.isEmailCadencia && (
          <IconMenu
            className={classes.botaoMaisOpcoes}
            iconButtonElement={
              <IconButton iconStyle={{ width: 20, height: 20 }}>
                <Tooltip title={intl.formatMessage({ defaultMessage: "Mais Opções" })} position="bottom" duration={0}>
                  <MoreVert />
                </Tooltip>
              </IconButton>
            }
            desktop
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            targetOrigin={{ horizontal: "right", vertical: "top" }}
          >
            <MenuItem onClick={handleResponder}>
              <ListItemIcon>
                <Reply fontSize="small" />
              </ListItemIcon>
              {intl.formatMessage({ defaultMessage: "Responder" })}
            </MenuItem>
            <MenuItem onClick={handleEncaminhar}>
              <ListItemIcon>
                <Forward fontSize="small" />
              </ListItemIcon>
              {intl.formatMessage({ defaultMessage: "Encaminhar" })}
            </MenuItem>
          </IconMenu>
        )}
    </Flex>
  );
}

EmailItemHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  className: PropTypes.object,
  anchorEl: PropTypes.object,
  handleClick: PropTypes.func,
  handleClose: PropTypes.func,
  fecharEditorResponder: PropTypes.func,
  handleClickHeader: PropTypes.func.isRequired,
  mostrarMaisOpcoes: PropTypes.bool,

  /** Propriedade responsável por ocultar o botão de menu
   * que contém os botões Responder e Encaminhar */
  modoVisualizacao: PropTypes.bool,

  mostrarExpandirEmail: PropTypes.bool.isRequired,
  destinatarios: PropTypes.array.isRequired,
  isPVOrVendedor: PropTypes.bool,
  handleResponder: PropTypes.func.isRequired,
  handleEncaminhar: PropTypes.func.isRequired,
  esconderBotaoResponder: PropTypes.bool,
};

EmailItemHeader.defaultProps = {
  mostrarMaisOpcoes: true,
};

const enhance = compose(
  injectSheet(styles),
  withTheme
);

export default enhance(EmailItemHeader);
