import React from "react";
import { EditorState } from "draft-js";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { decorators } from "../../../_common/utils/editorTexto/editorTexto";
import EditorTextoEmailsBase from "../EditorTextoEmailsBase/EditorTextoEmailsBase";
import { fecharEditorResponder } from "../../../store/reducers/emails/emailsActions";

class EditorTextoEmailsContainer extends React.PureComponent {
  state = {
    editorState: EditorState.createEmpty(decorators),
  };

  componentWillUnmount = () => {
    if (this.props.toggleEditorExpandido) {
      this.props.toggleEditorExpandido(false);
    }
    if (this.props.toggleCopiasExpandidas) {
      this.props.toggleCopiasExpandidas(false);
    }
  };

  onChange = editorState => {
    this.setState({ editorState });
  };

  handleExcluirRascunho = () => {
    this.props.fecharEditorResponder();
  };

  render() {
    return (
      <EditorTextoEmailsBase
        {...this.props}
        onChange={this.onChange}
        editorState={this.state.editorState}
        assuntoInicial={this.props.assuntoInicial}
        setFocusCampoMensagem={!this.props.isEncaminhando}
        templateId={this.props.templateId}
        handleExcluirRascunho={this.handleExcluirRascunho}
        fecharModalLerEmail={this.props.fecharModalLerEmail}
      />
    );
  }
}

EditorTextoEmailsContainer.propTypes = {
  assuntoInicial: PropTypes.object.isRequired,
  isEncaminhando: PropTypes.bool,
  templateId: PropTypes.number,
  fecharEditorResponder: PropTypes.func,
  fecharModalLerEmail: PropTypes.func.isRequired,
  editorExpandido: PropTypes.bool,
  exibirBotaoExpandirEditor: PropTypes.bool,
  toggleEditorExpandido: PropTypes.func,
  toggleCopiasExpandidas: PropTypes.func,
  conteudoHtml: PropTypes.bool,
  conteudoTemplate: PropTypes.string,
};

const mapStateToProps = state => ({
  assuntoInicial: state.emails.responderEmail.threadNormalizada.subject,
});

const mapDispatchToProps = dispatch => ({
  fecharEditorResponder: () => dispatch(fecharEditorResponder()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditorTextoEmailsContainer);
