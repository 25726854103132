import React from "react";
import PropType from "prop-types";
import { useIntl } from "react-intl";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from "@material-ui/core";
import { RadioButton, RadioButtonGroup } from "material-ui/RadioButton";

import { Tipografia } from "../../../_common";
import injectSheet from "../../../_common/hocs/injectSheet";

const styles = {
  tamanhoPadraoModal: {
    width: "468px !important",
  },
};

const ModalEnviarSelecionarContato = ({
  classes,
  listaContatos,
  handlePreencherEEnviar,
  changeContato,
  fecharModal,
}) => {
  const intl = useIntl();

  return (
    <Dialog modal={false} contentClassName={classes.tamanhoPadraoModal} open>
      <DialogTitle>{intl.formatMessage({ defaultMessage: "Selecione o contato principal" })}</DialogTitle>
      <DialogContent>
        <Tipografia>
          {intl.formatMessage({
            defaultMessage: "O template será preenchido com as informações do contato selecionado.",
          })}
        </Tipografia>
        <br />
        <br />
        <RadioButtonGroup name="contatos" onChange={(event, value) => changeContato(event, value)}>
          {listaContatos.map(elem => (
            <RadioButton
              key={elem.email}
              lead={elem.idLead}
              value={elem.email}
              label={elem.email}
              style={{ marginBottom: 16 }}
            />
          ))}
        </RadioButtonGroup>
      </DialogContent>
      <DialogActions>
        <Button key="btnCancelar" color="primary" onClick={fecharModal}>
          {intl.formatMessage({ defaultMessage: "CANCELAR" })}
        </Button>,
        <Button
          key="btnPreencher"
          color="primary"
          onClick={() => {
            handlePreencherEEnviar();
          }}
          variant="contained"
        >
          {intl.formatMessage({ defaultMessage: "PREENCHER" })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ModalEnviarSelecionarContato.propTypes = {
  classes: PropType.object,
  listaContatos: PropType.array.isRequired,
  handlePreencherEEnviar: PropType.func.isRequired,
  changeContato: PropType.func.isRequired,
  fecharModal: PropType.func.isRequired,
};

export default injectSheet(styles)(ModalEnviarSelecionarContato);
